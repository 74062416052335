const state = {
    cartOpen: false,
    cartId: undefined
}

const actions = {
    setCartState(context, newState){
        context.commit('SET_CART_STATE', newState)
    },
    setCartId(context, newsState){
        context.commit('SET_CART_ID', newsState)
    }

}


const mutations = {
    SET_CART_STATE(state, newState){
        state.cartOpen = newState
    },
    SET_CART_ID(state, newState){
        state.cartId = newState
    }
}

const getters = {

    cartState(state) {
        return state.cartOpen
    }

}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
