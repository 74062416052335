<template>
  <div class= "footer" style="text-align: center; color: darkgray; width: 9rem; margin: 0; display: flex; flex-direction: row; font-weight: lighter; align-items: center; justify-content: space-between; height:3rem">
    <div>Provided by</div>
    <svg xmlns="http://www.w3.org/2000/svg" width="55" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="layer"  viewBox="0 0 652 652" style="enable-background:new 0 0 652 652;" xml:space="preserve">

      <g>
        <path class="st0" d="M529.9,357.6c-3.4-1.9-1.2-4.5-1.2-4.5l6.5-8c1.4-1.7,3.2-1.5,4.6-0.8c14.8,7.5,34.7,9.4,49.1,7.5   c12.4-1.7,14.6-7,14.6-11.7c0-6.6-6.3-8.8-6.3-8.8c-3.8-2-18.9-2.9-37.6-4.9c-18.7-1.9-29.4-11-29.4-25.4c0-8.9,3.6-14.9,11.8-20.3   c8.6-5.7,43.3-13.9,77.8,4.8c3.2,1.6,1.2,4.5,1.2,4.5l-6.5,8c-1.4,1.6-3.3,1.4-4.6,0.8c-10.1-5.5-25.2-8.3-39.5-8.2   c-21.9,0.6-21.9,7.5-21.9,10.5c0,3.4,0,7.6,13.6,9.1l25.7,2.8c24.1,2.6,34.3,12,33.9,27.8c-0.5,15.8-10.5,22-23.8,25.7   C598,366.7,563.1,376.2,529.9,357.6"/>
        <path class="st0" d="M305.4,369h-12.6c-1.6,0-3-1.3-3-2.9v-88.8c0-1.6,1.3-3,3-3h12.6c1.6,0,2.9,1.3,2.9,3v88.8   C308.3,367.7,307,369,305.4,369"/>
        <path class="st1" d="M201.6,334.5c37.1,40.5,71.3,34.1,71.3,34.1c1.6,0,2.9-1.3,2.9-2.9v-11.4c0-1.6-1.3-2.9-2.9-2.9   c0,0-20.8,0.8-35.9-8.6c-15.1-9.4-23.6-21.1-23.6-21.2c0.1-0.1,8.6-11.8,23.6-21.2c15.1-9.4,35.9-8.6,35.9-8.6   c1.6,0,2.9-1.3,2.9-2.9v-11.4c0-1.6-1.3-2.9-2.9-2.9c0,0-34.1-6.3-71.3,34.1c-37.1-40.5-71.2-34.2-71.2-34.2c-1.6,0-3,1.3-3,2.9   V289c0,1.6,1.3,2.9,3,2.9c0,0,20.8-0.8,35.9,8.6c15.1,9.4,23.6,21.1,23.6,21.2c-0.1,0.1-8.6,11.8-23.6,21.1   c-15.1,9.4-35.9,8.6-35.9,8.6c-1.6,0-3,1.3-3,2.9v11.4c0,1.6,1.3,2.9,3,2.9C130.3,368.7,164.4,375,201.6,334.5"/>
        <path class="st0" d="M401.2,282.1c-8.6-5.7-41.7-15.5-76.2,3.5c-3.2,1.6-1.2,4.5-1.2,4.5l6.4,8c1.4,1.6,3.3,1.5,4.6,0.8   c10.1-5.5,23.6-7.9,37.9-7.9c21.9,0.6,22.9,8.6,22.9,11.6v10.9c-3.5-1.5-7.5-2.4-11.6-2.4h-35.8c-16,0-29,13-29,28.9   c0,8,3.2,15.2,8.5,20.5c5.2,5.2,12.5,8.5,20.5,8.5h35.8c16,0,28.9-13,28.9-29v-37.5C413,293.6,409.4,287.5,401.2,282.1 M385,352.8   h-37.7c-6.7,0-12.2-5.7-12.2-12.7c0-7,5.5-12.7,12.2-12.7H385c6.7,0,12.2,5.7,12.2,12.7C397.2,347.1,391.7,352.8,385,352.8"/>
        <path class="st0" d="M103.7,282.1c-8.6-5.7-41.7-15.5-76.2,3.5c-3.2,1.6-1.2,4.5-1.2,4.5l6.4,8c1.4,1.6,3.3,1.5,4.6,0.8   c10.1-5.5,23.6-7.9,37.9-7.9c21.9,0.6,22.9,8.6,22.9,11.6v10.9c-3.5-1.5-7.5-2.4-11.6-2.4H50.7c-16,0-29,13-29,28.9   c0,8,3.2,15.2,8.5,20.5c5.2,5.2,12.5,8.5,20.5,8.5h35.8c16,0,28.9-13,28.9-29v-37.5C115.5,293.6,111.9,287.5,103.7,282.1    M87.5,352.8H49.8c-6.7,0-12.2-5.7-12.2-12.7c0-7,5.5-12.7,12.2-12.7h37.7c6.7,0,12.2,5.7,12.2,12.7   C99.6,347.1,94.2,352.8,87.5,352.8"/>
        <path class="st0" d="M472,274.3c10.4,0,16.6,1,16.6,1c30.2,3.8,29.3,26.5,29.3,26.5v64.1c0,1.6-1.3,2.9-3,2.9h-11.5   c-1.6,0-3-1.3-3-2.9v-64.1c0,0,0.4-8.3-15.7-9.7c-9.5-0.8-12.8-0.6-12.8-0.6s-3.4-0.2-12.8,0.6c-16.1,1.4-15.7,9.7-15.7,9.7v64.1   c0,1.6-1.3,2.9-3,2.9h-11.5c-1.6,0-3-1.3-3-2.9v-64.1c0,0-0.9-22.7,29.3-26.5C455.5,275.3,461.6,274.3,472,274.3"/>
      </g>
    </svg>
  </div>
</template>

<script>
  export default {
    name: "Footer"
  }
</script>

<style >
  @media screen and (max-width: 1250px), (max-height: 780px) {
  .footer {
  visibility: collapse;
  }}


  .st0{fill:#0068B6;}
  .st1{fill:#AC006D;}

  svg {
    margin-top: 5px;
  }
</style>