<template>
	<div>
		<div class="files">
			<div>
				<vs-alert>
					<template>
						<div class="template-title">
							<div style="display: inline-block align-items: center;">
								<span id="title">
									Fichiers
								</span>
							</div>
							<div>
								<p
									style="text-align: end; margin-bottom: 5%; margin: 0; color: black;"
								>
									{{ file_over }}
								</p>
							</div>
						</div>
					</template>
				</vs-alert>
			</div>
			<div class="table">
				<div style="color: darkgrey; align-items: center; overflow-y: scroll">
					<FingerprintSpinner
						style="position: absolute; left: 60%; top: 50%;"
						:size="60"
						:color="'#0060be'"
						v-if="loading"
					/>
					<div v-else style="width: 100%">
						<vs-input
							v-model="searchFile"
							shadow
							placeholder="Rechercher un fichier"
							style="margin: 2px 10px 5px 10px"
						>
							<template #icon>
								<i style="color: darkgrey" class="bx bx-search-alt"></i>
							</template>
						</vs-input>
						<table style="width: 100%; border-spacing: 0; padding: 0px 10px;">
							<tr>
								<th
									style="font-weight: normal; display: flex; align-items: center; cursor: pointer"
									@click="
										toggleOrderName =
											toggleOrderName + 1 === 3 ? 0 : toggleOrderName + 1;
										orderResult();
									"
								>
									<p style="margin: 0 5px 0 0">Nom</p>
									<svg
										v-if="toggleOrderName >= 1 && !searchFile"
										:style="{
											rotate: toggleOrderName === 2 ? '180deg' : undefined,
										}"
										clip-rule="evenodd"
										fill-rule="evenodd"
										fill="grey"
										width="14"
										height="14"
										stroke-linejoin="round"
										stroke-miterlimit="2"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="m18.787 9.473s-4.505-4.502-6.259-6.255c-.147-.146-.339-.22-.53-.22-.192 0-.384.074-.531.22-1.753 1.753-6.256 6.252-6.256 6.252-.147.147-.219.339-.217.532.001.19.075.38.221.525.292.293.766.295 1.056.004l4.977-4.976v14.692c0 .414.336.75.75.75.413 0 .75-.336.75-.75v-14.692l4.978 4.978c.289.29.762.287 1.055-.006.145-.145.219-.335.221-.525.002-.192-.07-.384-.215-.529z"
											fill-rule="nonzero"
										/>
									</svg>
									<svg
										v-if="toggleOrderName === 0 && !searchFile"
										clip-rule="evenodd"
										fill-rule="evenodd"
										stroke-linejoin="round"
										stroke-miterlimit="2"
										width="14"
										height="14"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="m21 11.75c0-.414-.336-.75-.75-.75h-16.5c-.414 0-.75.336-.75.75s.336.75.75.75h16.5c.414 0 .75-.336.75-.75z"
											fill-rule="nonzero"
										/>
									</svg>
								</th>
								<th
									style="font-weight: normal;"
									@click="
										toggleSizeOrder =
											toggleOrderName + 1 === 3 ? 0 : toggleOrderName++;
										orderResult();
									"
								>
									<div
										style="display: flex; align-items: center; cursor: pointer"
									>
										<p style="margin: 0 5px 0 0">Taille</p>
										<!--<svg :style="{rotate: toggleSizeOrder ? '180deg' : undefined}" clip-rule="evenodd" fill-rule="evenodd" fill="grey" width="14" height="14" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m18.787 9.473s-4.505-4.502-6.259-6.255c-.147-.146-.339-.22-.53-.22-.192 0-.384.074-.531.22-1.753 1.753-6.256 6.252-6.256 6.252-.147.147-.219.339-.217.532.001.19.075.38.221.525.292.293.766.295 1.056.004l4.977-4.976v14.692c0 .414.336.75.75.75.413 0 .75-.336.75-.75v-14.692l4.978 4.978c.289.29.762.287 1.055-.006.145-.145.219-.335.221-.525.002-.192-.07-.384-.215-.529z" fill-rule="nonzero"/></svg>-->
									</div>
								</th>
								<th style="font-weight: normal; border-left: 1px solid #fafafa">
									Type
								</th>
								<th
									style="font-weight: normal"
									@click="
										toggleDate = toggleDate + 1 === 3 ? 0 : toggleDate + 1;
										orderResult();
									"
								>
									<div
										style="display: flex; align-items: center; cursor: pointer"
									>
										<p style="margin: 0 5px 0 0">Date</p>
										<svg
											v-if="toggleDate >= 1 && !searchFile"
											:style="{
												rotate: toggleDate === 2 ? '180deg' : undefined,
											}"
											clip-rule="evenodd"
											fill-rule="evenodd"
											fill="grey"
											width="14"
											height="14"
											stroke-linejoin="round"
											stroke-miterlimit="2"
											viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="m18.787 9.473s-4.505-4.502-6.259-6.255c-.147-.146-.339-.22-.53-.22-.192 0-.384.074-.531.22-1.753 1.753-6.256 6.252-6.256 6.252-.147.147-.219.339-.217.532.001.19.075.38.221.525.292.293.766.295 1.056.004l4.977-4.976v14.692c0 .414.336.75.75.75.413 0 .75-.336.75-.75v-14.692l4.978 4.978c.289.29.762.287 1.055-.006.145-.145.219-.335.221-.525.002-.192-.07-.384-.215-.529z"
												fill-rule="nonzero"
											/>
										</svg>
										<svg
											v-if="toggleDate === 0 && !searchFile"
											clip-rule="evenodd"
											fill-rule="evenodd"
											stroke-linejoin="round"
											stroke-miterlimit="2"
											width="14"
											height="14"
											viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="m21 11.75c0-.414-.336-.75-.75-.75h-16.5c-.414 0-.75.336-.75.75s.336.75.75.75h16.5c.414 0 .75-.336.75-.75z"
												fill-rule="nonzero"
											/>
										</svg>
									</div>
								</th>
								<th style="font-weight: normal">Actions</th>
							</tr>
							<tr
								v-for="(item, index) in table"
								:key="index"
								style="padding: 10px"
								:style="{
									backgroundColor: index % 2 === 0 ? '#fafafa' : undefined,
								}"
							>
								<td>
									<div style="display: flex; align-items: center;">
										<img
											:src="getImage(item)"
											@error="imageUrlAlt"
											alt="itemImg"
											style="width: 16px; margin-right: 10px;"
										/>
										<p style="margin: 0px">{{ item.name }}</p>
									</div>
								</td>
								<td>{{ calcSize(item.size) }}</td>
								<td>{{ getItemExt(item) }}</td>
								<td>{{ convertFrenchTime(item.mtime) }}</td>
								<td>
									<div>
										<i v-if="getItemExt(item) == 'pdf'" @click="showPdf(item, index)" class="bx bx-show"></i>
										<i
											v-if="downloadSuccess === 1 && indexfor === index"
											class="bx bx-check"
											style="color: #12E759"
										></i>
										<i
											v-else
											@click="downloadFile(item, index)"
											class="bx bx-download"
										></i>
										<i @click="showModal('delete', index)" class="bx bx-x"></i>
										<i v-if="files.length === 0">Aucun fichier disponible</i>
									</div>
								</td>
								<DeleteModal
									v-if="indexfor === index"
									v-show="isModalDeleteVisible"
									@close="closeModal('delete')"
									@delete="deleteFile(item)"
									:file="item"
								/>
							</tr>
						</table>
					</div>
				</div>
				<footer>
					<div class="upload">
						<vs-button
							@click="showModal('upload')"
							id="button-upload"
							transparent
							active
							>Téléverser un fichier</vs-button
						>
						<vs-button
							@click="downloadAll()"
							id="button-upload"
							transparent
							active
							>Télécharger tout les fichiers</vs-button
						>
					</div>
				</footer>
			</div>

			<UploadFileModal
				v-show="isModalUploadVisible"
				@close="closeModal('upload')"
				:bucket="folder"
				:all_files="files"
			/>
		</div>
	</div>
</template>

<script>
import Api from "../../../store/config";
import { FingerprintSpinner } from "epic-spinners";
import UploadFileModal from "./Modals/UploadFileModal";
import DeleteModal from "./Modals/DeleteModal";
import JSZip from "jszip";

export default {
	name: "Files",
	components: {
		FingerprintSpinner,
		UploadFileModal,
		DeleteModal,
	},
	watch: {
		files(newValue) {
			this.table = newValue;
			console.log(this.table);
		},
		searchFile(newValue) {
			this.table = this.files.filter((item) =>
				item.name.toLowerCase().includes(newValue.toLowerCase())
			);
		},
	},
	data() {
		return {
			searchFile: undefined,
			downloadSuccess: 0,
			indexfor: null,
			secondLoading: true,
			infoEnable: false,
			isModalDeleteVisible: false,
			isModalUploadVisible: false,
			file_over: "",
			table: [],
			toggleOrderName: 0,
			toggleSizeOrder: 0,
			toggleDate: 0,
		};
	},
	props: {
		files: Array,
		folder: String,
		loading: Boolean,
	},
	mounted() {
		// Check if there are files
		if (this.files.length !== 0) {
			this.secondLoading = false;
			this.loading = false;
			this.table = this.files;
		}
	},
	methods: {
		calcSize(size) {
			if (size / 1024 >= 1) {
				if (size / 1024 / 1024 >= 1) {
					if (size / 1024 / 1024 / 1024 >= 1) {
						return (size / 1024 / 1024 / 1024).toFixed(2) + " Go";
					} else {
						return (size / 1024 / 1024).toFixed(2) + " Mo";
					}
				} else {
					return (size / 1024).toFixed(2) + " Ko";
				}
			} else {
				return size + " o";
			}
		},
		convertFrenchTime(time) {
			const data = new Date(time);
			const day = data.getDate() < 10 ? `0${data.getDate()}` : data.getDate();
			const month =
				data.getMonth() + 1 < 10
					? `0${data.getMonth() + 1}`
					: data.getMonth() + 1;
			const year = data.getFullYear();
			const hour =
				data.getHours() < 10 ? `0${data.getHours()}` : data.getHours();
			const minute =
				data.getMinutes() < 10 ? `0${data.getMinutes()}` : data.getMinutes();
			const second =
				data.getSeconds() < 10 ? `0${data.getSeconds()}` : data.getSeconds();

			return `${day}/${month}/${year} ${hour}:${minute}:${second}`;
		},
		getItemExt(item) {
			const res = item.name.split(".");
			return res[res.length - 1];
		},
		orderResult() {
			let sortByName = (a, b) => {
				if (a.name < b.name) {
					return 1;
				} else if (a.name > b.name) {
					return -1;
				} else {
					return 0;
				}
			};

			let sortByDate = (a, b) => {
				if (a.mtime < b.mtime) {
					return 1;
				} else if (a.mtime > b.mtime) {
					return -1;
				} else {
					return 0;
				}
			};

			/*let sortBySize = (a,b) => {
							if(a.size > b.size) {
								return 1
							} else if (a.size < b.size) {
								return -1
							} else {
								return 0
							}
						}*/

			if (this.toggleDate === 0 && this.toggleOrderName === 0) {
				this.table = this.files.sort(sortByName).reverse();
			}

			if (this.toggleOrderName !== 0 && this.toggleDate !== 0) {
				if (this.toggleOrderName === 1) {
					if (this.toggleDate === 1) {
						this.table = this.files
							.sort(sortByName)
							.reverse()
							.sort(sortByDate)
							.reverse();
					} else {
						this.table = this.files
							.sort(sortByName)
							.reverse()
							.sort(sortByDate);
					}
				} else {
					if (this.toggleDate === 1) {
						this.table = this.files
							.sort(sortByName)
							.sort(sortByDate)
							.reverse();
					} else {
						this.table = this.files.sort(sortByName).sort(sortByDate);
					}
				}
			}
			if (this.toggleOrderName === 0 && this.toggleDate !== 0) {
				if (this.toggleDate === 1) {
					this.table = this.files.sort(sortByDate).reverse();
				} else {
					this.table = this.files.sort(sortByDate);
				}
			}

			if (this.toggleOrderName !== 0 && this.toggleDate === 0) {
				if (this.toggleOrderName === 1) {
					this.table = this.files.sort(sortByName).reverse();
				} else {
					this.table = this.files.sort(sortByName);
				}
			}
		},
		async downloadFile(file, index) {
			try {
				this.downloadSuccess = 1;
				this.indexfor = index;

				// TODO : Remplacer le fetch par Api().get
				await Api()
					.get(`/minio/files?bucket=${this.folder}&filename=${file.name}`, {
						headers: {
							Authorization: `${localStorage.getItem("token")}`,
						},
						responseType: "blob",
					})
					.then((response) => {
						const fileURL = URL.createObjectURL(response.data);

						const anchor = document.createElement("a");
						anchor.href = fileURL;
						anchor.download = file.name;

						document.body.appendChild(anchor);
						anchor.click();
						document.body.removeChild(anchor);

						URL.revokeObjectURL(fileURL);

						setTimeout(() => {
							this.downloadSuccess = 0;
						}, 5000);
					});
			} catch {
				this.downloadSuccess = 2;
				setTimeout((err) => {
					this.$vs.notification({
						position: "top-left",
						title: `Erreur téléchargement`,
						text: `Raison de l'erreur: ${err}`,
						color: "error",
					});
					setTimeout(() => {
						this.downloadSuccess = 0;
					}, 5000);
				}, 5000);
			}
		},
		deleteFile(file) {
			this.secondLoading = true;
			Api()
				.delete(`/minio/files?bucket=${this.folder}&filename=${file.name}`)
				.then(() => {
					this.secondLoading = false;
					this.$emit("refresh");
					this.$vs.notification({
						position: "top-left",
						title: `Suppression`,
						text: `Suppression du fichier ${file} réussi`,
						color: "success",
					});
				})
				.catch((err) => {
					this.secondLoading = false;
					setTimeout(() => {
						this.$vs.notification({
							position: "top-left",
							title: `Suppression`,
							text: `Raison de l'erreur: ${err}`,
							color: "error",
						});
					}, 5000);
				});
		},
		downloadAll() {
			const zip = new JSZip();
			this.files.forEach((file) => {
				Api()
					.get(`/minio/files?bucket=${this.folder}&filename=${file.name}`)
					.then((res) => {
						zip.file(file, res.data);
					})
					.catch((err) => {
						this.$vs.notification({
							position: "top-left",
							title: `Erreur téléchargement`,
							text: `Raison de l'erreur: ${err}`,
							color: "error",
						});
					});
			});

			zip.generateAsync({ type: "blob" }).then(function(content) {
				// see FileSaver.js
				let fileLink = document.createElement("a");
				fileLink.href = URL.createObjectURL(content);
				fileLink.setAttribute(
					"download",
					`${this.folder} - ${new Date().toLocaleString()}.zip`
				);
				document.body.appendChild(fileLink);

				fileLink.click();
			});
		},
		showModal(modal, index = null) {
			if (modal === "delete") {
				this.isModalDeleteVisible = true;
				this.indexfor = index;
			} else if (modal === "upload") {
				this.isModalUploadVisible = true;
			}
		},
		closeModal(modal) {
			if (modal === "delete") this.isModalDeleteVisible = false;
			else if (modal === "upload") this.isModalUploadVisible = false;

			this.$emit("refresh");
		},
		mouseover(item) {
			this.file_over = item;
		},
		mouseleave() {
			this.file_over = "";
		},
		getImage(item) {
			let value = item.name.match(/[^.]*$/);
			let extension = value[0].toLowerCase();
			if (extension === "docx") extension = "word";
			return `https://img.icons8.com/color/48/000000/${extension}.png`;
		},
		imageUrlAlt(event) {
			event.target.src = "https://img.icons8.com/ios/50/000000/file--v1.png";
		},
		showPdf(item) {
			const baseURL = process.env.VUE_APP_DEV ? process.env.VUE_APP_DEV : `https://${window.location.hostname}/api/`
			const url = `${baseURL}/minio/files-preview?bucket=${this.folder}&filename=${item.name}&token=${btoa(localStorage.getItem("token"))}`
			console.log(url)
			window.open(url, '_blank').focus();
		}
	},
};
</script>

<style scoped>
.files {
	height: 72vmin;
}

.table {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 87%;
}

div.vs-alert.vs-component--primary {
	border-top-left-radius: 12px;
	border-bottom-right-radius: 0;
	height: 50px !important;
}

@media (max-width: 1920px) {
	.files {
		border-radius: 10px;
		width: 100%;
		box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
			rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
	}
}

@media (min-width: 1920px) {
	.files {
		border-radius: 10px;
		width: 100%;
		box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
			rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
	}

	.table {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
}

.template-title {
	display: grid;
	grid-template-columns: 1fr 2fr;
}

#title {
	font-weight: 600;
	font-size: 1rem;
	display: flex;
	align-items: center;
}

.card-container {
	padding: 20px;
	align-items: stretch;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	justify-content: space-between;
	overflow-y: scroll;
	height: 52vh;
	max-width: 100%;
}

.card {
	height: 130px;
	transition: 0.3s;
	text-align: center;
}

.increase-width-card {
	width: 400px;
}

.normal-width-card {
	width: 150px;
}

.card > p {
	padding-top: 5px;
	margin: 0;
}

.bx {
	transition: 0.3s;
	padding: 5px;
}

.bx-download, .bx-show, .bx-x {
	cursor: pointer;
}

.bx-download:hover, .bx-show:hover {
	color: #2a69af;
}

.bx-x:hover {
	color: #d22d53;
}

.bx-info-circle:hover {
	color: #4dafe6;
}

.upload {
	display: flex;
	justify-content: center;
	float: bottom;
	margin-top: 1%;
}

#button-upload {
	width: 200px;
}

/* Media Queries Width */
@media screen and (max-width: 1280px) {
	.card-container {
		grid-template-columns: repeat(4, 1fr);
	}
}

@media screen and (max-width: 1112px) {
	.card-container {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media screen and (max-width: 1024px) {
	.card-container {
		grid-template-columns: 1fr 1fr;
	}
}

/* Media Queries Height */
@media screen and (max-height: 667px) {
	.upload {
		display: none;
	}
}
</style>
