<template>
  <transition name="modal-fade">
    <modal :resizable="true" :name="'ProductDetailsModal'" width="50%" height="auto" classes="modal-override">
      <div class="modal-container">
        <div class="modal-header">
          <i @click="close" class='bx bx-x-circle bx-sm' id="close" />
          <div class="product-informations">
            <img
							:alt="product.maker.logo"
              id="img-product-informations"
							v-if="product.maker.logo !== null"
							style="border-radius: 8px;"
							:src="baseURL + product.maker.logo"
						/>
						<img
							style="border-radius: 8px; height:75px;"
							v-else
							src="../../../assets/defaultbox.png"
							alt="default-box"
						/>
            <div class="div-product-informations">
              <span>
                <span class="span-important-informations">
                  {{ product.family_sub.family.name }} &nbsp; / &nbsp;
                </span>
                <span class="span-important-informations">
                  {{ product.family_sub.name }}
                </span>
              </span>
              <h4>
                <span id="span-product-reference">
                  {{product.reference}}
                </span>
              </h4>
            </div>
          </div>
        </div>

        <div class="modal-body-container">
          <div class="line" id="line-major-div">
            <div id="div-equipment-informations">Information équipement</div>
              <div class="line" style="overflow-y: auto">
                <div class="line-item item-margin-auto">
                  <div class="item">
                    <span class="minor-informations">
                      Constructeur
                    </span>
                    <span class="span-minor-informations span-minor-informations-bold">
                      {{product.maker.name}}
                    </span>
                  </div>

                  <div class="item item-margin-auto">
                    <span class="minor-informations">
                      Référence constructeur
                    </span>
                    <span class="span-minor-informations span-minor-informations-bold">
                      {{product.reference_const}}
                    </span>
                  </div>

                  <div class="item item-margin">
                    <span class="minor-informations">
                      Remise famille
                    </span>
                    <span class="span-minor-informations">
                     {{ product.discount_price ? (100 * (1-(product.discount_price / product.public_price))).toFixed(2) : '0' }}%
                    </span>
                  </div>
                </div>

                <div class="line-item">
                  <div class="item">
                    <span class="minor-informations">
                      Description
                    </span>
                    <span class="span-minor-informations">
                      {{product.description}}
                    </span>
                  </div>
                </div>

                <div class="line-item">
                  <div class="item item-margin">
                    <span class="minor-informations">
                      Maintenance 1 an, GTR J+1:
                    </span>
                    <span class="span-minor-informations"> 
                      {{ product.rie8_support[0] ? product.rie8_support[0].annual_unit_included.toFixed(2) + ' €' : 'Prix non spécifié' }} 
                    </span>
                  </div>
                  <div class="item item-margin">
                    <span class="minor-informations">
                      Maintenance 3 ans, GTR J+1:
                    </span>
                    <span class="span-minor-informations"> 
                      {{ product.rie8_support[1] ? product.rie8_support[1].annual_unit_included.toFixed(2) + ' €' : 'Prix non spécifié' }} 
                    </span>
                  </div>
                </div>

                <div class="line-item">
                  <div class="item item-margin">
                <span class="minor-informations">
                  Maintenance 1 an, GTR H+4 :
                </span>
                <span class="span-minor-informations"> 
                  {{ product.rie4_support[0] ? product.rie4_support[0].annual_unit_included.toFixed(2) + ' €' : 'Prix non spécifié' }} 
                </span>
              </div>
              <div class="item item-margin">
                <span class="minor-informations">
                  Maintenance 3 ans, GTR H+4:
                </span>
                <span class="span-minor-informations"> 
                  {{ product.rie4_support[1] ? product.rie4_support[1].annual_unit_included.toFixed(2) + ' €' : 'Prix non spécifié' }} 
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="line-rundown" style="height: 100%">
          <div id="div-second-informations">Tarif</div>
          <div id="div-child-second-informations">
            <div class="item-rundown">
              <span class="span-minor-sc-informations-bold">
                Prix public
              </span>
              <span class="span-minor-sc-informations">
                {{product.public_price.toFixed(2) }}€<strong> HT</strong> 
              </span>
            </div>

            <div class="item-rundown">
              <span class="span-minor-sc-informations-bold">
                Remise famille
              </span>
              <span class="span-minor-sc-informations">
                <strong style="font-size: 1.2em">-</strong> {{ product.discount_price ? (product.public_price - product.discount_price).toFixed(2) : '0' }}€ <strong>HT</strong>
              </span>
            </div>
                        
            <div id="div-price"></div>
              <div class="item-rundown">
                <span class="span-minor-sc-informations-bold">
                  Total remisé
                </span>
                <span class="span-minor-sc-informations">
                  {{ (product.public_price - (product.public_price - product.discount_price)).toFixed(2)   }}€ <strong>HT</strong>
                </span>
              </div>

              <div class="item-rundown" id="major-div-maintenance">
                <div id="major-div-child-maintenace">
                  <span class="span-minor-sc-informations-bold">
                    Maintenance 
                    <span style="font-weight: lighter; font-size: 0.8em">
                      (choisir)
                    </span>
                  </span>
                  <span class="span-minor-sc-informations"> 
                    <strong style="font-size: 1.2em">+</strong> {{selectedRie === 0 ? 0 :  selectedRie[0].annual_unit_included.toFixed(2)}}€ <strong>HT</strong> 
                  </span>
                </div>

                <select v-model="selectedRie" style="width: 70%;">
                  <option value="" disabled hidden>Sélectionnez maintenance</option>
                  <option :value="0">Aucune</option>
                  <option v-if="product.rie4_support.length > 0" :value="[product.rie4_support[0], 'rie4', ]">Maintenance 1 an avec GTR J+1: {{ product.rie8_support[0] ? '+' + product.rie8_support[0].annual_unit_included.toFixed(2) + ' €' : 'Prix non spécifié' }} </option>
                  <option v-if="product.rie4_support.length > 0" :value="[product.rie4_support[1], 'rie4', ]">Maintenance 3 ans avec GTR J+1: {{ product.rie8_support[1] ? '+' + product.rie8_support[1].annual_unit_included.toFixed(2) + ' €' : 'Prix non spécifié' }} </option>
                  <option v-if="product.rie8_support.length > 0" :value="[product.rie8_support[0], 'rie8']">Maintenance 1 an avec GTR H+4: {{ product.rie4_support[0] ? '+' + product.rie4_support[0].annual_unit_included.toFixed(2) + ' €' : 'Prix non spécifié' }}</option>
                  <option v-if="product.rie8_support.length > 0" :value="[product.rie8_support[1], 'rie8']">Maintenance 3 ans  avec GTR H+4: {{ product.rie4_support[1] ? '+' + product.rie4_support[1].annual_unit_included.toFixed(2) + ' €' : 'Prix non spécifié' }}</option>
                </select>
              </div>

              <div class="item-rundown" id="div-quantity">
                <div id="div-quantity-child">
                  <span class="span-minor-sc-informations-bold">Quantité</span>
                </div>

                <input v-model="quantity" type="number" min="1" max="1000" style="width: 15%; background-color: transparent; outline: none; border: 1px solid #2c3e50a5; border-radius: 4px;" />

              </div>
            </div>

            <div class="item-rundown" style="border-top: 1px solid black;">
              <span id="total-price-first-span">Total</span>
              <span id='total-price-second-span'>{{totalPrice}}€ <strong>HT</strong></span>
            </div>
          </div>
        </div>

        <div class="modal-footer" v-if="isCurrentVersion">
          <vs-button style="width: auto" dark @click="goToProductHistory"> Historique <i class="bx bxs-time"></i></vs-button>
          <vs-button v-if="this.$store.state.Users.currentUser.role_type !== 'Responsable d\'Affaire'" style="width: auto" @click="addToCart(product)"> Ajouter au panier <i class="bx bxs-cart-add"></i></vs-button>
        </div>
      </div>
    </modal>
  </transition>
</template>

<script>
  import Api from '../../../store/config'

  export default {
    name: "ProductDetailsModal",
    props: {
      isCurrentVersion: Boolean,
      product: Object,
      page: Number
    },
    watch: {
      quantity() {
        if (this.quantity < 1) {
          this.quantity = 1
        }
        if(this.quantity > 1000){
          this.quantity = 1000
        }
      }
    },
    data(){
      return{
        quantity: 1,
        baseURL: process.env.VUE_APP_DEV ? process.env.VUE_APP_DEV : `https://${window.location.hostname}/api/`,
        selectedRie: 0,
      }
    },
    computed: {
      totalPrice(){
        if(this.selectedRie === 0 ){
          return (this.product.discount_price * this.quantity).toFixed(2)
        }
        else {
          return ((this.product.discount_price * this.quantity) + this.selectedRie[0].annual_unit_included).toFixed(2)
        }

      }
    },
    methods: {
      close() {
        this.$emit('close');
      },
      goToProductHistory(){
        this.$router.push({ name: 'ProductHistory', params: { product_reference: this.product.reference_const, page: this.page } })
      },
      addToCart(){
        let url = `cart/?user_id=${this.$store.state.Users.currentUser.id}&product_id=${this.product.id}&quantity=${this.quantity}`

        if(this.selectedRie !== 0) {
          if(this.selectedRie[1] === 'rie4') {
            let RieId = this.selectedRie[0].id
            url = `cart/?user_id=${this.$store.state.Users.currentUser.id}&product_id=${this.product.id}&quantity=${this.quantity}&rie4_id=${RieId}`
          }
          else if(this.selectedRie[1] === 'rie8') {
            let RieId = this.selectedRie[0].id
            url = `cart/?user_id=${this.$store.state.Users.currentUser.id}&product_id=${this.product.id}&quantity=${this.quantity}&rie8_id=${RieId}`
          }
        }

        Api().post(url)
        .then( () => {
              this.$store.dispatch('Cart/setCartState', true)
              this.$modal.hide(`ProductDetailsModal_${this.product.id}`)
              this.$vs.notification({
                position: 'top-left',
                title: `Panier - Ajout` ,
                text: `${this.product.reference} a été ajouté au panier` ,
                color: 'success'
              })
            }
        )
        .catch((err) => {
          this.$vs.notification({
            position: 'top-left',
            title: `Erreur` ,
            text: err,
            color: "warn"
          })
        })
      }
    }
  }
</script>

<style scoped>
 /* Media queries styles */
  @media screen and (min-width: 1250px) {
    .options-icon {
      display: none;
    }
  } 
  
  @media screen and (max-width: 1250px) {
    .options-btn-title {
      display: none;
    }
  }

  /* Normal styles */

  h4 {
    margin: 0;
  }

  .modal-container {
    padding: 0%; 
    background-color: #fafbfc;
    height: 100%; 
    width: 100%;
  }

  .line {
    display: flex;
    border-radius: 8px;
    flex-direction: column;
    background-color: #eee;
    flex:  1 1 0;
    justify-content: space-between;

  }

  .line-rundown {
    display: flex;
    border-radius: 8px;
    flex-direction: column;
    background-color: #eee;
    flex:  1 1 0;
    justify-content: space-between;
  }

  #img-product-informations {
    border-radius: 8px
  }

  #close {
    cursor: pointer; 
    color: black; 
    position: absolute; 
    top: 10px; 
    right: 10px;
    transition: 0.3s;
  }

  #close:hover {
    color: red;
  }

  .product-informations {
    display: flex; 
    flex-direction: row; 
    justify-content: left; 
    width: 70%; 
    align-items: center
  }

  .div-product-informations {
    display: flex; 
    flex-direction: column; 
    margin-left: 4%
  }

  .span-important-informations {
    text-transform: capitalize; 
    font-size: 0.65em; 
    color: grey
  }

  #span-product-reference {
    font-weight: 800
  }

  .item-margin {
    margin-right: 2%;
  }

  .item-margin-auto {
    margin-right: 2%; 
    width: auto;
  }

  #line-major-div {
    margin-right: 2%; 
    height:100%; 
    border-radius: 8px;
  }

  #div-equipment-informations {
    text-align: left; 
    height: 30px; 
    background-color: rgba(0, 0, 0, 0.10); 
    border-top-left-radius: 8px; 
    border-top-right-radius: 8px; 
    padding-left: 2%;  
    font-weight: bold
  }

  .minor-informations {
    font-size: 0.8em; 
    color: grey
  }

  .span-minor-informations {
    color: black; 
    font-size: 0.8em;
  }

  .span-minor-informations-bold {
    font-weight: bold
  }

  .span-minor-sc-informations-bold {
    font-size: 0.8em; 
    color: grey; 
    font-weight: bold
  }

  .span-minor-sc-informations {
    margin-left: 1%; 
    font-size: 0.8em; 
    color: #005eb8;
  }

  #div-second-informations {
    text-align: left; 
    height: 30px; 
    background-color: rgba(0, 0, 0, 0.10); 
    border-top-left-radius: 8px; 
    border-top-right-radius: 8px; 
    padding-left: 2%;  
    font-weight: bold
  }

  #div-child-second-informations {
    display: flex; 
    flex-direction: column;  
    flex: 1 1 0; 
  }

  #div-price {
    height: 2%; 
    border-top: 1px solid rgba(0, 0, 0, 0.1); 
    width: auto; 
    margin: 0% 3%
  }

  #major-div-maintenance {
    text-align: center; 
    flex-direction: column; 
    width: auto; 
    align-items: flex-start; 
    justify-content: flex-end
  }

  #major-div-child-maintenace {
    display: flex; 
    flex-direction: row;
    width: 100%; 
    justify-content: space-between; 
    align-items: center
  }

  #div-quantity {
    text-align: center; 
    flex-direction: column; 
    width: auto; 
    align-items: flex-end; 
    justify-content: flex-end
  }

  #div-quantity-child {
    display: flex; 
    flex-direction: row;
    width: 100%;
    justify-content: flex-end; 
    align-items: center
  }

  #total-price-first-span {
    font-size: 0.9em; 
    color: black; 
    font-weight: bold; 
    margin-right: 2%
  }

  #total-price-second-span {
    font-size: 1em;  
    color: #005eb8
  }

  .line-item {
    padding: 1.5% 2%;
    border-radius: 8px;
    background-color: #eee;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
  }
  
  .item {
    padding: 1.5%;
    border-radius: 8px;
    background-color: #eee;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .item-rundown {
    padding: 1.5% 3%;
    background-color: #eee;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }

  .modal-header {
    padding: 3% 3% 1% 3%;
    height: 10%;
    align-items: center;
    background-color: #FFFFFF;
    border-bottom: 2px solid #eee;
    border-radius: 10px 10px 0px 0px;
    display: flex; flex-direction: row; justify-content: space-between;
  }

  .modal-footer {
    padding: 1% 3% 1% 3%;
    height: 5%;
    align-items: center;
    background-color: #FFFFFF;
    border-top: 2px solid #eee;
    border-radius: 0px 0px 10px 10px;
    display: flex; flex-direction: row; justify-content: space-between;
  }

  .buttons-container {
    display: flex; flex-direction: row;
  }

  .modal-body-container {
    height: 19rem;
    padding: 3%;
    display: flex; 
    flex-direction: row; 
    justify-content: space-between;
  }

  .separator {
    width: 100%;
    position: absolute;
    height: 2px;
    top: 5.5rem;
    left: 0%;
    background-color: #eee;
  }

  .modal-override {
    padding:  0!important;
    border-radius: 8px!important;
  }
  
  select {
    text-align: center;
    text-align-last: center;
    -moz-text-align-last: center; 
  }
</style>

