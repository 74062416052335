<template>
    <transition name="modal-fade">
        <div class="modal-backdrop">
            <div class="modal">
                <header class="modal-header">
                    <slot name="header">
                        <div>
                            <h4>
                                Création d'un bucket
                            </h4>
                        </div>
                    </slot>
                    <i @click="close" id="close" class='bx bx-x-circle bx-sm'></i>
                </header>
                <section class="modal-body">
                    <div style="width: 100%">
                        <vs-input v-model="name" :class="alreadyUsed ? 'red-border' : '' " :success="!alreadyUsed" type="search" shadow placeholder="Nom du bucket" style="width: 85%; border-radius: 10px">
                            <template #icon>
                                <i style="color: darkgrey" class='bx bx-plus'></i>
                            </template>
                        </vs-input>
                    </div>
                
                    <p :class="!alreadyUsed ? 'hide-text' : ''" style="margin-bottom: 0; font-size: 15px;">Le bucket {{ name }} existe déjà</p>
                    <div>
                        <vs-button id="button" :disabled="alreadyUsed" @click="createBucket()" transparent>Créer</vs-button>
                    </div>
                </section>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "CreateBucketModal",
        data() {
            return {
                name: '',
                alreadyUsed: false
            }
        },
        props: {
            folders: Array,
            files_exists: Array
        },
        watch: {
            name: function() {
                if(this.folders.includes(this.name)) {
                    this.alreadyUsed = true;
                } else this.alreadyUsed = false;
            }
        },
        methods: {
            close() {
                this.$emit('close');
            },
            createBucket() {
                this.$emit('create', this.name);
                this.close();
            },
        }
    }
</script>

<style scoped>
    .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }

    .modal {
        background: #FFFFFF;
        box-shadow: 2px 2px 20px 1px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        width: 500px
    }

    .modal-header {
        padding: 15px;
        display: flex;
        position: relative;
        border-bottom: 1px solid #eeeeee;
        color: #2A69AF;
        justify-content: space-between;
        font-weight: bold;
        height: 40px;
    }

    .modal-body {
        position: relative;
    }

    .modal-fade-enter,
    .modal-fade-leave-to {
        opacity: 0;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity .5s ease;
    }

    .close {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    i {
        position: absolute;
        right: 15px;
        top: 10px;
        cursor: pointer;
    }

    p {
        text-align: center;
    }

    .modal-body > div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    span {
        font-weight: bold;
    }

    #button {
        margin-top: 3%;
        width: 50%;
    }

    .red-border {
        border: 1px solid rgb(229, 0, 81)
    }

    .hide-text {
        display: none;
    }

    #close {
        cursor: pointer; 
        color: black; 
        position: absolute; 
        top: 10px; 
        right: 10px;
        transition: 0.3s;
    }

    #close:hover {
        color: red;
    }
</style>