<template>
  <div class="versions-view-container">
    <FingerprintSpinner
        style="position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 10"
        :size="60"
        :color="'#0060be'"
        v-if="loading"
    />

    <Versions @loading="loadingComponent" />
  </div>
</template>

<script>
import {FingerprintSpinner} from 'epic-spinners'

import Versions from "../components/Versions/Versions";
export default {
  name: "VersionsView",
  components: {Versions, FingerprintSpinner},
  data() {
    return {
      loading: true
    }
  },
  methods: {
    loadingComponent(value){
      this.loading = value
    }
  }
}
</script>

<style scoped>

  h2 {
    color: #2c3e50!important;
    z-index: 3;
    font-size: 1.2em;
  }

  .versions-view-container {
    align-items: center;
    width: 100%;
    height: 94vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
  }
</style>
