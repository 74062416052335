<template>
  <div class="login-component-container">
    <svg style="top: 15%;" width="330" xmlns="http://www.w3.org/2000/svg" version="1.0"   viewBox="0 0 1349.000000 420.000000" preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,420.000000) scale(0.100000,-0.100000)" fill="#005eb8" stroke="none">
        <path d="M0 2100 l0 -2100 6745 0 6745 0 0 2100 0 2100 -6745 0 -6745 0 0 -2100z m13290 0 l0 -1900 -4445 0 -4445 0 0 1900 0 1900 4445 0 4445 0 0 -1900z m-9715 1408 c-34 -402 -138 -728 -322 -1006 -103 -155 -384 -423 -875 -835 l-168 -141 -1 -120 c0 -67 -4 -187 -8 -268 -7 -137 -9 -150 -32 -174 -28 -30 -291 -231 -313 -240 -18 -7 -32 38 -71 229 -16 75 -37 151 -47 168 -18 29 -22 31 -61 26 -23 -4 -71 -18 -106 -32 -85 -33 -122 -32 -174 4 -112 78 -273 254 -296 323 -12 36 -9 47 39 181 12 34 18 67 14 82 -7 30 -35 39 -268 89 -87 19 -160 36 -162 38 -6 7 65 105 172 240 l94 118 263 0 264 0 144 172 c406 487 655 751 809 861 121 86 342 197 479 241 133 43 308 81 431 95 52 6 109 13 125 15 82 9 77 14 70 -66z m-2645 -2127 c0 -4 -18 -47 -40 -93 -47 -100 -77 -219 -89 -352 -11 -110 -7 -132 22 -140 94 -25 404 39 497 104 38 26 57 30 69 16 9 -10 -3 -27 -52 -72 -151 -141 -341 -207 -622 -216 l-90 -3 2 90 c7 243 94 490 218 619 48 50 85 71 85 47z"/>
        <path d="M8708 2628 l-3 -313 -395 -5 c-426 -6 -437 -8 -551 -66 -64 -32 -155 -118 -201 -188 -119 -184 -141 -569 -47 -820 52 -139 164 -253 301 -306 109 -42 237 -53 538 -47 211 4 254 8 310 26 171 54 275 179 317 381 16 77 18 155 18 865 l0 780 -142 3 -143 3 -2 -313z m2 -885 c0 -421 -17 -484 -145 -548 l-60 -30 -265 0 c-248 0 -268 1 -310 21 -101 47 -157 130 -181 267 -15 90 -7 294 15 364 24 75 74 144 126 173 83 45 114 49 478 49 l342 1 0 -297z"/>
        <path d="M4570 2695 l0 -165 145 0 145 0 -2 163 -3 162 -142 3 -143 3 0 -166z"/>
        <path d="M4570 1595 l0 -715 140 0 140 0 0 715 0 715 -140 0 -140 0 0 -715z"/>
        <path d="M5521 2292 c-48 -24 -70 -58 -81 -127 -6 -35 -10 -320 -10 -672 l0 -613 145 0 145 0 0 575 0 575 24 6 c13 3 162 4 332 2 294 -4 312 -5 374 -28 80 -29 149 -91 181 -163 24 -52 24 -53 27 -509 l3 -458 144 0 145 0 0 429 c0 455 -7 541 -50 651 -66 168 -221 287 -430 330 -85 18 -138 20 -505 20 -371 0 -413 -2 -444 -18z"/>
        <path d="M9859 2292 c-90 -24 -173 -76 -221 -139 -87 -114 -102 -211 -95 -616 5 -307 9 -344 56 -435 49 -95 149 -170 271 -203 31 -8 203 -13 548 -16 l502 -5 0 141 0 140 -487 3 c-549 3 -528 0 -581 80 -23 36 -27 54 -30 129 l-4 87 409 5 c227 3 430 10 456 16 118 26 217 94 261 179 24 47 46 150 46 217 0 183 -74 323 -203 384 -97 46 -150 51 -521 50 -289 0 -351 -3 -407 -17z m770 -277 c53 -31 83 -97 79 -168 -5 -61 -23 -95 -72 -131 -26 -20 -41 -21 -422 -24 l-394 -3 0 101 c0 88 3 107 25 150 14 27 35 55 47 62 62 35 81 37 393 35 291 -2 312 -3 344 -22z"/>
        <path d="M11491 2222 c215 -224 554 -584 557 -591 2 -6 -432 -478 -656 -713 l-36 -38 177 0 176 0 258 275 c141 151 259 275 262 275 4 0 119 -124 256 -275 l250 -274 178 -1 c97 0 177 2 177 5 0 3 -150 164 -332 356 -183 193 -333 355 -333 360 0 4 128 144 285 311 157 166 305 323 328 350 l44 48 -175 0 -175 0 -243 -255 c-134 -140 -246 -255 -249 -255 -4 0 -111 115 -240 255 l-233 255 -180 0 -180 0 84 -88z"/>
        <path d="M2565 2902 c-36 -22 -89 -81 -110 -121 -69 -135 27 -309 190 -344 69 -14 142 7 203 59 62 52 86 105 86 184 1 53 -5 74 -29 118 -16 30 -47 68 -69 85 -38 31 -45 32 -141 35 -84 2 -105 0 -130 -16z"/>
      </g>
    </svg>

      <vs-input
          style="width: 60%; margin-top: 15%"
          label="Utilisateur"
          autocomplete="on"
          v-model="username"
          placeholder="Utilisateur*"
      />
      <vs-input
          style="margin-top: 10%; width: 60%; margin-bottom: 5%"
          type="password"
          v-model="password"
          label="Mot de passe"
          placeholder="Mot de passe*"
          @keyup.enter="logIn"
      />

      <vs-button
          @click="logIn"
          ref="button"
          :active="active === 1"
          color="#005eb8"
          style="width: 60%; margin: 0 auto; height: 3.5em" >
            Se connecter
      </vs-button>

    <router-link to="forgot-password" style="text-decoration: none">
      <h5 style="color: #2c3e50;"> 
        Mot de passe oublié 
      </h5>
    </router-link>
    
    <p style="position: absolute; bottom: 3%; font-size: 12px;">v{{ version }}</p>
    <Footer style="position: absolute; bottom: 0%"/>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import Footer from "@/components/Footer/Footer";

  export default {
    name: "Login",
    components: {Footer},
    data(){
      return {
        active: 0,
        username: '',
        password: '',
        loading: false,
        version: localStorage.getItem('version'),
      }
    },
    methods: {
      ...mapActions(["Users/login"]),
      logIn() {
        const loading = this.$vs.loading({
          target: this.$refs.button,
          scale: '0.6',
          background: 'primary',
          opacity: 1,
          color: '#fff'
        })
        this["Users/login"]({ username: this.username, password: this.password })
            .then(() => {
              this.$router.push({ name: "Dashboard" });
              loading.close()
            })
            .catch(() => {
              loading.close()
            });
      },
    }
  }
</script>

<style scoped>
  .login-component-container {
    height: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .vs-input {
    width:  400px!important;
  }
</style>
