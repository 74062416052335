<template>
  <div class="userInfo-view-container">
    <div class="item">

      <div class="sub-item" style="margin-left: 0; padding-left: 1%">
        <h1 style="margin-bottom: 0">{{user.first_name}} {{user.last_name}}</h1>
        <h4 style="font-weight: normal; color: grey; margin-bottom: 5%; margin-top: 0">{{user.role_type}}</h4>
      </div>

      <div class="sub-item" style="border-bottom: none; background-color: #eee; border-radius: 8px; display: flex; flex-direction: row; align-items: center">
        <div style="width: 3px; height: 100%; background-color: #FFFFFF; border-radius: 6px; margin-right: 3%"></div>
        <div>
          <h4>Email: <span style="font-weight: normal">{{ user.email }}</span></h4>
          <h4>Entreprise: <span style="font-weight: normal">{{ this.user.client.length > 0 ? user.client[0].name : 'non spécifiée' }}</span></h4>
          <h4>Adresse: <span style="font-weight: normal">{{  this.user.client.length > 0 ? user.client[0].address : 'non spécifiée' }}</span></h4>
        </div>
      </div>

      <div class="sub-item" style="border-bottom: none; background-color: #eee; border-radius: 8px; display: flex; flex-direction: row; align-items: center; margin-top: 2%">
        <div style="width: 3px; height: 100%; background-color: #FFFFFF; border-radius: 6px; margin-right: 3%"></div>
        <div>
          <h2 style="margin: 0">Responsable d'affaire</h2>
          <h4 v-if="ra.first_name !== undefined && ra.last_name !== undefined" style="font-weight: normal; color: grey;  margin-top: 0"><span style="text-transform: capitalize">{{ra.first_name}}</span> {{ra.last_name}}</h4>
          <h4 v-if="ra.email !== undefined" style="margin: 0">Email: <span style="font-weight: normal">{{ ra.email }}</span></h4>
          <h4 v-if="ra.first_name === undefined" style="font-weight: normal; color: grey;  margin-top: 0"><span style="text-transform: capitalize">Non spécifié</span></h4>
        </div>
      </div>

    </div>

  </div>
</template>


<script>
import Api from '../store/config'
export default {
  name: "UserInfosView",
  created() {
    this.user = this.$store.state.Users.currentUser
    this.str = this.$store.state.Users.currentUser.client.length > 0  ?this.$store.state.Users.currentUser.client[0].inventory_rule.split('\n') : '',
    this.getRAInfos()
    },
  data(){
    return {
      user: '',
      ra: {},
      str: '',
      group_rule: undefined
    }
  },

  methods: {
    getRAInfos(){
      if(this.user.client.length > 0){
        Api().get(`user/${this.user.client[0].ra_id}`)
            .then((res)=> {
              this.ra = res.data
            })
            .catch(()=> {})

      }
    }
  }
}
</script>

<style scoped>
  h2 {
    color: #2c3e50!important;
    z-index: 3;
    font-size: 1.2em;

  }

  h4{
    margin-top: 2%;
  }

  .item {

    height: 80%;
    background-color: white;
    border-radius: 8px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
  }


  .sub-item {
    padding: 1% 3%;
    margin-left: 1%;
  }


  .userInfo-view-container {
    margin: 0 auto;
    display: flex; flex-direction: column;
    width: 80%;
    justify-content: center;
    height: 100vh;
    text-align: left;
  }
</style>
