<template>
	<div style=" width: 90%; margin-bottom:3%; margin-top:3%">
		<!-- Heading Title + filtering  container-->
		<div
			style="display: flex; flex-direction: row; justify-content: space-between; align-items: center"
		>
			<div
				style="width: 100%; display: flex; flex-direction: row; justify-content: space-between; align-items: flex-end"
			>
				<h2 style="width: fit-content; display: flex; flex-direction: column">
					<span style="font-size: 1.3em">Produits</span>
					<div
						style="font-size: 0.8em;margin-left: 10px; color: grey; font-weight: lighter; width: 100%;"
					>
						# {{ loading ? "Chargement" : selectedVersionName ? selectedVersionName : version.name }}

						<span
							v-if="(currentVersion.name === selectedVersionName || version?.validated === true) && !loading"
							style="background-color: mediumseagreen; position: absolute; margin-left: 1%; width: fit-content; z-index: 3; font-size: 0.8em; color: rgba(255,255,255, 1); border-radius: 10px; padding: 3px"
							>Version courante</span
						>
						<span
							v-if="(currentVersion.name !== selectedVersionName || version?.validated === false) && !loading"
							style="background-color: darkgrey; position: absolute; margin-left: 1%; width: fit-content; z-index: 3; font-size: 0.8em; color: rgba(255,255,255, 1); border-radius: 10px; padding: 3px"
							>Version provisoire</span
						>
					</div>
				</h2>

				<p v-if="!loading">
					<strong>{{ count }}</strong> produits
				</p>
			</div>
		</div>

		<!-- Exception if no products found or API issue catched -->
		<p
			class="no-product-exception"
			style="color: darkgrey"
			v-if="products.results.length === 0"
		>
			{{ loading ? "Chargement" : "Aucun produit trouvé" }}
		</p>

		<div
			style="background-color: white;
    padding: 1% 2%;
    border-radius: 12px;"
		>
			<div class="filter-and-search-container">
				<!-- Search product input -->
				<vs-input
					ref="searchProductInput"
					@keyup="getProducts(0, 10)"
					v-model="referenceInput"
					type="search"
					style="width: 22rem"
					shadow
					placeholder="Rechercher un produit"
				>
					<template #icon>
						<i
							@click="getProducts(0, 10)"
							style="color: darkgrey"
							class="bx bx-search-alt"
						></i>
					</template>
				</vs-input>

				<!-- Filter container -->
				<div style="display: flex; flex-direction: row; margin-left: 2%">
					<vs-select
						filter
						multiple
						collapse-chips
						placeholder="Famille"
						style="width: 100%"
						v-model="filteringFamily"
					>
						<vs-option
							v-for="(item, index) in family"
							:key="index"
							:label="item.name"
							:value="item.id"
						>
							{{ item.name }}
						</vs-option>
					</vs-select>

					<!-- Filtering by maker -->
					<vs-select
						filter
						style="width: 14rem; margin-left: 1%"
						multiple
						collapse-chips
						placeholder="Constructeur"
						v-model="filteringMaker"
					>
						<vs-option
							v-for="(item, index) in makers"
							:key="index"
							:label="item.name"
							:value="item.id"
						>
							{{ item.name }}
						</vs-option>
					</vs-select>
				</div>
			</div>

			<div class="products-container">
				<div
					class="products-block"
					v-for="(item, index) in products.results"
					:key="index"
				>
					<div class="product-logo">
						<img
							:alt="item.maker.logo"
							v-if="item.maker.logo !== null"
							style="border-radius: 8px;"
							:src="baseURL + item.maker.logo"
						/>
						<img
							style="border-radius: 8px; height:75px;"
							v-else
							src="../../assets/defaultbox.png"
							alt="default-box"
						/>
					</div>

					<div class="product-title">
						<!-- Product maker -->
						<div
							style="font-size: 0.7em; color: slategrey; display: flex; flex-direction: column; text-align: left"
						>
							Référence
						</div>

						<vs-tooltip>
							<div class="product-reference">
								<div>
									<strong>{{
										item.reference === undefined
											? "Undefined"
											: item.reference.length > 40
											? item.reference.slice(0, 40) + "..."
											: item.reference
									}}</strong>
								</div>
								<div>
									<i>{{
										item.reference_const === undefined
											? "Undefined"
											: item.reference_const
									}}</i>
								</div>
							</div>
							<template #tooltip>
								<div
									style="font-family: 'Noto Sans JP', sans-serif; display: flex; flex-direction: column; justify-content: left; text-align: left"
								>
									<div>
										Ref:
										{{
											item.reference === undefined
												? "Undefined"
												: item.reference
										}}
									</div>
									<div>
										Ref constructeur:
										{{
											item.reference_const === undefined
												? "Undefined"
												: item.reference_const
										}}
									</div>
								</div>
							</template>
						</vs-tooltip>
					</div>

					<!-- Product description -->
					<div class="product-detail">
						<div style="font-size: 0.9em; color: grey">
							{{
								item.family_sub === undefined
									? "Undefined"
									: item.family_sub.name
							}}
						</div>

						<div>{{ item.description }}</div>
					</div>

					<!-- public price -->
					<div class="product-price" style="width: 15%">
						<span style="color: slategray; font-size: 0.9em">Prix public</span>
						<span>{{ item.public_price.toFixed(2) }} HT</span>
					</div>

					<!-- sale applied price -->
					<div class="product-price" style="width: 15%">
						<span style="color: slategray; font-size: 0.9em">Prix remisé</span>
						<span
							>{{
								(
									item.discount_price ? 
									item.public_price - (item.public_price - item.discount_price) :
									item.public_price
								).toFixed(2)
							}}
							HT</span
						>
					</div>

					<!-- Product buttons -->
					<div class="product-btns">
						<vs-button
							class="details-btn-title"
							icon
							color="tumblr"
							@click="showModal(item)"
						>
							<i class="bx bx-file-find options-icon"></i>
							<span class="options-btn-title">Détails</span>
						</vs-button>

						<!-- <div >
            <vs-button class="product-compare-btns" v-if="isCurrentVersion"  @click="goToCompareProducts(item)"><i class="bx bx-transfer-alt button-icon"></i> Equivalent constructeur</vs-button>
			</div> -->
					</div>
				</div>
			</div>
		</div>
		<ProductDetailsModal
			v-show="isModalVisible"
			@close="closeModal()"
			:isCurrentVersion="isCurrentVersion"
			:product="productDetail"
			:page="page"
		/>

		<!-- Pagination -->
		<vs-pagination
			v-if="products.results.length > 0"
			class="pagination"
			v-model="page"
			:length="parseInt(products.pages)"
		/>
	</div>
</template>

<script>
import Api from "../../store/config";
import ProductDetailsModal from "@/components/Products/Modals/ProductDetailsModal";

export default {
	name: "Products",
	components: {
		ProductDetailsModal,
	},
	props: {
		version: Object,
	},
	data: () => ({
		products: {
			results: [],
		},
		page: 1,
		isModalVisible: false,
		productDetail: undefined,
		makers: [],
		option: [],
		family: [],
		baseURL: process.env.VUE_APP_DEV
			? process.env.VUE_APP_DEV
			: `https://${window.location.hostname}/api/`,
		familySub: [],
		selectedVersion: "",
		selectedVersionName: "",
		referenceInput: "",
		versions: [],
		filteringFamily: [],
		filteringFamilySub: [],
		filteringMaker: [],
		currentVersion: {
			name: "",
		},
		filteringType: "",
		loading: true,
		focus: true,
	}),
	watch: {
		page(newValue) {
			this.getProducts((newValue - 1) * 10, 10);
		},
		referenceInput: function() {
			this.$refs["searchProductInput"].$el.focus();
			if (this.referenceInput.length === 0) {
				this.getProducts(0, 10);
			}
		},
		filteringFamily: function() {
			this.getProducts(0, 10);
		},
		filteringMaker: function() {
			this.getProducts(0, 10);
		},
		selectedVersion: function() {
			this.selectVersionAndGetProducts();
		},
		products: function() {
			if (this.products.results.length == 0) {
				if (this.focus != false) {
					this.focus = false;
					this.$nextTick(() => this.setFocusOnInput("searchProductInput"));
				}
			} else {
				if (this.focus != true) {
					this.focus = true;
					this.$nextTick(() => this.setFocusOnInput("searchProductInput"));
				}
			}
		},
	},
	async created() {
		this.getFamily();
	},
	async mounted() {
		if (this.$route.params.id && this.$route.params.page) {
			setTimeout(() => {
				this.page = parseInt(this.$route.params.page);
			}, 500);
			setTimeout(() => {
				this.productDetail = this.products.results.find(
					(item) => item.reference_const === this.$route.params.id
				);
				this.showModal(this.productDetail);
			}, 800);
		}
	},
	computed: {
		isCurrentVersion() {
			return this.currentVersion.name === this.selectedVersionName;
		},
		userRole() {
			return this.$store.state.Users.currentUser.role_type;
		},
		count: function() {
			return this.products.count;
		},
	},
	methods: {
		closeModal() {
			this.isModalVisible = false;
		},
		showModal(item) {
			this.productDetail = item;
			setTimeout(() => {
				this.isModalVisible = true;
				this.$modal.show(`ProductDetailsModal`);
			}, 200);
		},
		getMakers() {
			Api()
				.get("/maker/")
				.then((res) => {
					this.makers = res.data.results;
					if (this.version === undefined) {
						this.getCurrentVersion();
					} else {
						this.selectedVersion = this.version.id;
					}
				});
		},
		getFamily() {
			Api()
				.get("/family/")
				.then((res) => {
					this.family = res.data.results;
					this.getMakers();
				});
		},
		// Versions methods
		getVersions() {
			Api()
				.get(`/version/`)
				.then((res) => {
					this.versions = res.data;
					if (this.version === undefined) {
						this.getCurrentVersion();
					} else {
						this.selectedVersion = this.version.id;
						this.getProducts(0, 10);
					}
				});
		},
		getCurrentVersion() {
			Api()
				.get("/version/current-version-id")
				.then((response) => {
					this.currentVersion.id = response.data.id;
					this.selectedVersion = response.data.id;
					this.selectedVersionName = response.data.version;
					this.currentVersion.name = response.data.version;
					this.getProducts(0, 10);
				});
		},
		getVersionById(version_id) {
			Api()
				.get(`version/${version_id}`)
				.then((res) => {
					this.selectedVersionName = res.data.name;
				});
		},
		// Family methods
		getFamilyList() {
			Api()
				.get("/family/")
				.then((res) => {
					this.family = res.data;
				});
		},

		getFamilyFromId(family_id) {
			let output = this.family.results.filter((e) => e.id === family_id);
			return output[0].name;
		},

		// Family sub methods
		getFamilySubList() {
			Api()
				.get("/familysub/")
				.then((res) => {
					this.familySub = res.data;
				});
		},
		getFamilySubFromId(familySub_id) {
			let output = this.familySub.results.filter((e) => e.id === familySub_id);
			return output[0].name;
		},

		// Logo methods
		getLogo(product_id) {
			let output = this.makers.results.filter((e) => e.id === product_id);
			try {
				output[0].logo;
			} catch {
				this.item.maker.logo === "../../assets/defaultbox.png";
			}
		},

		// Products methods
		selectVersionAndGetProducts() {
			this.$emit("loading", true);
			this.referenceInput = "";
			let url = `/product/?offset=0&limit=10&version=${this.selectedVersion}`;
			//this.getVersionById(this.selectedVersion)

			Api()
				.get(url)
				.then((res) => {
					this.products = res.data;
					this.loading = false;
					this.$emit("loading", false);
				})
				.catch(() => {
					this.loading = false;
					this.$emit("loading", false);
				});
		},
		async getProducts(offset, limit) {
			this.$emit("loading", false);
			console.log('Je suis la')

			let url = this.version
				? this.referenceInput.length > 0
					? `/product/?offset=${offset}&limit=${limit}&reference=${this.referenceInput}&version=${this.version.id}&family_list=${this.filteringFamily}&maker_list=${this.filteringMaker}`
					: `/product/?offset=${offset}&limit=${limit}&version=${this.version.id}&family_list=${this.filteringFamily}&maker_list=${this.filteringMaker}`
				: this.referenceInput.length > 0
				? `/product/?offset=${offset}&limit=${limit}&reference=${this.referenceInput}&version=${this.selectedVersion}&family_list=${this.filteringFamily}&maker_list=${this.filteringMaker}`
				: `/product/?offset=${offset}&limit=${limit}&version=${this.selectedVersion}&family_list=${this.filteringFamily}&maker_list=${this.filteringMaker}`;

			Api()
				.get(url)
				.then((res) => {
					this.products = res.data;
					this.page = res.data.current_page;
					this.loading = false;
					this.$emit("loading", false);
					//this.$refs.searchProductInput.focus();
				})
				.catch(() => {
					this.loading = false;
					this.$emit("loading", false);
				});
			this.$refs["searchProductInput"].$el.focus();
		},
		goToCompareProducts(item) {
			this.$emit("loading", false);
			this.$router.push({
				name: "CompareProducts",
				params: { version: this.selectedVersion, reference: item },
			});
		},
		unselectProducts() {
			this.option = [];
		},
		setFocusOnInput(inputName) {
			let inputEl = this.$refs[inputName].$el.querySelector("input");
			inputEl.focus();
		},
	},
};
</script>

<style scoped>
input:focus {
	border: 5px solid green;
}

h2 {
	color: #2c3e50 !important;
	z-index: 3;
	font-size: 1.2em;
}

.pagination {
	position: absolute;
	transform: translate(-50%, -40%);
	left: 50%;
	bottom: 3%;
}

@media screen and (max-width: 1250px), (max-height: 780px) {
	.pagination {
		position: absolute;
		transform: translate(-50%, 20%);
		left: 50%;
		bottom: 3%;
	}
}

.no-product-exception {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.products-container {
	height: 65vmin;
	margin-top: 1%;
	display: flex;
	flex-direction: column;
	overflow: auto;
}

.products-block {
	margin-top: 0.5%;
	padding: 1%;
	margin-left: 1%;
	margin-right: 1%;
	border-radius: 8px;
	background-color: white;
	box-shadow: rgba(17, 17, 26, 0.1) 0 1px 0;
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-between;
}

.product-title {
	width: 30%;
}
.product-logo {
	width: 12%;
}
.product-reference {
	width: 75%;
	font-size: 0.9em;
	display: flex;
	flex-direction: column;
	text-align: left;
}
.product-price {
	width: 10%;
	font-size: 0.9em;
	display: flex;
	flex-direction: column;
	text-align: right;
}

.product-detail {
	width: 35%;
	font-size: 0.9em;
	display: flex;
	flex-direction: column;
}

.product-btns {
	width: 20%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.product-compare-btns {
	width: 7em;
	height: 3em;
	text-align: center;
	justify-content: center;
	flex-direction: column;
}

.button-icon {
	display: none;
}
.checkbox {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	width: 5%;
}
.filter-and-search-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
</style>
