<template>
  <div>
    <div style=" display: flex; flex-direction: row; position: absolute; align-items: center; right: 2%; top: 2%">  
      <vs-button dark transparent 
        v-if="$route.name !== 'Cart' && this.$store.state.Users.currentUser.role_type !== 'Responsable d\'Affaire'"
        @click="openSideCart" 
        badge-position="top-right" 
        style="height: 4.5em; width: 4.5em"
      >           
        <vs-avatar badge-color="blue" badge-position="top-right">
          <i class='bx bxs-cart' style="dark transparent"></i>  
          <template #badge>
            {{cartLength}}
          </template>
        </vs-avatar>
      </vs-button>

      <vs-button class= "profil-button"
        dark
        transparent
        :active="active === 2"
        @click="active = 2 && (userPanelVisible = !userPanelVisible)" style= "height: 4.5em; width: fit-content">   
          {{ currentUser.username }}
        <i class='bx bxs-chevron-down' />
      </vs-button>

      <div
        id="user-panel"
        v-click-outside="onClickOut"
        v-if="userPanelVisible">

        <vs-button 
            dark
            shadow
            @click="goToUserInfos"
            :active="active === 4">
              Profil
        </vs-button>

        <vs-button
            dark
            ref="logoutBtn"
            shadow
            :active="active === 4"
            @click="logout">
              Déconnexion
        </vs-button>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from "vuex";

  export default {
    name: "Navbar",
    props: {
      currentUser: Object,
      cart: Object
    },
    data: () => ({
      active: 0,
      userPanelVisible: false,
    }),
    watch: {
      $route(to) {
        if (to.name !== 'Cart') {
              this.active = 0
            }
          }
        },

    computed: {
    cartLength(){
        return this.cart.results ? this.cart.results.length : this.cart.length
      }
    },

    methods: {
      ...mapActions(["Users/logout"]),
      onClickOut() {
        this.userPanelVisible = !this.userPanelVisible
      },
      openSideCart(){
        this.$store.dispatch('Cart/setCartState', true)
      },
      goToUserInfos(){
        this.$router.push({name: 'UserInfos'})
      },
      logout() {
        this.$store.dispatch('Users/logout')
      }
    }
  }
</script>

<style scoped>
  #user-panel {
    z-index: 1000;
    background-color: #ffffff;
    color: black;
    height: fit-content;
    position: absolute;
    font-size: 0.9rem;
    display: flex;
    padding: 0.5% 2%;
    text-align: left;
    flex-direction: column;
    top: 40px;
    right: 2%;
    border-radius: 6px;
    width: auto;
    box-shadow: rgba(23, 43, 77, 0.133) 0px 3px 6px;
    -webkit-transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -moz-transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -o-transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }



</style>
