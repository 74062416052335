<template>
	<div style=" width: 80%">
		<FingerprintSpinner
			style="position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 10"
			:size="60"
			:color="'#0060be'"
			v-if="loading"
		/>

		<p
			style="position: fixed; top: 52%; left: 50%; transform: translateX(-50%); z-index: 10"
		>
			{{ loadingSentence }}
		</p>

		<div
			style="display: flex; flex-direction: row; align-items: center; position: relative"
		>
			<div>
				<h2>
					<span style="font-size: 1.3em; padding-bottom: 2%"
						>Validation
						<span
							v-if="!versionInfo.validated"
							style="font-size: 0.7em; margin-left: 10px; color: rgba(0, 0, 0, 0.3); font-weight: lighter"
							>{{ versionInfo.name }}</span
						>
					</span>
				</h2>
			</div>

			<div class="buttons">
				<vs-button v-if="!loading" icon @click="downloadFile()">
					<i class="bx bx-download" style="padding-right:5px"></i>
					Télécharger la comparaison
				</vs-button>

				<vs-button
					v-if="
						!loading &&
							!versionInfo.validated &&
							$store.state.Users.currentUser.role_type !== 'Client'
					"
					ref="btn"
					@click="validateVersion"
					color="success"
				>
					<i class="bx bx-check" style="padding-right:5px"></i>
					Valider version
				</vs-button>
			</div>
		</div>

		<!-- Exception if no products found or API issue catched -->

		<div style="background-color: white; border-radius: 12px; padding: 1% 2%">
			<div class="products-container" style="overflow: hidden">
				<div
					class="new-version"
					v-if="!loading && !versionInfo.validated"
					style="margin-top: 1%;"
				>
					<div
						style="background-color: #eee; padding: 2%; border-radius: 8px; height: 20vh;"
					>
						<span
							v-if="!versionInfo.validated"
							style="font-size: 0.7em; width: fit-content;font-weight: normal; background-color: darkgrey; color: white; border-radius: 8px; padding: 2px 3px;"
							>Version en attente de validation</span
						>
						<span
							v-if="versionInfo.validated"
							style="font-size: 0.7em;width: fit-content;font-weight: normal; background-color: mediumaquamarine;padding: 2px 3px; border-radius: 8px; color: white"
							>Version validée</span
						>

						<p style="color: grey">
							<strong>Date de la dernière version:</strong>
							{{ versionInfo.date_updated }}
						</p>
						<div class="div-grid">
							<p style="grid-area: add">
								<i class="bx bx-book-add"></i>
								<strong>{{
									versions.new_products.length
										? versions.new_products.length
										: "0"
								}}</strong>
								équipements ajoutés
							</p>
							<p style="grid-area: edit">
								<i class="bx bx-edit-alt"></i>
								<strong>{{
									versions.modified_products.length
										? versions.modified_products.length
										: "0"
								}}</strong>
								équipements édités
							</p>
							<p style="grid-area: unchange">
								<i class="bx bx-badge-check"></i>
								<strong>{{
									versions.unchanged_products.length
										? versions.unchanged_products.length
										: "0"
								}}</strong>
								équipements inchangés
							</p>
							<p style="grid-area: delete">
								<i class="bx bx-trash"></i
								><strong>{{
									versions.deleted_products.length
										? versions.deleted_products.length
										: "0"
								}}</strong>
								équipements supprimés
							</p>
						</div>
					</div>
				</div>
				<div
					v-if="versionInfo.validated"
					style="position:fixed; top: 50%; left: 50%; transform: translate(-50%, -50%) "
				>
					Aucune version en attente de validation
				</div>
				<div
					class="updated-products"
					v-if="!loading && !versionInfo.validated"
					style="margin-top: 1%"
				>
					<h3 style="margin: 1% 0 1% 0">Équipements</h3>
					<div
						style="display: flex; flex-direction: row; justify-content: space-between"
					>
						<div style="display: flex; flex-direction: row;width: 80%">
							<vs-button
								@click="selected = 'Tous'"
								transparent
								:active="selected === 'Tous'"
								style="margin-right: 2%"
								>Tous ({{ allProducts.length ? allProducts.length : "0" }})
							</vs-button>
							<vs-button
								@click="selected = 'Ajoutés'"
								transparent
								:active="selected === 'Ajoutés'"
								style="margin-right: 2%"
								>Ajoutés ({{ newProducts.length ? newProducts.length : "0" }})
							</vs-button>
							<vs-button
								@click="selected = 'Modifiés'"
								transparent
								:active="selected === 'Modifiés'"
								style="margin-right: 2%"
								>Modifiés ({{
									modifiedProducts.length ? modifiedProducts.length : "0"
								}})
							</vs-button>
							<vs-button
								@click="selected = 'Freeze'"
								transparent
								:active="selected === 'Freeze'"
								style="margin-right: 2%"
								>Non modifiés ({{
									noUpdateProducts.length ? noUpdateProducts.length : "0"
								}})</vs-button
							>
							<vs-button
								@click="selected = 'Supprimés'"
								transparent
								:active="selected === 'Supprimés'"
								style="margin-right: 2%"
								>Supprimés ({{
									deletedProducts.length ? deletedProducts.length : "0"
								}})</vs-button
							>
						</div>
						<vs-input
							icon-after
							placeholder="Chercher référence constructeur"
							v-model="search"
							style="width: 25%"
						>
							<template #icon>
								<i class="bx bx-search-alt-2"></i>
							</template>
						</vs-input>
					</div>
					<div style="height: 100%; overflow-y: auto; margin-top: 1%">
						<FreezeTable
							:frozen="noUpdateProducts"
							v-if="selected === 'Freeze'"
						/>
						<ModTable
							:modified="modifiedProducts"
							v-if="selected === 'Modifiés'"
						/>
						<AddedTable
							:added_devices="newProducts"
							v-if="selected === 'Ajoutés'"
						/>
						<DeleteTable
							:deleted="deletedProducts"
							v-if="selected === 'Supprimés'"
						/>
						<AllDevicesTable
							:devices="allProducts"
							v-if="selected === 'Tous'"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Api from "../../store/config";
import { FingerprintSpinner } from "epic-spinners";
import FreezeTable from "./Validation/FreezeTable";
import DeleteTable from "./Validation/DeleteTable";
import ModTable from "./Validation/ModTable";
import AllDevicesTable from "@/components/Versions/Validation/AllDevicesTable";
import AddedTable from "@/components/Versions/Validation/AddedTable";
export default {
	name: "Validation",
	components: {
		AllDevicesTable,
		ModTable,
		DeleteTable,
		FreezeTable,
		FingerprintSpinner,
		AddedTable,
	},
	data: () => ({
		loading: true,
		selected: "Modifiés",
		loadingSentence: "",
		search: "",
		versionInfo: {},
		versions: {
			new_product_or_changed: [],
			product_deleted: [],
			product_with_no_update: [],
		},
	}),
	computed: {
		newProducts() {
			return this.versions.new_products.filter(
				(e) =>
					e.reference_const.includes(this.search) ||
					e.reference.includes(this.search) ||
					e.description.includes(this.search)
			);
		},
		deletedProducts() {
			return this.versions.deleted_products.filter(
				(e) =>
					e.reference_const.includes(this.search) ||
					e.reference.includes(this.search) ||
					e.description.includes(this.search)
			);
		},
		noUpdateProducts() {
			return this.versions.unchanged_products.filter(
				(e) =>
					e.reference_const.includes(this.search) ||
					e.reference.includes(this.search) ||
					e.description.includes(this.search)
			);
		},
		modifiedProducts() {
			return this.versions.modified_products.filter(
				(e) =>
					e.reference_const.includes(this.search) ||
					e.reference.includes(this.search) ||
					e.description.includes(this.search)
			);
		},
		allProducts() {
			return this.versionInfo.products.filter(
				(e) =>
					e.reference_const.includes(this.search) ||
					e.reference.includes(this.search) ||
					e.description.includes(this.search)
			);
		},
	},
	mounted() {
		this.getLastVersionInfos();
	},
	methods: {
		validateVersion() {
			this.loading = true;
			this.loadingSentence = "Validation ...";
			Api()
				.post(`version/validate-version/${this.versionInfo.id}`)
				.then(() => {
					this.$vs.notification({
						position: "top-left",
						title: `Validation`,
						text: `La version ${this.versionInfo.name} a été validé`,
						color: "success",
					});
					this.$router.push("/dashboard/products/navigate");
					this.getLastVersionInfos();
				})
				.catch(() => {});
		},
		getLastVersionInfos() {
			this.loadingSentence = "Chargement des informations de la version ...";

			Api()
				.get("version/last-version")
				.then((res) => {
					this.versionInfo = res.data;
					this.getVersionsCompare();
				})
				.catch(() => {});
		},
		getVersionsCompare() {
			this.loadingSentence = "Comparaison des versions ...";
			Api()
				.get("version/compare-version")
				.then((res) => {
					this.versions = res.data;
					this.loadingSentence = "";
					this.loading = false;
					this.$emit("loading");
				})
				.catch(() => {
					this.loading = false;
				});
		},
		downloadFile() {
			Api()
				.get("version/download-compare-version", {
					responseType: 'blob'
				})
				.then((response) => {
					const fileURL = URL.createObjectURL(response.data);

					const anchor = document.createElement("a");
					anchor.href = fileURL;
					let name = `${this.versionInfo.name}-${new Date().toLocaleDateString()}`
					anchor.download = name;

					document.body.appendChild(anchor);
					anchor.click();
					document.body.removeChild(anchor);

					URL.revokeObjectURL(fileURL);
				});
		},
	},
};
</script>

<style scoped>
h2 {
	color: #2c3e50 !important;
	z-index: 3;
	font-size: 1.2em;
}

.products-container {
	height: 73vmin;
	display: flex;
	flex-direction: column;
	overflow: auto;
}

.new-version {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	height: 25%;
}
.updated-products {
	height: 62%;
}

p {
	font-size: 0.9em;
}

.div-grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	grid-template-areas:
		"add edit"
		"unchange delete";
}

.div-grid > p {
	margin: 3px;
}

.buttons {
	position: absolute;
	display: flex;
	float: right;
	right: 0;
}
</style>
