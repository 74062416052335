<template>
  <div style="width: 80%; height: 60vmin; background-color: white">
    <FingerprintSpinner
      style="
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
      "
      :size="60"
      :color="'#0060be'"
      v-if="loading"
    />
    <p
      v-if="loading"
      style="
        position: fixed;
        top: 52%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
      "
    >
      Chargement des commandes ...
    </p>
    <p
      v-if="filterCommands.length == 0 && !loading"
      style="
        position: fixed;
        top: 52%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
      "
    >
      Aucune commande trouvée
    </p>
    <!-- Heading Title container-->

    <div class="products-container">
      <div v-if="!loading">
        <div id="commands-count-parent">
          <h2>
            <span
              v-if="
                commandsUser.count || (commandsEverybody.count != 0 && !loading)
              "
              class="commands-count"
              style="margin-left: 15px"
            >
              {{
                !filterCommands.length ? "0" : filterCommands.length
              }}
              commandes</span
            >
          </h2>
        </div>

        <div
          v-if="filterCommands"
          class="commands-container"
          style="width: 125%"
        >
          <div v-for="(item, index) in filterCommands" :key="index">
            <TemplateCommands @getCommands="getCommands()" :commands="item" />
          </div>
        </div>
        <div v-else>
          <p
            style="
              position: fixed;
              top: 52%;
              left: 50%;
              transform: translate(-50%, 80%);
              z-index: 10;
            "
          >
            Il n'y a aucune commande à afficher
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TemplateCommands from "../TemplateCommands";
import { FingerprintSpinner } from "epic-spinners";

export default {
  name: "AllCommands",
  components: {
    TemplateCommands,
    FingerprintSpinner,
  },
  props: {
    active: String,
    commandsUser: Object,
    commandsEverybody: Object
  },
  data() {
    return {
      loading: false,
      currentUser: "",
    };
  },
  computed: {
    filterCommands() {
      if (!this.commandsUser || !this.commandsEverybody) return
      return this.active == "my_commands"
        ? this.commandsFinished(false, this.commandsUser.results) 
        : this.active == "all_commands"
        ? this.commandsFinished(false, this.commandsEverybody.results)
        : this.commandsEverybody.results == true;
    },
  },
  methods: {
    commandsFinished(status, commands) {
      return commands.filter((command) =>
        status ? command.finished : !command.finished
      );
    },
    getCommands() {
      this.$emit('getCommands')
    }
  },
};
</script>

<style scoped>
h2 {
  color: #2c3e50 !important;
  z-index: 3;
  font-size: 1.2em;
}

.no-commands-exception {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.commands-container {
  margin-top: 0%;
  width: auto;
  height: 60vmin;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.SpanTitle {
  font-size: 0.7em;
  margin-left: 10px;
  color: rgba(0, 0, 0, 0.3);
  font-weight: lighter;
}

.commands-count-parent {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.commands-count {
  font-size: 0.8em;
  color: grey;
  font-weight: lighter;
}
</style>
