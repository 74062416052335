<template>
    <div id="tracker_global">
        <div id="tracker_logo">
            <i v-if="status == 'ordered'" class='bx bx-package blue tracker_first_logo' />
            <i v-else class='bx bx-package grey' />

            <i v-if="status == 'shipped'" class='bx bxs-truck tracker_second_logo blue' />
            <i v-else class='bx bxs-truck tracker_second_logo grey' />

            <i v-if="status == 'finished'" class='bx bxs-user-check tracker_third_logo blue' />
            <i v-else-if="status == 'shipped'" class='bx bxs-user-check tracker_third_logo_rebuild grey' />
            <i v-else class='bx bxs-user-check tracker_third_logo grey' />
        </div>
        
        <div id="tracker_canvas" 
            :class="modal == 'info' ? 'traker_canvas_increase_margin' : 
                    modal == 'normal' ? 'traker_canvas_normal_margin' : 
                    modal == 'validate' ? 'traker_canvas_increase2_margin' : 
                    modal == 'change' ? 'traker_canvas_increase3_margin' :
                    'traker_canvas_increase_margin'">
            <span v-if="status == 'ordered' || status == 'shipped' || status == 'finished'" class="dot_delivery blue_round" />
            <span v-else class="dot_delivery grey_round" />
            <span v-if="status == 'shipped' || status == 'finished'" class="line_delivery blue_line" />
            <span v-else class="line_delivery grey_line" />
            <span v-if="status == 'shipped' || status == 'finished'" class="dot_delivery blue_round" />
            <span v-else class="dot_delivery grey_round" />
            <span v-if="status == 'finished'" class="line_delivery blue_line" />
            <span v-else class="line_delivery grey_line" />
            <span v-if="status == 'finished'" class="dot_delivery blue_round" />
            <span v-else class="dot_delivery grey_round" />
        </div>
    </div>
</template>

<script>
    export default {
        name: "DeliveryTracker",
        props: {
            status: String,
            modal: String
        },
    }
</script>

<style scoped>
    #tracker_global {
        width: 75%;
    }

    #tracker_canvas {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .traker_canvas_normal_margin {
        margin-right: 55px;
    }

    .traker_canvas_increase_margin {
        margin-right: 100px;
    }

    .traker_canvas_increase2_margin {
        margin-right: 210px;
    }

    .traker_canvas_increase3_margin {
        margin-right: 160px;
    }

    #tracker_logo {
        width: 80%;
    }

    .dot_delivery {
        height: 10px;
        width: 10px;
        background-color: black;
        border-radius: 50%;
        display: inline-block;
    }

    .line_delivery {
        color:#fff;
        background-color: #fff;
        height: 1px;
        width: 50px;
        display: inline-block;
    }

    .tracker_first_logo {
        margin-left: -5px;
    }

    .tracker_second_logo {
        margin-left: 40px;
    }

    .tracker_third_logo {
        margin-left: 45px;
    }

    .tracker_third_logo_rebuild {
        margin-left: 38px;
    }

    .blue {
        color: #2A69AF;
        font-size: 27px;
    }

    .grey {
        color: #878787;
        opacity: 0.5;
        font-size: 16px;
    }

    .blue_round {
        background-color: #2A69AF
    }

    .grey_round {
        background-color: white;
        border: 1px dotted black;;
    }

    .blue_line {
        border-top: 1px solid #2A69AF;
    }

    .grey_line {
        border: none;
        border-top:1px dotted black;
    }
</style>