import Vue from 'vue'
import store from '../'
import Api from '../config'

const state = {
    currentUser: JSON.parse(  sessionStorage.getItem('currentUser')) || null,
    users: [],
    groups: [],
    rememberMe: undefined,
    token:   localStorage.getItem('token') || null,
}

const actions = {
    getUsers({commit}) {
         Api().get('/user/').then((response) => {
             commit('SET_USERS', response.data.results)
         })

    },
    login(context, credentials) {
        return new Promise((resolve, reject) => {
            let creds = Buffer.from(credentials.username + ':' + credentials.password)
            Api()
                .post('/auth/login', {}, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: 'Basic '+ creds.toString('base64')
                    }
                })
                .then(res => {
                    let user = res.data.user
                    let response = res.data
                    if (response.token) {
                        localStorage.setItem('token', response.token)
                        localStorage.setItem('currentUser', JSON.stringify(user))
                        context.commit('SET_CURRENTUSER', user)
                        context.commit('SET_TOKEN', response.token)
                        resolve(response.token)

                    }
                })
                .catch(e => {
                    let text = 'Une erreur inattendu est survenue'
                    if(e.response.status === 401) {
                        text = 'Mot de passe ou identifiant incorrect'
                    }
                    if(e.response.status === 403) {
                        text = `Suite à un trop gros nombre de requêtes vous avez été bannis pour ${e.response.data.retry}s`
                    }
                    reject(e)
                    this._vm.$vs.notification({
                        position: 'top-left',
                        title: 'Erreur',
                        text: text
                    })
                })
        })
    },
    addUser(context, user) {
        if(user.group === 'default') {user.group = "undefined"}
        Api()
            .post('user/', {
                'username': user.username,
                'password': user.password,
                'first_name': user.firstname,
                'last_name': user.lastname,
                'email': user.email,
                'role_type': user.roletype,
                'group': user.group,
                'isAdmin': user.isAdmin === "Admin"
            })
            .then(() => {
                Vue.toasted.success(`${user.username} successfully added !`, {
                    icon: {
                        name: 'fas fa-shield-check'
                    }
                });
                store.dispatch('Miscellaneous/slideoutSiteIsHidden')
                context.dispatch('getUsers')
            })
            .catch(() => {
                Vue.toasted.error(`There was an error when trying to execute your request`, {
                    icon: {
                        name: 'fas fa-ban'
                    }
                });
            })
    },
    updateUserInfo(context, user){
        Api()
            .put(`user/${user.id}/`,)

    },
    async updateUserPassword(context, data) {
        await Api().post('auth/reset-password', data)
    },
    deleteUser({commit}, id) {
        Api()
            .delete(`user/${id}`)
            .then(() => {
                commit('REMOVE_USER', id)
            })
    },
    logout(context) {

        try {

            Api()
                .post('/auth/logout', {}, {
                    headers: {
                        Authorization: localStorage.getItem('token')
                    }
                })
                .then(() => {
                    // store version into var
                    let version = localStorage.getItem("version")
                    localStorage.clear()
                    localStorage.setItem("version", version)
                    context.commit('REMOVE_TOKEN')
                    context.commit('REMOVE_HASURA_TOKEN')

                })
        } catch (error) {

            localStorage.clear()
            context.commit('REMOVE_TOKEN')
        }
    },
    setCurrentUser(context, currentUser){
        context.commit('SET_CURRENTUSER', currentUser)

    },
    REMEMBER_ME({ commit }, rememberMe) {
        commit('SET_REMEMBER_ME',rememberMe )
    }
}


const mutations = {
    SET_TOKEN(state, token) {
        state.token = token
    },
    SET_HASURA_TOKEN(state, hasuraToken) {
        state.hasuraToken = hasuraToken
    },
    REMOVE_TOKEN(state) {
        state.token = null
    },
    REMOVE_HASURA_TOKEN(state) {
        state.hasuraToken = null
    },
    SET_CURRENTUSER(state, user) {
        state.currentUser = user
    },
    SET_USERS(state, users) {
        state.users = users
    },
    SET_GROUPS(state, groups) {
        state.groups = groups
    },
    SET_REMEMBER_ME(state, rememberMe) {
        state.rememberMe = rememberMe
    },
    REMOVE_USER(state, id) {
        state.users = state.users.filter(user => user.id !== id)
    }
}

const getters = {
    isLoggedIn(state) {
        return state.token != null
    },
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
