<template>
    <div id="main">
        <div id="header">
            <div>
                <h2>
                    <span id="title-span">
                        Stockage
                    </span>
                    <span id="subtitle-span">
                        parcourir les dossiers / fichiers
                    </span>
                </h2>
            </div>
        </div>

        <div class="main-container">
            <div class="files-container">
                <div class="folder">
                    <Folders @activeFolder="changeFolder" @create="createBucket" @refresh="getFolders" :folders="folders" :loading="loadingFolders"/>
                </div>
                <div style="height: 72vmin">
                    <Files v-if="files" @refresh="getFiles" :files="files" :folder="activeFolder ? activeFolder : folders[0]" :loading="loadingFiles"/>
                    <span style="position: fixed; top: 50%; left: 56%; align-items: center; color: darkgrey" v-if ="files.length == 0">Aucun fichier disponible</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Api from "../../store/config";
    import Folders from "./Components/Folders";
    import Files from "./Components/Files";

    export default {
        name: "Storage",
        data() {
            return {
                folders: [],
                files: [],
                loadingFolders: false,
                loadingFiles: false,
                activeFolder: ''
            }
        },
        components: {
            Folders,
            Files,
        },
        created() {
            this.getFolders();
        },
        watch: {
            folders: function() {
                this.activeFolder = this.folders[0]
                this.getFiles();
            },
            activeFolder: function() {
                this.getFiles();
            }
        },
        methods: {
            getFolders() {
                this.loadingFolders = true,
                Api().get('/minio/buckets')
                    .then((res) => {
                        this.folders = res.data;
                        this.loadingFolders = false;
                    })
            },
            getFiles() {
                if(this.activeFolder && this.folders) {
                    this.loadingFiles = true;
                    Api().get(`/minio/buckets?bucket=${this.activeFolder}`)
                        .then((res) => {
                            this.files = res.data;
                            this.loadingFiles = false;
                        })
                        .catch(() => {
                            this.loadingFiles = false;
                        })
                }
                this.loadingFiles = false;
            },
            createBucket(value) {
                Api().post(`/minio/buckets?bucket=${value}`)
                    .then(() => {
                        this.getFolders();
                    })
                    .catch((err) => {
                        this.$vs.notification({
                            position: 'top-left',
                            title: `Erreur téléchargement` ,
                            text: `Raison de l'erreur: ${err}` ,
                            color: 'error'
                        })
                    })
            },
            changeFolder(value) {
                this.activeFolder = value;
            }
        }
    }
</script>

<style scoped>
    #main {
        width: 80%;
    }

    #header {
        display: flex; 
        flex-direction: row; 
        justify-content: space-between; 
        align-items: center
    }

    #title-span {
        font-size: 1.3em; 
        padding-bottom: 2%
    }

    #subtitle-span {
        font-size: 0.7em;
        margin-left: 10px; 
        color: rgba(0, 0, 0, 0.3); 
        font-weight: lighter
    }

    .main-container {
        background-color: white;
        padding: 1% 2%;
        border-radius: 12px;
        width: 98%;
        height: 75vmin;
    }

    .files-container {
        height: 73vmin;
        margin-top: 1%;
        display: grid;
        grid-template-columns: 1fr 3fr;
    }

    .folder {
        border-radius: 10px;
        margin-right: 15px;
        width: 93%;
        height: 72vmin;
        box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, 
                    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    }
</style>
