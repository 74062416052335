<template>
  <div class="notification-view-container">
    <div class="item">
      <h2 style="margin-bottom: 1%; border-bottom: 2px solid #eee; padding-bottom: 1%">Notifications</h2>

    </div>
  </div>
</template>


<script>
export default {
  name: "NotificationsView"
}
</script>

<style scoped>
h2 {
  color: #2c3e50 !important;
  z-index: 3;
  font-size: 1.2em;
}


.item {
  padding: 1% 3%;
  margin-left: 1%;
  height: 80%;
  background-color: white;
  border-radius: 8px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
}


.notification-view-container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 80%;

  justify-content: center;
  height: 100vh;
  text-align: left;
}
</style>
