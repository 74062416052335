<template>
  <div class="notFound">
    <div class="box_rocket">
      <img class="object_rocket" src="../assets/rocket.svg" width="160px">
    </div>
    <div class="main-text">
      <h1>404</h1>
      <h3>There's nothing here..</h3>

      <h5  class="quote" style="padding: 40px">« If the Earth gets hit by an asteroid, it's game over. It's control-alt-delete for
        civilization »</h5>

      <h3 class="quote" style="text-align: right; padding-right: 60px;">- Bill Nye</h3>

      <a class="footer" href="/"><h2>Return to Earth</h2></a>

    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>


@-moz-keyframes move { 100% { -moz-transform: translate(1200px,-600px); } }
@-webkit-keyframes move { 100% { -webkit-transform: translate(1200px,-600px); } }
@keyframes move { 100% { -webkit-transform: translate(1200px,-600px); transform:translate(1200px,-600px); } }

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }





.notFound {
  position: fixed;
  background: linear-gradient(to right top, #005eb8, #005699, #004a77, #003d57, #0f2f39);
  width: 100%;
  height: 100%;
}



a{
  color: #fff;
  font-size: xx-large;
  font-weight: 100;
  text-align: center;
}

.main-text{
  width: 100%;
  height: 100%;
  text-align: center;
  position: absolute;
  top: 30%;
  color: #fff;
  font-size: xx-large;
  font-weight: 300;
  z-index: 110 !important;
  text-shadow: 4px 4px 20px  rgba(0,0,0,.6);
}

.quote{
  font-weight: 100;
  text-shadow: none;
}
.footer{
  text-shadow: none;
  color: #fff;
  font-weight: 100;
}

.box_rocket{
  z-index: 10;
  position: absolute;
  top: 40%;
  left: 20%;

  animation: move 20s linear  infinite both running;
}

.object_rocket{
  animation:spin 12s linear  infinite both running;
}




</style>
