import axios from 'axios';
import store from "../index";
const https = require('https');


export default () => {
    let instance =  axios.create({

        baseURL: process.env.VUE_APP_DEV ? process.env.VUE_APP_DEV : `https://${window.location.hostname}/api/`,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization':   localStorage.getItem('token') ?   localStorage.getItem('token') : '',
        },
        httpsAgent: new https.Agent({
            rejectUnauthorized: false
        })
    })

    instance.interceptors.response.use(null, function (error) {
        if (error.response.status === 401) {
            store.dispatch('Users/logout').then(()=> {
                this.$router.push({
                    name: "Login"
                });
            })
        }
        return Promise.reject(error)
    })

    return instance

}

