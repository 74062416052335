<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view   />
    </transition>

  </div>
</template>

<script>
import Api from './store/config'
export default {
  name: 'App',
  created() {
    this.authCall(),
    this.setCurrentVersion()
  },
  watch: {
    '$route': function () {
      if(this.$route.name !== "Login" && this.$route.name !== "ForgotPassword"){
        this.authCall()
      }
    }
  },
  methods: {
    authCall(){
      Api().get('auth/check')
    },
    // Set current version cookie
		setCurrentVersion() {
			let version = process.env.VUE_APP_MAP_VERSION ? process.env.VUE_APP_MAP_VERSION : "dev"
			localStorage.setItem("version", version)
		}
  }

}
</script>

<style>

body {
  margin: 0;
  max-height: 100%;
  max-width: 100%;

}

#app {
  font-family: 'Noto Sans JP', sans-serif;  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  text-align: center;
  color: #2c3e50;
}

.router-link-active {
  color: red;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 0.3s ease;
}

.fade-leave {}

.fade-leave-active {
  transition: opacity 0.3s ease;
  opacity: 0;
}


</style>
