<template>
  <modal name="AddUserModal" width="800px" height="35%" classes="modal-override">
    <div class="modal-container">
      <div class="modal-header">
        <div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; align-items: center">
          <p>Nouvel utilisateur</p>
          <vs-button @click="createUser" active :disabled="!canSubmitUser" transparent >Créer utilisateur</vs-button>
        </div>
      </div>
      <div style="flex: 1 1 0; padding: 1% 3%; display: flex; flex-direction: column; justify-content: space-evenly; ">


        <div style="display: flex; flex-direction: row; justify-content: space-around">
          <vs-input style="width: fit-content; margin-top: 1%" label="Nom d'utilisateur" v-model="userInfos.username" placeholder="Nom d'utilisateur" />
          <vs-input style="width: fit-content; margin-top: 1%" label="Email"  v-model="userInfos.email" placeholder="Email" />
          <vs-input style="width: fit-content; margin-top: 1%" label="Password" type="password"  v-model="userInfos.password" placeholder="Password" />
        </div>

        <div style="display: flex; flex-direction: row; justify-content: space-around">
          <vs-input  style="width: fit-content" label="Nom" v-model="userInfos.last_name" placeholder="Nom" />
          <vs-input style="width: fit-content; margin-top: 1%" label="Prénom"  v-model="userInfos.first_name" placeholder="Prénom" />
          <div style="width: auto; display: flex; flex-direction: column; justify-content: center; align-items: center">
            <div style="font-size: 0.8em; color: #2c3e50;">Role</div>
            <select v-model="userInfos.role_type" >
              <option value="" disabled>Selectionnez le type de role</option>
              <option value="Administrateur">Administrateur</option>
              <option value="Responsable d'Affaire">Responsable d'Affaire</option>
              <option value="Client">Client</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Api from "../../../store/config"
export default {
  name: "AddUserModal",
  data() {
    return {
      userInfos: {
        role_type: '',
        last_name: '',
        first_name: '',
        password: '',
        username: '',
        email: '',
        client_id: this.$store.state.Users.currentUser.client[0],
        entity_id: 1,
        isAdmin: false
      }
    }
  },
  computed: {
    canSubmitUser(){
      if(this.userInfos.role_type === '' || this.userInfos.password === '' ||  this.userInfos.last_name === '' || this.userInfos.first_name === '' || this.userInfos.username === '' || this.userInfos.email === ''){
        return false
      }
      else {
        return true
      }
    },
    userRole() {
      return this.$store.state.Users.currentUser ? this.$store.state.Users.currentUser.role_type : []
    },
  },
  methods: {
    createUser(){
      let body = this.userInfos
      Api().post('user/',  body )
      .then(() => {
        this.$vs.notification({
          title: 'Ajout',
          text: `L'utilisateur ${this.userInfos.username} avec le rôle ${this.userInfos.role_type} a été ajouté`,
          position: 'top-left'
        })
        this.$emit('userAdded')
        this.$modal.hide('AddUserModal')
      })
      .catch()
    }
  }
}
</script>

<style scoped>

.modal-container {
  padding: 0%; background-color: #fafbfc;
  height: 100%; width: 100%;
  display: flex; flex-direction: column;
}

.modal-header {
  padding: 3% 3% 1% 3%;
  height: 10%;
  align-items: center;
  background-color: #FFFFFF;
  border-bottom: 2px solid #eee;
  display: flex; flex-direction: row; justify-content: space-between;
}



</style>
