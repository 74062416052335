<template>
  <div class="center">


  </div>
</template>

<script>

  export default {
    name: "ProductDetailsButton",
    components: {},
    props: {
      isCurrentVersion: Boolean,
      product: Object,
      currentVersionId: Number
    },
    data(){
      return {
        active: false,
        isModalVisible: false,
      }
    },
    methods: {
      showModal() {
        this.$modal.show(`ProductDetailsModal_${this.product.id}`)
        this.isModalVisible = true;
      },
      closeModal() {
        this.isModalVisible = false;
      }
    }
  }
</script>

<style scoped>

  .options-icon {
    display: none;
  }

.details-btn-title {
  width: 7em;
  height:3em;
  text-align: center;
}

</style>
