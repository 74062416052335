<template>
  <div class="cart-view-container">
    <Cart/>
  </div>
</template>

<script>
import Cart from "@/components/Cart/Cart";
export default {
  name: "CartView",
  props: {
    cart: Object
  },
  components: {Cart}
}
</script>

<style scoped>

  .cart-view-container {
    align-items: center;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
  }
</style>
