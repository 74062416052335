<template>
  <div style=" width: 68%" >

    <!-- Heading Title + filtering  container-->
    <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center" >
      <svg @click="sendBack()" clip-rule="evenodd" fill-rule="evenodd" width="24" height="24" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m9.474 5.209s-4.501 4.505-6.254 6.259c-.147.146-.22.338-.22.53s.073.384.22.53c1.752 1.754 6.252 6.257 6.252 6.257.145.145.336.217.527.217.191-.001.383-.074.53-.221.293-.293.294-.766.004-1.057l-4.976-4.976h14.692c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-14.692l4.978-4.979c.289-.289.287-.761-.006-1.054-.147-.147-.339-.221-.53-.221-.191-.001-.38.071-.525.215z" fill-rule="nonzero"/></svg>
        <h2 style="color: black!important;">
          <span style="font-size: 1.3em; padding-bottom: 2%">Historique</span>
          <span style="font-size: 0.7em; margin-left: 10px; color: rgba(0, 0, 0, 0.3); font-weight: lighter">Equipement</span>
        </h2>
        <h2>{{ product_reference }}</h2>
    </div>


    <div style="background-color: white;
    padding: 1% 2%;
    border-radius: 12px;">

      <div style="display: flex; align-items: center">
        <div style="padding: 10px 15px; cursor: pointer" :style="{backgroundColor: selectTab === 1 ? 'black' : undefined, color: selectTab === 1 ? 'white' : undefined}" @click="selectTab = 1">
          <p style="margin: 0">Graphique</p>
        </div>
        <div style="padding: 10px 15px; cursor: pointer" :style="{backgroundColor: selectTab === 2 ? 'black' : undefined, color: selectTab === 2 ? 'white' : undefined}" @click="selectTab = 2">
          <p style="margin: 0">Information</p>
        </div>
      </div>
      <hr style="margin: 0; border-color: #000000">


      <div v-if="selectTab === 1" class="products-container">
        <VueApexCharts :key="updating_chart" width="1000px" height="100%" type="line" :options="options" :series="series"></VueApexCharts>

      </div>

      <div v-if="selectTab === 2" style="overflow-y: scroll; max-height: 65vh; overflow-x: hidden">

        <div class="products-block"  v-for="(item, index) in productHistory" :key="index">

          <div class="product-logo">
            <img :alt="item.maker.logo" v-if="item.maker.logo !== null" style="border-radius: 8px;"   :src="baseURL  + item.maker.logo" />
            <img style="border-radius: 8px; height:75px;" v-else src="../../assets/defaultbox.png" alt="default-box"/>
          </div>

          <div class="product-title">
            <!-- Product maker -->
            <div style="font-size: 0.7em; color: slategrey; display: flex; flex-direction: column; text-align: left">Référence</div>


            <vs-tooltip>
              <div class="product-reference">
                <div><strong>{{ item.reference === undefined ? 'Undefined' : ( item.reference.length > 40 ? item.reference.slice(0,40) + '...' : item.reference )}}</strong></div>
                <div><i>{{ item.reference_const === undefined ? 'Undefined' : item.reference_const }}</i></div>
              </div>
              <template  #tooltip>
                <div style="font-family: 'Noto Sans JP', sans-serif; display: flex; flex-direction: column; justify-content: left; text-align: left" >
                  <div>Ref: {{ item.reference === undefined ? 'Undefined' :  item.reference }}</div>
                  <div>Ref constructeur: {{ item.reference_const === undefined ? 'Undefined' : item.reference_const }}</div>
                </div>
              </template>
            </vs-tooltip>

          </div>

          <!-- Product description -->
          <div class="product-detail">
            <div style="font-size: 0.9em; color: grey">{{ item.family_sub === undefined ?  'Undefined' : item.family_sub.name}}</div>

            <div>{{ item.description }}</div>
          </div>

          <!-- public price -->
          <div class="product-price" style="width: 15%">
            <span style="color: slategray; font-size: 0.9em">Prix public</span>
            <span >{{ item.public_price.toFixed(2)}} HT</span>
          </div>

          <!-- sale applied price -->
          <div class="product-price" style="width: 15%">
            <span style="color: slategray; font-size: 0.9em">Prix remisé</span>
            <span >{{  (item.public_price * ( 1 - (item.family_discount.discount / 100))).toFixed(2)}} HT</span>
          </div>

          <!-- Product buttons -->
          <div class="product-btns">
            <ProductDetailsButton :isCurrentVersion="isCurrentVersion" :currentVersionId="currentVersion ? currentVersion.id : selectedVersion" :product="item"  />


            <!-- <div >
              <vs-button class="product-compare-btns" v-if="isCurrentVersion"  @click="goToCompareProducts(item)"><i class="bx bx-transfer-alt button-icon"></i> Equivalent constructeur</vs-button>
            </div> -->
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import Api from '../../store/config'
import VueApexCharts from 'vue-apexcharts'
import ProductDetailsButton from "@/components/Products/Buttons/ProductDetailsButton";

export default {
  name: "ProductHistory",
  components: {
    VueApexCharts,
    ProductDetailsButton
  },
  props: {
    product_reference: String,
    page: Number,
  },
  data() {
    return {
      productHistory: '',
      updating_chart: 0,
      baseURL: process.env.VUE_APP_DEV ? process.env.VUE_APP_DEV : `https://${window.location.hostname}/api/`,
      selectTab: 1,
      options: {
        chart: {
          id: `Historique_${this.product_reference}`
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          labels: {
            datetimeFormatter: {
              year: 'yyyy',
              month: 'MMM,',
              day: 'ddd',
              hour: 'HH:mm'
            }
          },
          categories: []
        }
      },
      series: [{
        name: 'Prix €',
        data: []
      },
        {
          name:'Version',
          data: []
        }
      ]
    }
  },

  watch: {
  },
  created() {
      this.getProductHistory()
  },
  methods: {
    sendBack(){
      this.$router.push({name: 'Navigate', params: {id: this.product_reference, page: this.page}})
    },
    getProductHistory(){
      Api().get(`product/historic-price/${this.product_reference}`)
      .then((res) => {
        this.productHistory = res.data.results
        this.productHistory.forEach((el) => {
          this.options.xaxis.categories.push(el.date_created)
          this.series[0].data.push(parseInt(el.public_price))
        })

        this.updating_chart++
        this.$emit('loading', false)


      })
      .catch(()=> {
        this.$emit('loading', false)
      })
    }
  }
}
</script>

<style scoped>


h2 {
  color: #005eb8!important;
  z-index: 3;
  font-size: 1.2em;
}

.products-container {
  height: 73vmin;
  margin-top: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: auto;
}

.products-block {
  width: 100%;
  margin-top: 0.5%;
  padding: 1%;
  margin-left: 1%;
  margin-right: 1%;
  border-radius: 8px;
  background-color: white;
  box-shadow: rgba(17, 17, 26, 0.1) 0 1px 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}



.product-title {
  width: 30%;
}
.product-logo {
  width: 12%;
}
.product-reference{
  width:75%; font-size: 0.9em;  display: flex; flex-direction: column; text-align: left;
}
.product-price {
  width: 10%; font-size: 0.9em; display: flex;  flex-direction: column; text-align: right;
}

.product-detail {
  width: 35%; font-size: 0.9em;  display: flex; flex-direction: column;
}

.product-btns {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

}
.product-compare-btns{
  width: 7em;
  height:3em;
  text-align: center;
  justify-content: center;
  flex-direction: column;
}

.button-icon {
  display: none
}


</style>
