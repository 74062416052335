<template>
    <div>
        <div>
            <vs-alert>
                <template>
                    <span id="title">
                        Dossiers
                    </span>
                </template>
            </vs-alert>
        </div>
        <div class="buttons">
            <FingerprintSpinner
                style="margin: 0 auto; top: 20%; z-index: 10"
                :size="60"
                :color="'#0060be'"
                v-if="loading"
            />
            <div v-else v-for="(item,index) in folders" :key="index">
                <vs-button id="button-for" :active="active == item" @click="changeActive(item)" transparent >{{ item }}</vs-button>
            </div>
        </div>

        <footer>
            <div class="create">
                <vs-button v-if="$store.state.Users.currentUser.role_type === 'Administrateur'" @click="showModal" id="button-create" transparent active>Créer un dossier</vs-button>
            </div>
        </footer>
        

        <CreateBucketModal 
            v-show="isModalCreateBucketVisible" 
            :folders="folders"
            @create="createBucket" 
            @close="closeModal"
        />
    </div>
    
</template>

<script>
    import { FingerprintSpinner } from 'epic-spinners';
    import CreateBucketModal from './Modals/CreateBucketModal';

    export default {
        name: "Folders",
        components: {
            FingerprintSpinner,
            CreateBucketModal,
        },
        data() {
            return { 
                active: '',
                isModalCreateBucketVisible: false,
            }
        },
        props: {
            folders: Array,
            loading: Boolean,           
        },
        watch: {
            folders: function() {
                this.active = this.folders[0]
            }
        },
        methods: {
            changeActive(item) {
                this.active = item;
                this.$emit('activeFolder', item)
            },
            showModal() {
                this.isModalCreateBucketVisible = true;
            },
            closeModal() {
                this.isModalCreateBucketVisible = false;
                this.$emit('refresh')
            },
            createBucket(value) {
                this.$emit('create', value)
                this.closeModal()
            }
        }
    }
</script>

<style scoped>
    template.div {
        height: 200px;
    }

    div.vs-alert.vs-component--primary {
        border-top-left-radius: 12px;
        border-bottom-right-radius: 0px;
        height: 50px !important;
    }

    #title {
        font-weight: 600;
        font-size: 1rem;
        display: flex;
        align-items: center;
    }

    .buttons {
        width: auto; 
        display: flex; 
        flex-direction: column; 
        justify-content: left; 
        padding-bottom: 1%;
        overflow-y: scroll;
        height: 58vh;
    }

    #button-for {
        width: 95%;
        border-radius: 5px;
    }

    .create {
        display: flex;
        justify-content: center;
        align-items: center;
        float: bottom; 
        margin-top: 1%;
    }

    #button-create {
        width: 150px;
    }

    /* Media Queries Height */
    @media screen and (max-height: 667px) {
        .create {
            display: none
        }
    }
</style>