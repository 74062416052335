<template>
    <transition name="modal-fade">
        <div class="modal-backdrop">
            <div class="modal">
                <header class="modal-header">
                    <slot name="header">
                        <div>
                            <h4>
                                Validation de la Commande: 
                                <span id="title">
                                    {{ title.slice(0, 18) + (title.length > 18 ? "..." : "") }}
                                </span>
                            </h4>
                        </div>
                    </slot>
                    <i @click="close" id="close" class='bx bx-x-circle bx-sm'></i>
                </header>

                <section class="modal-body">
                    <slot name="body">
                        <div class="validate">
                            <DeliveryTracker id="tracker" :modal="'change'" :status="status"/>
                            <select id="select" @change="onChange($event)" v-model="status">
                                <option value="default" selected disabled hidden>Selectionnez le statut</option>
                                <option :disabled="isDisable('ordered')" value="ordered">En préparation</option>
                                <option :disabled="isDisable('shipped')" value="shipped">En transit</option>
                                <option :disabled="isDisable('finished')" value="finished">Terminée</option>
                            </select>
                            <div class="validate_buttons">
                                <vs-button v-if="isPostOk == 'default'" id='button' @click="validateChange()" style="width: fit-content; height: fit-content">
                                    Valider le changement de statut
                                </vs-button>
                                <vs-button v-else-if="isPostOk == 'ok'" id='button' success style="width: fit-content; height: fit-content">
                                    <i class='bx bx-check'></i>Succès
                                </vs-button>
                                <vs-button v-else-if="isPostOk == 'error'" id='button' danger style="width: fit-content; height: fit-content">
                                    <i class='bx bx-x'></i> Erreur
                                </vs-button>
                            </div>
                        </div>
                    </slot>
                </section>
            </div>
        </div>
    </transition>
</template>

<script>
    import DeliveryTracker from '../DeliveryTracker';
    import Api from "../../../store/config"

    export default {
        name: 'ModalChangeStatusCommand',
        props: {
            title: String,
            order_id: Number,
            status: String 
        },
        data() {
            return {
                isPostOk: 'default',
                statusSave: ''
            }
        },
        components: {
            DeliveryTracker,
        },
        created() {
            this.statusSave = this.status;
        },
        computed: {
            userRole() {
                return this.$store.state.Users.currentUser ? this.$store.state.Users.currentUser.role_type : null
            },
        },
        methods: {
            close() {
                this.$emit('close');
                this.statusSave = ''
            },
            validateChange() {
                let url = this.status == 'finished' ? '/command/update-finish/' :
                        this.status == 'shipped' ? '/command/update-ship/' :
                        '/command/update-order/'
                Api().put(`${url}${this.order_id}`, {
                    'state': true
                }).then(() => {
                    this.isPostOk = 'ok'
                    setTimeout(function() { this.close() }.bind(this), 2000)
                }).catch(() => {
                    this.isPostOk = 'error'
                })
            },
            onChange(event) {
                this.status = event.target.value;
            },
            isDisable(value) {
                if(value == "ordered") {
                    if(this.statusSave == 'finished' || this.statusSave == 'shipped') return true;
                    else return false;
                } else if(value == "shipped") {
                    if(this.statusSave == 'ordered') return false;
                    else return true;
                } else if(value == "finished") {
                    if(this.statusSave == 'shipped' && (this.userRole === 'Administrateur' || this.userRole === 'Client')) return false;
                    else return true;
                } else {
                    if(this.statusSave == 'no_status') return false;
                    else return true;
                }
            }
        },
    };
</script>

<style scoped>
    .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
        
    }

    @supports (-moz-appearance:none) {
      select
      {
        height: 30px;
        -moz-appearance:none !important;
        background: transparent url('data:image/gif;base64,R0lGODlhBgAGAKEDAFVVVX9/f9TU1CgmNyH5BAEKAAMALAAAAAAGAAYAAAIODA4hCDKWxlhNvmCnGwUAOw==') right center no-repeat !important;
        background-position: calc(100% - 5px) center !important;
      }
    }

    modal-header.slot {
        display: flex;
        flex-direction: row;
    }

    .modal {
        background: #FFFFFF;
        box-shadow: 2px 2px 20px 1px;
        overflow-x: auto;
        flex-direction: column;
        border-radius: 10px;
        width: 500px;
        height: 300px;
        transform: scale(1.2);
    }

    .modal-header,
    .modal-footer {
        padding: 15px;
        display: flex;
    }

    .modal-header {
        position: relative;
        border-bottom: 1px solid #eeeeee;
        color: #2A69AF;
        justify-content: space-between;
        font-weight: bold;
    }

    .modal-footer {
        border-top: 1px solid #eeeeee;
        flex-direction: column;
        justify-content: flex-end;
    }

    .modal-body,
    .modal-product {
        position: relative;
        padding: 20px 10px;
    }

    .modal-fade-enter,
    .modal-fade-leave-to {
        opacity: 0;
    }

    .modal-body {
        padding: 0px;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity 1s ease;
    }

    .close {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .modal-body-container {
        height: 150px;
    }

    .modal-product {
        padding: 0px 20px 0px 20px;
    }

    .main-information-product {
        display: flex; 
        flex-direction: row; 
        justify-content: left; 
        width: 70%; 
        align-items: center
    }

    .main-information-product-text {
        display: flex; 
        flex-direction: column; 
        margin-left: 4%
    }

    .modal_body_grid {
        display: grid;
        width: 100%;
    }

    #title {
        color: black
    }

    #tracker {
        grid-area: tracker;
        padding: 0;
        margin: 2% 0 0 35%;
        width: 60%;
        padding-bottom: 20px;
    }

    .validate {
        width: 100%;
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-areas: "tracker"
                            "select"
                            "buttons";
        margin-top: 2%;
        height: 140px;
    }

    .validate_buttons {
        grid-area: buttons;
        height: 50px;
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    #button {
        padding: 0;
        margin: 0;
    }

    #select {
        grid-area: select;
        width: 50%;
        margin-left: 25%;
        font-size: 18px;
    }
    
    .bx-check, .bx-x {
        padding-right: 10px;
    }

    .fade-enter-active {
        transition: opacity .5s;
    }

    .fade-enter {
        opacity: 0;
    }

    #close {
        cursor: pointer; 
        color: black; 
        position: absolute; 
        top: 10px; 
        right: 10px;
        transition: 0.3s;
    }

    #close:hover {
        color: red;
    }

    .bx-sm {
        font-size: 1.2rem !important;
    }
</style>