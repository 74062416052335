<template>
  <vs-table striped>

    <template #thead>
      <vs-tr>
        <vs-th>
          Constructeur
        </vs-th>
        <vs-th>
          Reference
        </vs-th>
        <vs-th style="width: fit-content">
          Reference constructeur
        </vs-th>
        <vs-th>
          Desc.
        </vs-th>
        <vs-th>
          Remise famille
        </vs-th>
        <vs-th>
          Prix publique
        </vs-th>
      </vs-tr>
    </template>
    <template #tbody>
      <vs-tr
          :key="i"
          v-for="(tr, i) in modified"
          :data="tr"
      >
        <vs-td>
          {{ tr.maker.name }}
        </vs-td>
        <vs-td>
          {{ tr.reference }}
        </vs-td>
        <vs-td style="width: fit-content">
          {{ tr.reference_const }}
        </vs-td>
        <vs-td>
          {{ tr.description }}
        </vs-td>
        <vs-td>
          {{ tr.family_discount.discount.toFixed(2) }}
        </vs-td>
        <vs-td>
          {{ tr.public_price.toFixed(2) }}
        </vs-td>

      </vs-tr>
    </template>
    <template #notFound>
      Aucun équipement modifié ou ajouté
    </template>
  </vs-table>
</template>

<script>
export default {
  name: "ModTable",
  props: {
    modified: Array
  }
}
</script>

<style scoped>

</style>
