<template>
    <transition name="modal-fade">
        <div class="modal-backdrop">
            <div class="modal">
                <section class="modal-body">
                    <i @click="close" class='bx bx-x-circle' style='color: black;'></i>
                    <p>Êtes-vous sûre de vouloir supprimer <span>{{ file.name }}</span> ?</p>
                    <div>
                        <vs-button danger @click="deleteFile()" transparent active>Supprimer</vs-button>
                    </div>
                </section>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "DeleteModal",
        props: {
            file: String
        },
        methods: {
            close() {
                this.$emit('close');
            },
            deleteFile() {
                this.$emit('delete');
                this.close();
            }
        }
    }
</script>

<style scoped>
    .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }

    .modal {
        background: #FFFFFF;
        box-shadow: 2px 2px 20px 1px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        width: 500px
    }

    .modal-body {
        position: relative;
    }

    .modal-fade-enter,
    .modal-fade-leave-to {
        opacity: 0;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity .5s ease;
    }

    .close {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    i {
        position: absolute;
        right: 15px;
        top: 10px;
        cursor: pointer;
    }

    p {
        text-align: center;
    }

    .modal-body > div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    span {
        font-weight: bold;
    }
</style>