import Vue from 'vue'
import Vuex from 'vuex'
import Users from './modules/users.store'
import Cart from './modules/cart.store'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState()],
    modules: {
        Users,
        Cart
    }
})
