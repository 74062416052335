<template>
    <transition name="modal-fade">
        <div class="modal-backdrop">
            <div class="modal">
                <header class="modal-header">
                    <slot name="header">
                        <div>
                            <h4>
                                Informations de la commande: <span id="title">{{ products.title }}</span>
                            </h4>
                        </div>
                    </slot>
                    <i @click="close" id="close" class='bx bx-x-circle bx-sm'></i>
                </header>

                <section class="modal-body">
                    <slot name="body">
                        <div class="body-informations">
                            <h4 id="create">Cration: {{ date('created') }}</h4>
                            <h4 id="update">Mis a jour: {{ date('updated')}}</h4>
                            <div id="tracker">
                                <div class="status" v-if="products.finished" id="status_command">
                                    <DeliveryTracker :modal="'info'" :status="getStatus()"/>
                                </div>
                            </div>
                            <h5 id="number_articles">Nombre de produits : {{ products.product.length ? products.product.length : 0 }}</h5>
                            <div class="price">
                                <!--<h5>Montant : MONTANT</h5>-->
                            </div>
                        </div>
                    </slot>
                </section>

                <hr>

                <section class="modal-product">
                    <slot name="product">
                        <div class="modal-container">
                            <div class="modal-header">
                                <div class="main-information-product">
                                    <img v-if="product.product.maker && product.product.maker.logo" style="border-radius: 8px" :src="baseURL + product.product.maker.logo"/>
                                    <img style="border-radius: 8px; width:75px; height:75px; fit-content" v-else src="../../../assets/defaultbox.png" />
                                    <div class="main-information-product-text">
                                        <span id="info_product_span">
                                            <span class="mipt-span">
                                                {{ product.product.family_sub.family.name ? product.product.family_sub.family.name : "" }}  /  
                                            </span>
                                            <span class="mipt-span">
                                                {{ product.product.family_sub.name ? product.product.family_sub.name : "" }}
                                            </span>
                                        </span>
                                        <h4>
                                            <span style="font-weight: 800">
                                                {{ product.product.reference ? product.product.reference : "" }}
                                            </span>
                                        </h4>
                                    </div>
                                </div>
                            </div>

                            <div class="modal-body-container">
                                <div class="line-item" style="width: auto" >
                                    <div class="item-description-modal">
                                        <span style="font-size: 0.8em; color: grey">Constructeur:</span>
                                        <span style="color: black;  font-size: 0.8em; font-weight: bold ">
                                            {{ product.product.maker && product.product.maker.name ? product.product.maker.name : "" }}
                                        </span><br>
                                        
                                        <span style="font-size: 0.8em; color: grey">Famille remise:</span>
                                        <span style="color: black; font-size: 0.8em;">
                                            {{ product.product.family_discount.family_discount }}
                                        </span><br>

                                        <span style="font-size: 0.8em; color: grey">Référence constructeur:</span>
                                        <span style="color: black; font-size: 0.8em; font-weight: bold ">
                                            {{ product.product.reference_const ? product.product.reference_const : ""}}
                                        </span><br>

                                        <span style="font-size: 0.8em; color: grey; max-width: 50px;">Description:</span>
                                        <span v-if="product.product.description" style="color: black; font-size: 0.8em">
                                            {{ product.product.description.slice(0, 30) + (product.product.description.length > 30 ? "..." : "") }}
                                        </span><br>
                                    </div>

                                    <div class="item-price-modal">
                                        <span style="font-size: 0.8em; color: grey">Quantité: </span>
                                        <span style="color: black; font-size: 0.8em">
                                            {{ product.quantity ? product.quantity : "0" }}
                                        </span><br>

                                        <span style="font-size: 0.8em; color: grey">Montant remise famille:</span>
                                        <span style="color: black; font-size: 0.8em;">
                                            {{ (100 * (1 - (product.product.discount_price / product.product.public_price))).toFixed(2) }}%
                                        </span><br>
                    
                                        <span style="font-size: 0.8em; color: grey">Prix public:</span>
                                        <span style="color: black; font-size: 0.8em"> 
                                            {{ getTotalPriceArticle().toFixed(2) }} HT
                                        </span><br>


                                        <span style="font-size: 0.8em; color: grey">Prix remisé: </span>
                                        <span style="color: black; font-size: 0.8em">
                                            {{ product.product.discount_price.toFixed(2) ?? product.product.public_price.toFixed(2) }} HT
                                        </span>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </slot>
                </section>

                <footer class="modal-footer">
                    <vs-pagination not-arrows v-model="page" :length="products.product.length" />
                </footer>
            </div>
        </div>
    </transition>
</template>

<script>
    import DeliveryTracker from '../DeliveryTracker'

    export default {
        name: 'ModalInfoCommand',
        components: {
            DeliveryTracker
        },
        data() {
            return {
                page: 1,
                baseURL: process.env.VUE_APP_DEV ? process.env.VUE_APP_DEV : `https://${window.location.hostname}/api/`,
                dateCreated: this.products.date_created,
                dateUpdated: this.products.date_updated
            }
        },
        props: {
            products: Object, 
        },
        computed: {
            product() {
                return ( this.products.product[this.page-1] ? this.products.product[this.page-1] : "" )            
            },
        },
        methods: {
            close() {
                this.$emit('close');
                this.page = 1
            },
            getTotalPriceArticle() {
                return (
                    (this.product.product.public_price ? this.product.product.public_price : 0) * 
                    (this.product.quantity ? this.product.quantity : 0)
                )
            },
            date(status) {
                let date = status == 'created' ? this.dateCreated : this.dateUpdated;
                date = new Date();
                let formatted_date = this.pad(date.getDate()) + "/" +
                                    this.pad(date.getMonth()) + "/" +
                                    this.pad(date.getFullYear()) + " " +
                                    this.pad(date.getHours()) + "h" +
                                    this.pad(date.getMinutes());
                    
                return formatted_date;
            },
            pad(value) {
                return value < 10 ? `0${value}` : value
            },
            getStatus() {
                if(this.products.finished) return 'finished';
                else if(this.products.shipped) return 'shipped';
                else if(this.products.ordered) return 'ordered';
                else if(this.products.no_status) return 'no_status';
            }
            
        },
    };
</script>

<style scoped>
    @media screen and (max-width: 1250px), (max-height: 780px) {
    .modal {
        background: #FFFFFF;
        box-shadow: 2px 2px 20px 1px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        transform: scale(0.7)
    }
    }

    .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }

    modal-header.slot {
        display: flex;
        flex-direction: row;
    }
    
    hr {
        border: 0.6px solid black;
        border-radius: 20px;
        width: 70%;
    }

    .modal {
        background: #FFFFFF;
        box-shadow: 2px 2px 20px 1px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        width: 45em
    }

    .modal-header,
    .modal-footer {
        padding: 15px;
        display: flex;
    }

    .modal-header {
        position: relative;
        border-bottom: 1px solid #eeeeee;
        color: #2A69AF;
        justify-content: space-between;
        font-weight: bold;
    }

    .modal-footer {
        border-top: 1px solid #eeeeee;
        flex-direction: column;
        justify-content: flex-end;
    }

    .modal-body,
    .modal-product {
        position: relative;
        padding: 20px 10px;
    }

    .modal-fade-enter,
    .modal-fade-leave-to {
        opacity: 0;
    }

    .modal-body {
        padding-left: 30px;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity .5s ease;
    }

    .close {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .modal-body-container {
        height: 150px;
    }

    .modal-product {
        padding: 0px 20px 0px 20px;
    }

    .main-information-product {
        display: flex; 
        flex-direction: row; 
        justify-content: left; 
        width: 70%; 
        align-items: center
    }

    .main-information-product-text {
        display: flex; 
        flex-direction: column; 
        margin-left: 4%
    }

    .modal_body_grid {
        display: grid;
        width: 100%;
    }

    #info_product_span {
        width: 100%;
    }

    .mipt-span {
        text-transform: capitalize; 
        font-size: 0.65em; 
        width: 100%;
        color: grey
    }

    #title {
        height: 20px;
        margin: 0;
        font-weight: bold;
        font-size: 20px;
    }

    #create {
        font-size: 0.7em; 
        color: grey;
        grid-area: created;
        height: 24px;
        margin: 0;
    }

    #update {
        font-size: 0.7em; 
        color: grey;
        grid-area: updated;
        margin: 0;
        height: 24px;
    }

    #status_command {
        grid-area: status;
        height: 24px;
        margin: 0;
        font-style: italic;
    }

    .date, .info_command {
        justify-content: left;
    }

    .body-informations {
        width: 100%;
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "created updated"
                            "tracker products"
                            "tracker price";
    }

    #close {
        cursor: pointer; 
        color: black; 
        position: absolute; 
        top: 10px; 
        right: 10px;
        transition: 0.3s;
    }

    #close:hover {
        color: red;
    }

    .price {
        grid-area: price;
    }

    h5 {
        padding: 0px;
        margin: 0px;
    }

    #title {
        color: black
    }

    #create, #update {
        font-size: 0.7em; 
        color: grey;
    }

    #number_articles {
        grid-area: products;
    }

    #tracker {
        grid-area: tracker;
        margin-left: 3%
    }

    .line-item {
        height: 150px;
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "productdescription productprice";
    }

    .item-description-modal {
        text-align: left;
        grid-area: productdescription;
    }

    .item-price-modal {
        text-align: left;
        grid-area: productprice;
    }

</style>
