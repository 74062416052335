<template>
    <transition name="modal-fade">
        <div class="modal-backdrop">
            <div class="modal">
                <header class="modal-header">
                    <slot name="header">
                        <h4>
                            Finalisation de la Commande
                        </h4>
                    </slot>
                    <i @click="close" id="close" class='bx bx-x-circle bx-sm'></i>
                </header>

                <section class="modal-body">
                    <slot name="body">
                        <vs-input v-model="title" type="search" style="width: 21rem" shadow placeholder="Titre de la commande" ref="searchProductInput">
                            <template #icon>
                                <i style="color: darkgrey" class='bx bx-text'></i>
                            </template>
                        </vs-input>
                    </slot>
                </section>

                <footer class="modal-footer">
                    <vs-button v-if="!loading" class ="sendButton" @click="postCommand()" v-bind:class="[isSuccess == 1 ? 'success' : isSuccess == 2 ? 'error' : 'default']">{{ buttonText }}</vs-button>
                    <half-circle-spinner
                        v-if="loading"
                        :animation-duration="1000"
                        :size="30"
                        color="#0060be"
                        class="halfCircle"
                    />
                </footer>
            </div>
        </div>
    </transition>
</template>

<script>
    import Api from '../../store/config'
    import { HalfCircleSpinner } from 'epic-spinners'

    export default {
        name: 'ModalSendCart',
        components: {
            HalfCircleSpinner
        },
        data() {
            return {
                title: '',
                isSuccess: 0,
                buttonText: 'Envoyer la commande',
                loading: false
            }
        },
        methods: {
            close() {
                this.$emit('close');
            },
            postCommand() {
                if(this.title) {
                    let url = `/command/make-order?user_id=${this.$store.state.Users.currentUser.id}&title=${this.title}`
                    this.loading = true;
                    Api().post(url)
                        .then(() => {
                            this.loading = false;
                            this.isSuccess = 1;
                            this.$vs.notification({
                                position: 'top-left',
                                title: `Succès - Commande` ,
                                text: `Commande passée avec succès` ,
                                color: 'success'
                            })
                            this.title = null;
                            this.isSuccess = 0;
                            this.close();
                        })
                        .catch(() => {
                            this.loading = false;
                            this.isSuccess = 2;
                            this.buttonText = 'Une erreur s\'est produite'
                            this.$vs.notification({
                                position: 'top-left',
                                title: `Erreur - Commande` ,
                                text: `Une erreur s'est produite` ,
                                color: 'warn'
                            })
                        })

                }
            }
        },
    };
</script>

<style scoped>
    .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }

    .modal {
        background: #FFFFFF;
        box-shadow: 2px 2px 20px 1px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        width: 400px
    }

    .modal-header,
    .modal-footer {
        padding: 15px;
        display: flex;
    }

    .modal-header {
        position: relative;
        border-bottom: 1px solid #eeeeee;
        color: #2A69AF;
        justify-content: space-between;
        font-weight: bold;
    }

    .modal-footer {
        border-top: 1px solid #eeeeee;
        flex-direction: column;
        justify-content: flex-end;
    }

    .modal-body {
        position: relative;
        padding: 20px 10px;
    }

    .modal-fade-enter,
    .modal-fade-leave-to {
        opacity: 0;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity .5s ease;
    }

    .close {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #bx-x-circle {
            font-size: 25px;
    }

    .sendButton {
        width: fit-content; 
        height: fit-content; 
        width: 100%; 
    }

    .success {
        background-color: #9EBF43;
        transition: background-color 1s;
    }

    .error {
        background-color: #D22D53;
        transition: background-color 1s;
    }

    .default {
        background-color: #245EB2;
    }

    .halfCircle {
        margin: 5px;
        margin-left: auto;
        margin-right: auto;
    }

    #close {
        cursor: pointer; 
        color: black; 
        position: absolute; 
        top: 10px; 
        right: 10px;
        transition: 0.3s;
        z-index: 50;
    }

    #close:hover {
        color: red;
    }
</style>