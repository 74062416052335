<template>
  <div style=" width: 80%" >

    <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center" >
      <div>
        <h2>
          <span style="font-size: 1.3em; padding-bottom: 2%">{{active}}</span>
          <span v-if="active === 'Import'" style="font-size: 0.7em; margin-left: 10px; color: rgba(0, 0, 0, 0.3); font-weight: lighter">Importer un csv</span>
          <span v-if="active === 'Export'" style="font-size: 0.7em; margin-left: 10px; color: rgba(0, 0, 0, 0.3); font-weight: lighter">Exporter un csv</span>
          <span v-if="active === 'Historique'" style="font-size: 0.7em; margin-left: 10px; color: rgba(0, 0, 0, 0.3); font-weight: lighter">Historique des actions</span>
        </h2>
      </div>
    </div>

    <!-- Exception if no products found or API issue catched -->

    <div style="background-color: white;
    padding: 1% 2%;
    border-radius: 12px;">
      <div class="filter-and-search-container">
        <div style="width: auto; display: flex; flex-direction: row; justify-content: space-between; padding-bottom: 1%">
          <vs-button
              v-if="this.$store.state.Users.currentUser.role_type != 'Client'"
              transparent
              :active="active === 'Import'"
              @click="active = 'Import'"
          >Import</vs-button>
          <!--<vs-button
              transparent
              :active="active === 'Export'"
              @click="active = 'Export'"
          >
            Export
          </vs-button>
          <vs-button
              transparent
              :active="active === 'Historique'"
              @click="active = 'Historique'"
          >
            Historique
          </vs-button>-->
        </div>
      </div>

      <!--<div class="products-container" v-if="active === 'Historique'" >
      </div>-->

      <!-- Components -->
      <Export v-if="active === 'Export'" />
      <Import v-if="active === 'Import'" />

    </div>
  </div>
</template>

<script>
  import Import from "./Pages/Import";
  import Export from "./Pages/Export";
  export default {
    name: "ImportExport",
    components: {
      Export,
      Import
    },
    data:() => ({
      active: 'Import',
      loading: true,
      importCsv: ''
    }),
    created() {
      if(this.$store.state.Users.currentUser.role_type == 'Client') this.active = 'Export'
    }
  }
</script>

<style scoped>
  h2 {
    color: #2c3e50!important;
    z-index: 3;
    font-size: 1.2em;
  }

  .pagination {
    position: absolute; transform: translateX(-50%); left: 50%; bottom: 3%;
  }

  .no-product-exception {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .products-container {
    height: 73vmin;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .products-block {
    margin-top: 0.5%;
    padding: 1%;
    margin-left: 1%;
    margin-right: 1%;
    border-radius: 8px;
    flex: 1 1 0;
    background-color: white;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

  .product-title {
    width: 15%;
  }

  .product-logo {
    width: 10%;
  }
  .product-price {
    width: 10%; font-size: 0.9em; text-align: right; display: flex; flex-direction: column;
  }

  .product-detail {
    width: 45%; font-size: 0.8em;  display: flex; flex-direction: column;
  }

  .product-btns {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    width: 20%;
  }
  .checkbox {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 5%;
  }
  .filter-and-search-container {
    display: flex;
    flex-direction: row;
    border-bottom: solid 1px #eee;
    justify-content: space-between;
  }
</style>
