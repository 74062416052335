<template>
  <div class="myCommands-view-container">
    <MyCommands/>
  </div>
</template>

<script>
import MyCommands from "@/components/MyCommands/MyCommands";
export default {
  name: "MyCommandsView",
  props: {
    commands: Object
  },
  components: {MyCommands}
}
</script>

<style scoped>
.myCommands-view-container {
  align-items: center;
  width: 100%;
  height: 94vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}
</style>