import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
import LoginView from "@/views/LoginView";
import DashboardView from "@/views/DashboardView";
import NotificationsView from "@/views/NotificationsView";
import RecapView from "@/views/RecapView";
import UserInfosView from "@/views/UserInfosView";
import CartView from "../views/CartView";
import NotFound from "../views/NotFound";
import SettingsView from "../views/SettingsView"
import ProductsView from "../views/ProductsView";
import VersionsView from "../views/VersionsView";
import ForgotPasswordView from "@/views/ForgotPasswordView";
import Products from "@/components/Products/Products";
import CompareProducts from "@/components/Products/CompareProducts";
import ImportExportView from "../views/ImportExportView";
import ProductHistory from "../components/Products/ProductHistory";
import ValidationView from "@/views/ValidationView";
import MyCommandsView from "../views/MyCommandsView";
import StorageView from "../views/StorageView";
import NewPasswordView from "@/views/NewPasswordView";


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Login',
        component: LoginView,
        meta: {
            requiresVisitor: true
        }
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPasswordView,
        meta: {
            requiresVisitor: true
        }
    },
    {
        path: '/new-password/:uuid',
        name: 'NewPassword',
        component: NewPasswordView,
        meta: {
            requiresVisitor: true
        }
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: DashboardView,
        redirect: 'dashboard/recap',
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: 'recap',
                name: 'Recap',
                component: RecapView
            },
            {
                path: 'versions',
                name: 'Versions',
                component: VersionsView
            },
            {
                path: 'products',
                name: 'Products',
                redirect: 'products/navigate',
                component: ProductsView,
                children: [
                    {
                        path: 'navigate/:page?/:id?',
                        name: 'Navigate',
                        alias: 'navigate',
                        component: Products,
                        props: true
                    },
                    {
                        path: 'compare',
                        name: 'CompareProducts',
                        component: CompareProducts,
                        props: true
                    },
                    {
                        path: 'history/:product_reference',
                        name: 'ProductHistory',
                        component: ProductHistory,
                        props: true
                    },
                ]
            },
            {
                path: 'notifications',
                name: 'Notifications',
                component: NotificationsView
            },
            {
                path: 'settings',
                name: 'Settings',
                component: SettingsView,
            },
            {
                path: 'import_export',
                name: 'ImportExport',
                component: ImportExportView,
            },
            {
                path: 'validation',
                name: 'Validation',
                component: ValidationView,
                // beforeEnter(to, from, next) {
                //     if ( store.state.Users.currentUser.role_type !== 'Client' ) {
                //         next();
                //     } else {
                //         next('/');
                //     }
                // },
            },
            {
                path: 'storage',
                name: 'Storage',
                component: StorageView,
            },
            {
                path: 'userInfos',
                name: 'UserInfos',
                component: UserInfosView
            },
            {
                path: 'cart',
                name: 'Cart',
                component: CartView,
            },
            {
                path:'commands',
                name: 'MyCommands',
                component: MyCommandsView,
                
            },
        ]
    },
    {
        path: '*',
        name: "404",
        component: NotFound,
        meta: {
            requiresVisitor: true
        }
    }

    ]





const router = new VueRouter({
    base: '',
    routes,
    mode: 'history',
    hash: false,
})


router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {

        if (!store.getters['Users/isLoggedIn']) {
            next({
                name: 'Login',
            })
        } else {
            next()
        }
    } else if (to.matched.some(record => record.meta.requiresVisitor)) {
        if (store.getters['Users/isLoggedIn']) {
            next({
                name: 'Dashboard',
            })
        } else {
            next()
        }
    }

})

router.afterEach(() => {
    const tooltip = document.querySelector('.vs-tooltip')
    if (tooltip !== null) document.body.removeChild(tooltip)
})


export default router
