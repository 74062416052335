<template>
    <transition name="modal-fade">
        <div class="modal-backdrop">
            <div class="modal">
                <section class="modal-body" v-cloak @drop.prevent="addFile" @dragover.prevent @dragover="dragover" @dragleave="dragleave" @drop="drop">
                    <i @click="close" id="close" class='bx bx-x-circle bx-sm'></i>
                    <div class="products-container">
                        <div style="height: 100%; width: 98%; display: flex; flex-direction: column; justify-content: space-evenly; align-items: center">
                            <div v-if="!loading" style="display: flex; flex-direction: column; align-items: center; width: 400px;">
                                <p id="glissez">Glissez et déposez votre ou vos fichier(s) ici</p>
                                <input type="file" id="uploadFile" ref="file" @change="handleFileUpload" style="display: none" />

                                <svg v-if="areTheyFiles()"
                                    @click="clickOnInput()"
                                    style="margin-bottom: 2%; cursor: pointer;" 
                                    xmlns="http://www.w3.org/2000/svg" 
                                    width="100" fill="#eee" 
                                    version="1.1" 
                                    id="Layer_1" 
                                    viewBox="0 0 96.03 122.88"  
                                    xml:space="preserve">
                                    <g>
                                        <path d="M63.31,0.72C62.83,0.29,62.15,0,61.48,0c-0.15,0-0.29,0-0.43,0.05l-54.95,0c-1.64,0-3.19,0.68-4.3,1.79 C0.68,2.95,0,4.45,0,6.14V48h5.41V6.14c0-0.19,0.05-0.34,0.19-0.43c0.1-0.1,0.24-0.19,0.43-0.19h52.63h0.05v23.83 c0,2.27,0.92,4.35,2.42,5.85c1.5,1.5,3.58,2.42,5.85,2.42h23.39v79.19c0,0.15-0.05,0.34-0.19,0.43c-0.1,0.1-0.24,0.19-0.44,0.19 c-24.57,0-59.81,0-83.71,0c-0.15,0-0.34-0.05-0.43-0.19c-0.1-0.1-0.19-0.29-0.19-0.43v-42.9H0v42.9c0,1.69,0.68,3.19,1.79,4.3 c1.11,1.11,2.61,1.79,4.3,1.79c31.03,0,52.88,0,83.76,0c1.69,0,3.19-0.68,4.3-1.79c1.11-1.11,1.79-2.61,1.79-4.3V35.52 c0.05-0.24,0.1-0.43,0.1-0.68c0-0.82-0.39-1.55-0.92-2.08L63.65,0.92c-0.1-0.1-0.14-0.14-0.24-0.19H63.31L63.31,0.72z M43.56,63.98 H0v-6.04h43.53L31.42,44.07c-1.09-1.23-0.98-3.12,0.25-4.21c1.23-1.09,3.09-0.98,4.18,0.25L52.3,58.96c1.02,1.16,0.98,2.88-0.04,4 L35.84,81.77c-1.09,1.23-2.95,1.37-4.18,0.25c-1.23-1.09-1.33-2.98-0.25-4.21l12.11-13.87L43.56,63.98L43.56,63.98z M64.18,29.34 V9.38l22.47,22.76H66.99c-0.77,0-1.45-0.34-1.98-0.82C64.52,30.83,64.18,30.11,64.18,29.34L64.18,29.34z"/>
                                    </g>
                                </svg>

                                <div v-else style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
                                    <svg   
                                        style="margin-bottom: 2%"  
                                        xmlns="http://www.w3.org/2000/svg" 
                                        width="100" 
                                        fill="#4caf50" 
                                        viewBox="0 0 24 24">
                                        <path d="M6 12h10v1h-10v-1zm7.816-3h-7.816v1h9.047c-.45-.283-.863-.618-1.231-1zm-7.816-2h6.5c-.134-.32-.237-.656-.319-1h-6.181v1zm13 3.975v2.568c0 4.107-6 2.457-6 2.457s1.518 6-2.638 6h-7.362v-20h9.5c.312-.749.763-1.424 1.316-2h-12.816v24h10.189c3.163 0 9.811-7.223 9.811-9.614v-3.886c-.623.26-1.297.421-2 .475zm4-6.475c0 2.485-2.015 4.5-4.5 4.5s-4.5-2.015-4.5-4.5 2.015-4.5 4.5-4.5 4.5 2.015 4.5 4.5zm-2.156-.882l-.696-.696-2.116 2.169-.992-.941-.696.697 1.688 1.637 2.812-2.866z"/>
                                    </svg>
                                    <div v-if="files.length > 0" class="files-container">
                                        <div v-for="file in files" :key="file" >
                                            <div class="files">
                                                <div>
                                                    <i @click="removeFile(file, false)" class='bx bx-x' ></i>
                                                </div>
                                                <div>
                                                    <p class="allfiles">
                                                        {{ file.name }} | 
                                                        <span id="spantext">
                                                            {{ (file.size / 1000000).toFixed(2) }} Mo
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <div v-if="files_exists.length > 0">
                                        <p id="alert-text">Les fichiers ci-dessous existent déjà. Voulez-vous les écraser ?</p>

                                        <div class="files-container">
                                            <div v-for="(file, index) in files_exists" :key="index" >
                                                <div class="files_exists">
                                                    <div>
                                                        <i @click="removeFile(file, true)" class='bx bx-x' ></i>
                                                    </div>
                                                    <div>
                                                        <vs-switch :value=false @click="changeSwitch(index)">
                                                            <template #off>
                                                                Non
                                                            </template>
                                                            <template #on>
                                                                Oui
                                                            </template>
                                                        </vs-switch>
                                                    </div>
                                                    <div>
                                                        <p class="allfiles">
                                                            {{ file[0].name }} | 
                                                            <span id="spantext">
                                                                {{ (file[0].size / 1000000).toFixed(2) }} Mo
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <vs-button :disabled="uploadDisabled" @click="upload" style="margin-top: 10%">
                                    Téléverser fichier -
                                    <i class='bx bx-send' style="width: 20px"></i>
                                </vs-button>
                            </div>

                            <FingerprintSpinner
                                v-else
                                style="position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 10"
                                :size="60"
                                :color="'#0060be'"
                            />
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </transition>
</template>

<script>
    import { FingerprintSpinner } from 'epic-spinners'
    import Api from "../../../../store/config";

    export default {
        name: "UploadFileModal",
        components: {
            FingerprintSpinner
        },
        data() {
            return {
                files: [],
                files_exists: [],
                loading: false,
                uploadDisabled: false,
            }
        },
        props: {
            bucket: String,
            all_files: Array
        },
        methods: {
            close() {
                this.$emit('close');
                this.files = [];
                this.files_exists = [];
            },
            clickOnInput() {
                document.getElementById("uploadFile").click()
            },
            handleFileUpload() {
                console.log(this.$refs.file)
                let file = this.$refs.file.files[0]
                let csvName = this.$refs.file.files[0].name

                if(this.all_files.includes(csvName)) this.files_exists.push([file, false]);
                else this.files.push(file) 
            },
            addFile(e) {
                let droppedFiles = e.dataTransfer.files;
                if(!droppedFiles) return;
                ([...droppedFiles]).forEach(f => {
                    if(this.all_files.includes(f.name)) this.files_exists.push([f, false]);
                    else this.files.push(f) 
                });
            },
            removeFile(file, alreadyExists) {
                if(!alreadyExists) {
                    this.files = this.files.filter(f => {
                        return f != file
                    })
                } else {
                    this.files_exists = this.files_exists.filter(f => {
                        return f != file
                    })
                }
                
            },
            upload() {
                let isOk = false;
                let erreur = '';

                try {
                    this.loading = true;
                    if(this.files.length > 0) {
                        this.files.forEach((f) => { 
                            let formData = new FormData();
                            formData.append('file', f);
                            Api().post(`/minio/files?bucket=${this.bucket}&filename=${f.name}`, formData)
                                .then(() => {
                                    this.loading = false;
                                    this.isOk = true;
                                    this.$emit('close')
                                    this.files = []
                                })
                                .catch((err) => {
                                    this.loading = false;
                                    this.erreur = err;
                                    this.isOk = false;
                                })
                        })
                    }
                    if(this.files_exists.length > 0) {
                        this.files_exists.forEach((f) => {
                            let formData = new FormData();
                            formData.append('file', f[0]);
                            if(f[1] === true) {
                                Api().post(`/minio/files?force=yes&bucket=${this.bucket}&filename=${f[0].name}`, formData)
                                    .then(() => {
                                        this.loading = false;
                                        this.isOk = true;
                                        this.$emit('close')
                                        this.files_exists = []
                                    })
                                    .catch((err) => {
                                        console.log("JE SUIS LAHZEIRHZEIURHZIEUHRIZEHRIZEHIRHZIERUHZIEUHR")
                                        this.loading = false;
                                        this.erreur = err;
                                        this.isOk = false;
                                    })
                            }
                        })
                    }
                } catch {
                    this.isOk = false;
                }

                if(isOk) {
                    this.$vs.notification({
                        position: 'top-left',
                        title: `Téléversement` ,
                        text: `Téléversement réussi` ,
                        color: 'primary'
                    })
                } else {
                    this.$vs.notification({
                        position: 'top-left',
                        title: `Téléversement` ,
                        text: `Raison de l'erreur: ${erreur}` ,
                        color: 'error'
                    })
                }
            },
            dragover(event) {
                event.preventDefault();
                event.currentTarget.classList.add('bg-red');
            },
            dragleave(event) {
                event.currentTarget.classList.remove('bg-red');
            },
            drop(event) {
                event.currentTarget.classList.remove('bg-red');
            },
            areTheyFiles() {
                if(this.files.length > 0 || this.files_exists.length > 0) {
                    this.uploadDisabled = false;
                    return false;
                }
                else {
                    this.uploadDisabled = true;
                    return true;
                }
            },
            changeSwitch(index) {
                this.files_exists[index][1] = this.files_exists[index][1] == true ? false : true
            },
        }
    }
</script>

<style scoped>
    .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }

    .modal {
        background: #FFFFFF;
        box-shadow: 2px 2px 20px 1px;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        width: 610px;
        min-height: 250px;
    }

    .modal-body {
        position: relative;
        padding-top: 5px;
    }

    .modal-fade-enter,
    .modal-fade-leave-to {
        opacity: 0;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity .5s ease;
    }

    .close {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .bx-x-circle {
        position: absolute;
        right: 15px;
        top: 10px;
        cursor: pointer;
    }

    .files, .files_exists {
        height: 30px;
        justify-content: center;
        align-items: center;
    }

    .allfiles {
        margin-left: 10%;
        width: 370px;
    }

    #spantext {
        color: grey;
        font-style: italic;
        font-size: 14px;
    }

    .bx-x {
        transition: 0.3s;
    }

    .bx-x:hover {
        color: red;
    }

    .separator, .separator2 {
        background: black;
        height: 2px;
        width: 185%;
        opacity: 0.3
    }

    .separator {
        margin-top: 20px;
    }

    .separator2{
        margin-top: 30px;
    }

    #glissez {
        font-style: italic;
        font-size: 15px;
        color: grey;
        margin-bottom: 8%;
    }

    .bg-red {
        background-color: rgba(149, 193, 31, 0.3)
    }

    .files-container {
        height: 15vh; 
        width: 103%;
        margin-top: 10px;
        overflow: auto;
        overflow-x: hidden
    }

    .files {
        display: grid;
        grid-template-columns: 1fr 2fr;
    }

    .files_exists{
        display: grid;
        grid-template-columns: 1fr 1fr 2fr;
    }

    #alert-text {
        width: 90%;
        text-align: center;
    }

    #close {
        cursor: pointer; 
        color: black; 
        position: absolute; 
        top: 10px; 
        right: 10px;
        transition: 0.3s;
    }

    #close:hover {
        color: red;
    }
</style>