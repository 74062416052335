<template>
  <div class="login-container" style="background-size: 100% 100%" :style="{ backgroundImage: 'url(' + require('@/assets/bg.jpg') + ')' }">

    <div class="item">

      <Login />

    </div>

  </div>
</template>

<script>
import Login from "@/components/Login/Login";
export default {
  name: "LoginView",
  components: {Login}
}
</script>

<style scoped>
@media screen and (max-width: 1366px) {

  .login-container {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
  }

  .item {
    height: 100%;
    z-index: 2;
    background-color: #FFFFFF;
    width: 40%;
  }
}

@media screen and (min-width: 1366px) {

  .login-container {
    width: 100%;
    background-size: contain;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
  }

  .item {
    height: 100%;
    z-index: 2;
    background-color: #FFFFFF;
    width: 30%;
  }
}
</style>
