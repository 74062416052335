<template>
    <div class="commands-block">
        <strong id="title">{{ commands.title }}</strong>
        <h4 id="create">Création: {{ date(this.commands.date_created) }} </h4>
        <h4 id="update">Mise à jour: {{ date(this.commands.date_updated) }}</h4>

        <div id="status_command">
            <DeliveryTracker :modal="'normal'" :status="getStatusCommand()"/>
        </div>

        <h5 v-if="commands.product.length <= 1" id="products_command">{{ commands.product.length }} produit</h5>
        <h5 v-else id="products_command">{{ commands.product.length }} produits</h5>
        <div class="buttons_product">
            <div class="buttons_product_flex"></div>
            <vs-button v-if="commands.shipped && !commands.finished && (role == 'Client' || role == 'Administrateur')" 
                    id="modify" 
                    color=#9C2267 
                    @click="showModal('validate')">
                        Valider commande
            </vs-button>
            <vs-button v-else-if="(role == 'Responsable d\'Affaire' || role == 'Administrateur') && !commands.finished && !commands.shipped"
                    id="modify" color=#9C2267 
                    @click="showModal('change')">
                        Modifier statut
            </vs-button>
            <vs-button id="informations" @click="showModal('info')">
                Informations<i class='bx bx-search-alt-2 button-info' ></i>
            </vs-button>
            <vs-button v-if="!commands.finished && !commands.shipped && !commands.ordered" id="reminderEmail" @click="sendReminderEmail()">
                Envoyer un rappel</vs-button>
        </div>

        <!-- MODAL -->
        <ModalInfoCommand
            v-show="isModalInfoVisible"
            @close="closeModal('info')"
            :products="commands"
        />
        <ModalChangeStatusCommand
            v-show="isModalChangeStatusVisible"
            v-if="isModalChangeStatusVisible"
            @close="closeModal('change')"
            :title="commands.title"
            :order_id="commands.id"
            :status="getStatusCommand()"
        />
        <ModalValidateCommand
            v-show="isModalValidateVisible"
            @close="closeModal('validate')"
            :title="commands.title"
            :order_id="commands.id"
        />
    </div>
</template>

<script>
    import Api from "../../store/config";
    import ModalInfoCommand from './Modal/ModalInfoCommand';
    import ModalChangeStatusCommand from './Modal/ModalChangeStatusCommand';
    import ModalValidateCommand from './Modal/ModalValidateCommand'
    import DeliveryTracker from './DeliveryTracker'

    export default {
        name: "TemplateCommands",
        components: {
            ModalInfoCommand,
            ModalChangeStatusCommand,
            ModalValidateCommand,
            DeliveryTracker,
        },
        data() {
            return {
                isModalInfoVisible: false,
                isModalChangeStatusVisible: false,
                isModalValidateVisible: false,
                dateCreated: this.commands.date_created,
                dateUpdated: this.commands.date_updated,
                role: this.$store.state.Users.currentUser.role_type
            }
        },
        props: {
            commands: Object
        },
        methods: {
            showModal(modal) {
                if(modal == 'info') this.isModalInfoVisible = true;
                else if(modal == 'change') this.isModalChangeStatusVisible = true;
                else if(modal == 'validate') this.isModalValidateVisible = true;
            },
            closeModal(modal) {
                if(modal == 'info') this.isModalInfoVisible = false;
                else if(modal == 'change') this.isModalChangeStatusVisible = false;
                else if(modal == 'validate') this.isModalValidateVisible = false;
                this.$emit('getCommands')
            },
            date(data) {
                const date = new Date(data);
                let formatted_date = this.pad(date.getDate()) + "/" +
                                    this.pad(date.getMonth() + 1) + "/" +
                                    this.pad(date.getFullYear()) + " " +
                                    this.pad(date.getHours()) + "h" +
                                    this.pad(date.getMinutes());

                return formatted_date;
            },
            pad(value) {
                return value < 10 ? `0${value}` : value
            },
            getStatusCommand() {
                if(this.commands.finished) return 'finished';
                else if(this.commands.shipped) return 'shipped';
                else if(this.commands.ordered) return 'ordered';
                else if(this.commands.no_status) return 'no_status';
            },
            sendReminderEmail() {
                Api().post(`/command/reminder/${this.commands.id}`)
            },
        },
    }
</script>

<style scoped>
    .commands-block {
        margin-top: 0.5%;
        padding: 1%;
        border-radius: 8px;
        background-color: white;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas:  "title status status"
                                "create articles buttons";
        width: 97%;
        height: 5em;
    }


    @media screen and (max-width: 800px), (max-height: 780px) {
     .commands-block {
        margin-top: 0.5%;
        padding: 1%;
        border-radius: 8px;
        background-color: white;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
        display: grid;
        grid-template-columns: 1fr 1fr 2fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas:  "title status status"
                                "create articles buttons";
        width: 97%;
        height: 5em;
    }
    }

    #title {
        grid-area: title;
        overflow: hidden;
        height: 2em;
        margin: 0;
        font-weight: bold;
        font-size: 20px;
    }

    #create {
        font-size: 0.7em; 
        color: grey;
        grid-area: create;
        height: 2em;
        margin: 0;
    }

    #update {
        font-size: 0.7em; 
        color: grey;
        grid-area: create;
        height: 2em;
    }

    #status_command {
        grid-area: status;
        height: 24px;
        font-style: italic;
        width: 15.5em;
        margin-bottom: 1.5em;
    }

    #products_command {
        grid-area: articles;
        height: 2em;
        margin: 0;
        width: 10em;
    }

    .buttons_product {
        grid-area: buttons;
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas: "first second third";
    }

    #informations {
        grid-area: first;
        height: 3em;
        width: 10em;
        bottom: 75%;
    }
    .button-info {
        display: none;
    }

    #reminderEmail {
        grid-area: second;
        height: 3em;
        width: 10em;
        bottom: 75%;
    }

    #modify {
        grid-area: third;
        height: 3em;
        width: 10em;
        bottom: 75%;        
        
    }

    #status {
        border: 1px solid;
        text-align: center;
        border-radius: 5px;
        margin: 0;
    }

    #title {
        color: black
    }

    #create, #update {
        font-size: 0.7em; 
        color: grey;
    }

    #create, #number_articles, #status {
        width: 16em;
    }

    .date, .info_command, .price {
        display: flex;
        justify-content: left;
    }

    h5 {
        padding: 0px;
        margin: 0px;
    }
</style>

