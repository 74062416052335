<template>
  <div style="width: 80%">
    <div class="commands-title" v-if="active">
      <h2>
        <span style="font-size: 1.3em">{{ active }}</span>
        <span v-if="active === 'Terminées'" class="SpanTitle"
          >Commandes terminées et livrées</span
        >
        <span v-if="active === 'En Livraison'" class="SpanTitle"
          >Commandes en cours d'acheminement</span
        >
        <span v-if="active === 'En Traitement'" class="SpanTitle"
          >Commandes en traitement</span
        >
        <span v-if="active === 'Toutes les Commandes'" class="SpanTitle"
          >Toutes les commandes</span
        >
        <span v-if="active === 'En Cours'" class="SpanTitle"
          >En cours de commandes</span
        >
      </h2>
    </div>
    <div v-else style="font-size: 1.3em"><h2>Commandes</h2></div>
    <div style="width: 100%; height: 83vmin; background-color: white; padding: 1% 1%">
      <FingerprintSpinner
        style="
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 10;
        "
        :size="60"
        :color="'#0060be'"
        v-if="loading"
      />
      <p
        v-if="loading"
        style="
          position: fixed;
          top: 52%;
          left: 50%;
          transform: translateX(-50%);
          z-index: 10;
        "
      >
        Chargement des commandes ...
      </p>

      <!-- Heading Title container-->
      <div class="product-container">
        <div v-if="!loading">
          <div class="filter-and-search-container">
            <div
              style="
              margin-top: 5px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                flex-direction: row;
              "
            >
              <div style="display: flex; width: 50%">
                <vs-switch v-model="toutesCommandes" style="margin: 0.5% 2% 0 0">
                  <template #on> Mes commandes </template>
                  <template #off> Toutes commandes </template>
                </vs-switch>
                <vs-button
                    transparent
                    :active="active === 'Toutes les Commandes'"
                    @click="active = 'Toutes les Commandes'"
                >Toutes les commandes</vs-button
                >
                <vs-button
                    transparent
                    :active="active === 'Terminées'"
                    @click="active = 'Terminées'"
                >Commandes terminées</vs-button
                >
              </div>
              <div style="display: flex; justify-content: right">
                <vs-button
                    transparent
                    :active="orderBy === 'date'"
                    @click="orderBy === 'date' ? orderBy = undefined : orderBy = 'date'; orderResult()"
                >Trier par date</vs-button>
                <vs-button
                    transparent
                    :active="orderBy === 'maj'"
                    @click="orderBy === 'maj' ? orderBy = undefined : orderBy = 'maj'; orderResult()"
                >Trier par mise à jour</vs-button>
              </div>

            </div>
            <div>
            <AllCommands
              v-if="active === 'Toutes les Commandes' && this.commandsEverybody.results && this.commandsUser.results"
              :active="getActive()"
              :commandsUser="commandsUser"
              :commandsEverybody="commandsEverybody"
              @getCommands="getCommands()"
            />
            <!-- <OnDelivery
              v-if="active === 'En Livraison'"
              :active="getActive()"
              :commandsUser="commandsUser"
              :commandsEverybody="commandsEverybody"
              @getCommands="getCommands()"
            />
            <OnProcessing
              v-if="active === 'En Traitement'"
              :active="getActive()"
              :commandsUser="commandsUser"
              :commandsEverybody="commandsEverybody"
              @getCommands="getCommands()"
            /> -->
            <Done 
              v-if="active === 'Terminées'" 
              :active="getActive()"
              :commandsUser="commandsUser"
              :commandsEverybody="commandsEverybody"
              @getCommands="getCommands()"
            />
            <!-- <NoStatus 
              v-if="active === 'En Cours'" 
              :active="getActive()"
              :commandsUser="commandsUser"
              :commandsEverybody="commandsEverybody"
              @getCommands="getCommands()"
            /> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FingerprintSpinner } from "epic-spinners";
import Api from "../../store/config";
import AllCommands from "./Pages/AllCommands";
import Done from "./Pages/Done";

export default {
  name: "MyCommands",
  components: {
    FingerprintSpinner,
    AllCommands,
    Done,
  },
  data() {
    return {
      loading: true,
      active: "Toutes les Commandes",
      commandsUser: { results: "" },
      commandsEverybody: { results: "" },
      currentUser: "",
      toutesCommandes: false,
      orderBy: undefined,
    };
  },
  created() {
    this.getCommandsUser();
    this.getCommandsEverybody();
  },
  methods: {
    orderResult() {
      if (this.orderBy === "date") {
        this.commandsUser.results = this.commandsUser.results.sort((a, b) => {
          return new Date(b.date_created) - new Date(a.date_created);
        });
        this.commandsEverybody.results = this.commandsEverybody.results.sort((a, b) => {
          return new Date(b.date_created) - new Date(a.date_created);
        });
      } else if (this.orderBy === "maj") {
        this.commandsUser.results = this.commandsUser.results.sort((a, b) => {
          return new Date(b.date_updated) - new Date(a.date_updated);
        });
        this.commandsEverybody.results = this.commandsEverybody.results.sort((a, b) => {
          return new Date(b.date_updated) - new Date(a.date_updated);
        });
      }

      if(!this.orderBy) {
        this.getCommands()
      }
    },
    getCommandsUser() {
      Api()
        .get(`/command/${this.$store.state.Users.currentUser.id}`)
        .then((res) => {
          this.commandsUser = res.data;
          this.loading = false;
          this.$emit("loading");
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getCommandsEverybody() {
      Api()
        .get(`/command/`)
        .then((res) => {
          this.commandsEverybody = res.data;
          this.loading = false;
          this.$emit("loading");
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getCommands() {
      this.getCommandsUser();
      this.getCommandsEverybody();
    },
    changeToutesCommandes() {
      this.toutesCommandes ? this.toutesCommandes = false : this.toutesCommandes = true
    },
    getActive() {
      if (this.toutesCommandes) {
        return "my_commands";
      } else return "all_commands";
    },
  },
};
</script>

<style scoped>
h2 {
  color: #2c3e50 !important;
  z-index: 3;
  font-size: 1.2em;
}

.commands-title {
  display: flex;
}

.no-commands-exception {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.commands-container {
  width: auto;
  height: 60vmin;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.SpanTitle {
  font-size: 0.7em;
  margin-left: 10px;
  color: rgba(0, 0, 0, 0.3);
  font-weight: lighter;
}

.commands-count-parent {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.commands-count {
  font-size: 0.8em;
  color: grey;
  font-weight: lighter;
}
</style>

