<template>
  <div class="product-view-container">
    <FingerprintSpinner
        style="position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 10"
        :size="60"
        :color="'#0060be'"
        v-if="loading"
    />

    <transition  name="fade" mode="out-in">
      <router-view   @loading="loadingComponent"  :version="version"></router-view>
    </transition>
  </div>
</template>

<script>
import {FingerprintSpinner} from 'epic-spinners'

export default {
  name: "ProductsView",
  components: {
    FingerprintSpinner
  },
  data:() => ({
    version: undefined,
    selectedVersion: undefined,
    loading: true,
    reference: {}
  }),
  watch: {
    $route(to, from) {
      if(to.name === 'Navigate' && from.name === 'CompareProducts'){
        this.loading = true
      }
    }
  },
  methods: {

    loadingComponent(value){
      this.loading = value
    }
  }
}
</script>

<style scoped>

  .product-view-container {
    align-items: center;
    width: 100%;
    height: 94vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
  }

</style>
