<template>
    <div>
        <GChart 
            type="PieChart" 
            :options="options" 
            :data="data"
            width="100%"
            height="100%"
        />    
    </div>
</template>

<script>
    import { GChart } from "vue-google-charts";
    import Api from "../../store/config";

    export default {
        name: 'DoneCurrentOrders',
        components: {
            GChart
        },
        data() {
            return {
                api: {
                    results: []
                },
                data: [
                    ['Status Commandes', 'Pourcentage des Commandes'],
                ],
                options: {
                    width: 250,
                    height: 250,
                    padding: 0,
                    legend: 'bottom',
                    colors: [
                        '#4DAFE6', 
                        '#5E2B7E', 
                        '#9EBF43',
                        '#DC6E39',
                        '#D22D53',
                        '#D32F7C',
                        '#916843',
                        '#F8CD46'
                    ],
                    pieHole: 0.4,
                }
            };
        },
        methods: {
            DataInChart() {
                    Object.entries(this.api).forEach(([key, value]) => {
                        switch(key) {
                            case 'finished':
                                this.data.push(['Finies', value.length])
                                break;
                            case 'no_state': 
                                this.data.push(['En attente de traitement', value.length])
                                break;
                            case 'ordered_by_axians':
                                this.data.push(['Commandées par Axians', value.length])
                                break;
                            case 'shipped':
                                this.data.push(['En cours de livraison', value.length])
                                break;
                            default: break;
                        }
                    });
            }
        },
        created() {
            Api().get(`/command/statistic-command`)
                .then((res) => {
                    this.api = res.data
                    this.DataInChart()
                })
                .catch(() => {
                })
        },
    }
</script>