<template>
  <div style=" width: 80%" >

    <!-- Heading Title + filtering  container-->
    <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center" >
      <div>
        <h2>
          <span style="font-size: 1.3em; padding-bottom: 2%">Versions</span>
          <span style="font-size: 0.7em; margin-left: 10px; color: rgba(0, 0, 0, 0.3); font-weight: lighter">  parcourir les versions</span> </h2>
      </div>
    </div>

    <!-- Exception if no products found or API issue catched -->
    <p class="no-product-exception" v-if="versions.results.length === 0">Chargement des versions</p>

    <div style="background-color: white;
    padding: 1% 2%;
    border-radius: 12px;">

<!--   VERSION SEARCH INPUT COMMENTED
      <div class="filter-and-search-container">

        &lt;!&ndash; Search product input &ndash;&gt;
        <vs-input type="search" style="width: 30rem"  shadow  icon-after placeholder="Rechercher version">
          <template #icon>
            <i class='bx bx-search-alt'></i>
          </template>
        </vs-input>

      </div>
-->
      <div class="products-container">
        <div class="products-block"  v-for="(item, index) in versions.results" :key="index">
          <div class="product-title">
          <!-- Version name -->
            <div style="font-size: 0.7em; color: grey">Version</div>
            <strong>{{ item.name }}</strong>
          </div>

          <!-- Version description -->
          <div class="product-detail">
            <div style="font-size: 0.9em; color: grey">Date de modification</div>
            <div>{{ formatDate(item.date_updated) }}</div>
          </div>

      <!-- Version description -->
          <div class="product-detail">
            <div style="font-size: 0.9em; color: grey">Description</div>
            <div>{{ item.comments }}</div>
          </div>


          <!-- Version validation state -->
          <div class="product-price">
            <span style="color: slategray; font-size: 0.7em">Status</span>
            <span v-if="item.validated" >
              <vs-tooltip top>
                <i style="color: green" class="bx bxs-check-circle"></i>
                <template #tooltip>
                  <span  style="font-family: 'Noto Sans JP', sans-serif">Validée</span>
                </template>
              </vs-tooltip>
            </span>
            <span v-if="!item.validated" >
                <vs-tooltip top>
                  <i style="color: darkgrey" class="bx bxs-time"></i>
                <template #tooltip>
                 <span  style="font-family: 'Noto Sans JP', sans-serif">En attente de validation</span>
                </template>
              </vs-tooltip>
            </span>
          </div>

          <!-- Versions products amount -->
          <div class="product-price">
            <span style="color: slategray; font-size: 0.7em">Products</span>
            <span >{{ item.products }}</span>
          </div>

          <div style="margin-left: 4%;" >
              <vs-button @click="getVersionCatalog(item)" icon circle>
                <vs-tooltip top>
                  <i style="color: white" class="bx bx-download"></i>
                <template #tooltip>
                 <span  style="font-family: 'Noto Sans JP', sans-serif">Télécharger la version</span>
                </template>
              </vs-tooltip>
              </vs-button>
          </div>

          <!-- Product buttons -->
          <div class="product-btns">
              <vs-button icon circle @click="goToCatalogue(item)">
                <vs-tooltip top>
                  <i style="color: white" class="bx bx-file-find"></i>
                  <template #tooltip>
                    <span  style="font-family: 'Noto Sans JP', sans-serif">Parcourir la version</span>
                  </template>
                </vs-tooltip>
              </vs-button>
          </div>
        </div>
      </div>
    </div>

    <!-- Pagination -->
    <vs-pagination v-if="versions.results.length > 0"  class="pagination" v-model="page" :length="versions.pages" style="z-index: 99"/>

  </div>
</template>

<script>
  import Api from '../../store/config'
  import moment from 'moment'
  export default {
    name: "Versions",
    components: {

    },
    data:() => ({
      versions: {
        results: ''
      },
      makers: [],
      option: [],
      family: [],
      familySub: [],
      filteringFamily: [],
      filteringFamilySub: [],
      filteringMaker: [],
      filteringType: '',
      loading: true
    }),
    watch: {
      option(){
        this.$emit('compare', this.option)
      }
    },
    created() {
      this.getVersion(0, 20)
    },
    computed: {
      count: function () {
        return this.versions.count
      },
      page: {
        get: function() {
          return this.versions.current_page
        },
        set: function (event) {
          let factor = 20
          this.getVersion( ((event * factor) - factor), factor)
          return event
        }
      }
    },
    methods: {
      formatDate(date){
        return moment(date).add(2, 'hours').format('DD/MM/YYYY, HH:mm:ss ');
      },
      goToCatalogue(item){
        console.log(item)
        this.$router.push({ name: 'Navigate', params: { version: item } })
      },
      unselectProducts(){
        this.option = []
      },
      getVersionCatalog(version){
        Api().get(`version/export/${version.id}`)
            .then((res)=> {
              let blob = new Blob([res.data], { type: 'text/csv;charset=utf-8;' })
              let link = document.createElement('a')
              link.href = window.URL.createObjectURL(blob)
              link.download = `catalogue_${version.slug}.csv`
              link.click()
            })
      },
      getVersion(offset, limit){
        Api().get(`/version/?offset=${offset}&limit=${limit}`)
            .then((res)=> {
              this.versions = res.data
              this.$emit('loading', false)
            })
            .catch(() => {
              this.$emit('loading', false)
            })
      },
    }
  }
</script>

<style scoped>
  h2 {
    color: #2c3e50!important;
    z-index: 3;
    font-size: 1.2em;
  }

  .pagination {
  position: absolute; transform: translate(-50%, -40%); left: 50%; bottom: 3.5%;
}

@media screen and (max-width: 1250px), (max-height: 780px) {
  .pagination {
  position: absolute; transform: translate(-50%, 20%); left: 50%; bottom: 3.5%;
}

}

  .no-product-exception {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .products-container {
    height: 73vmin;
    margin-top: 1%;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .products-block {
    margin-top: 0.5%;
    padding: 1%;
    margin-left: 1%;
    margin-right: 1%;
    border-radius: 8px;
    background-color: white;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

  .product-title {
    width: 30%;
  }

  .product-logo {
    width: 10%;
  }
  .product-price {
    width: 10%; font-size: 0.9em; text-align: center; display: flex; flex-direction: column;
  }

  .product-detail {
    width: 45%; font-size: 0.8em;  display: flex; flex-direction: column;
  }

  .product-btns {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    width: 10%;
  }
  .checkbox {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 5%;
  }
  .filter-and-search-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
</style>
