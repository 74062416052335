<template>
  <div class="recap-view-container">
    <Recap />
  </div>
</template>

<script>

import Recap from "../components/Recap/Recap";
export default {
  name: "RecapView",
  components: {Recap}
}
</script>

<style scoped>

  h2 {
    color: #2c3e50!important;
    z-index: 3;
    font-size: 1.4em;
  }

  .recap-view-container {
    align-items: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
  }


  .item {
    padding: 1%;
    border-radius: 8px;
    margin-left: 1%;
    flex: 1 1 0;
    background-color: white;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
  }

</style>
