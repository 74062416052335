<template>
  <modal :name="'UserDetailsModal_'+user.id" width="50%" height="35%" classes="modal-override">
    <div class="modal-container">
      <div class="modal-header">
        <i @click="close" id="close" class='bx bx-x-circle bx-sm' style="width: 20px"></i>
        <div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; align-items: center">
          <p>Informations utilisateur</p>
          <div style="display:flex; flex-direction: row">
            <vs-button @click="updateUser" active transparent :disabled="!canSave">Sauvegarder</vs-button>
          </div>

        </div>
      </div>
      <div style="flex: 1 1 0; padding: 1% 3%; display: flex; flex-direction: column; justify-content: space-evenly; ">
        <div style="display: flex; flex-direction: row; justify-content: space-around">
          <vs-input  style="width: fit-content" label="Nom" v-model="userInfos.last_name" placeholder="Nom" />
          <vs-input style="width: fit-content; margin-top: 1%" label="Prénom"  v-model="userInfos.first_name" placeholder="Prénom" />
        </div>

        <div style="display: flex; flex-direction: row; justify-content: space-around">
          <vs-input style="width: fit-content; margin-top: 1%" label="Nom d'utilisateur" v-model="userInfos.username" placeholder="Nom d'utilisateur" />
          <vs-input style="width: fit-content; margin-top: 1%" label="Email"  v-model="userInfos.email" placeholder="Email" />
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Api from '../../../store/config'
export default {
  name: "UserModal",
  props: {
    user: Array
  },
  mounted() {
    if(this.user){
      this.userInfos = {
        role_type: this.user.role_type,
        last_name: this.user.last_name,
        first_name: this.user.first_name,
        username: this.user.username,
        email: this.user.email,
        password: "sp@ce",
        entity_id: 1,
        isAdmin: this.user.isAdmin
      }
    }
  },
  computed: {
    userRole() {
      return this.$store.state.Users.currentUser ? this.$store.state.Users.currentUser.role_type : []
    },
    canSave(){
      return  (this.userInfos.last_name !== this.user.last_name ) ||
              (this.userInfos.first_name !== this.user.first_name ) ||
              (this.userInfos.email !== this.user.email ) ||
              (this.userInfos.username !== this.user.username )
    }
  },
  data() {
    return {
      userInfos: {
        role_type: ''
      }
    }
  },
  methods: {
    updateUser(){
      let body = this.userInfos
      Api().put(`user/${this.user.id}`, body )
        .then(()=> {
          this.$emit('userUpdated')
          this.$modal.hide(`UserDetailsModal_${this.user.id}`)
          this.$vs.notification({
            title: 'Modification Utilisateur',
            text: `L'utilisateur ${this.user.username} a été modifié`,
          })
        })
    },
    close() {
      this.$emit('close');
    },
  }
}
</script>

<style scoped>

.modal-container {
  padding: 0%; background-color: #fafbfc;
  height: 100%; width: 100%;
  display: flex; flex-direction: column;
}

.modal-header {
  padding: 3% 3% 1% 3%;
  height: 10%;
  align-items: center;
  background-color: #FFFFFF;
  border-bottom: 2px solid #eee;
  display: flex; flex-direction: row; justify-content: space-between;
}

#close {
  cursor: pointer; 
  color: black; 
  position: absolute; 
  top: 5px; 
  right: 10px;
  transition: 0.3s;
}

#close:hover {
  color: red;
}

</style>
