<template>
  <div class="userManagement-view-container">
    <Settings />
  </div>
</template>


<script>
import Settings from "../components/Settings/Settings";
export default {
  name: "SettingsView",
  components: { Settings }
}
</script>

<style scoped>


.userManagement-view-container {
  align-items: center;
  width: 100%;
  height: 94vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}
</style>
