import { render, staticRenderFns } from "./Sidebar.vue?vue&type=template&id=9c4fe30a&scoped=true"
import script from "./Sidebar.vue?vue&type=script&lang=js"
export * from "./Sidebar.vue?vue&type=script&lang=js"
import style0 from "./Sidebar.vue?vue&type=style&index=0&id=9c4fe30a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c4fe30a",
  null
  
)

export default component.exports