<template>
  <div class="products-container">
    <vs-alert  style="height: 23% !important; z-index: auto;">
      <template>
        <p id="title">Template Excel</p>
      </template>
      <div style="display:flex; flex-direction: row; justify-content: space-between ">
        <p >Avant d'importer, vérifiez que le fichier excel réponde au standard du template
        </p>
        <vs-button style="width: fit-content; margin-bottom: 10px;" @click="getTemplate" active transparent>Télécharger template</vs-button>
      </div>
    </vs-alert>

    <div v-if="loading" style="z-index: 10" class="sceneWrapper">
      <!-- <progress-ring :min="0" :max="100" :value="progress" :text="progress"></progress-ring> -->
      <div class="scene">
        <div class="rocket">
          <img id="rocketImage" src="../../../assets/rocket.svg" />
        </div>
        <h3 id="progress">{{ progress }}%</h3>
      </div>
    </div>

    <div v-else style=" height: 100%; display: flex; flex-direction: column; justify-content: space-evenly; align-items: center">
      <div style="display: flex; flex-direction: column; align-items: center">
      <svg v-if="csvName.slice(-3) !== 'csv'" 
          style="margin-bottom: 2%" 
          xmlns="http://www.w3.org/2000/svg" 
          width="100" fill="#eee" 
          version="1.1" 
          id="Layer_1" 
          viewBox="0 0 96.03 122.88"  
          xml:space="preserve">
          <g>
            <path d="M63.31,0.72C62.83,0.29,62.15,0,61.48,0c-0.15,0-0.29,0-0.43,0.05l-54.95,0c-1.64,0-3.19,0.68-4.3,1.79 C0.68,2.95,0,4.45,0,6.14V48h5.41V6.14c0-0.19,0.05-0.34,0.19-0.43c0.1-0.1,0.24-0.19,0.43-0.19h52.63h0.05v23.83 c0,2.27,0.92,4.35,2.42,5.85c1.5,1.5,3.58,2.42,5.85,2.42h23.39v79.19c0,0.15-0.05,0.34-0.19,0.43c-0.1,0.1-0.24,0.19-0.44,0.19 c-24.57,0-59.81,0-83.71,0c-0.15,0-0.34-0.05-0.43-0.19c-0.1-0.1-0.19-0.29-0.19-0.43v-42.9H0v42.9c0,1.69,0.68,3.19,1.79,4.3 c1.11,1.11,2.61,1.79,4.3,1.79c31.03,0,52.88,0,83.76,0c1.69,0,3.19-0.68,4.3-1.79c1.11-1.11,1.79-2.61,1.79-4.3V35.52 c0.05-0.24,0.1-0.43,0.1-0.68c0-0.82-0.39-1.55-0.92-2.08L63.65,0.92c-0.1-0.1-0.14-0.14-0.24-0.19H63.31L63.31,0.72z M43.56,63.98 H0v-6.04h43.53L31.42,44.07c-1.09-1.23-0.98-3.12,0.25-4.21c1.23-1.09,3.09-0.98,4.18,0.25L52.3,58.96c1.02,1.16,0.98,2.88-0.04,4 L35.84,81.77c-1.09,1.23-2.95,1.37-4.18,0.25c-1.23-1.09-1.33-2.98-0.25-4.21l12.11-13.87L43.56,63.98L43.56,63.98z M64.18,29.34 V9.38l22.47,22.76H66.99c-0.77,0-1.45-0.34-1.98-0.82C64.52,30.83,64.18,30.11,64.18,29.34L64.18,29.34z"/>
          </g>
      </svg>

      <svg v-if="csvName.length > 0 && csvName.slice(-3) === 'csv'"  
          style="margin-bottom: 2%"  
          xmlns="http://www.w3.org/2000/svg" 
          width="100" 
          fill="#4caf50" 
          viewBox="0 0 24 24">
        <path d="M6 12h10v1h-10v-1zm7.816-3h-7.816v1h9.047c-.45-.283-.863-.618-1.231-1zm-7.816-2h6.5c-.134-.32-.237-.656-.319-1h-6.181v1zm13 3.975v2.568c0 4.107-6 2.457-6 2.457s1.518 6-2.638 6h-7.362v-20h9.5c.312-.749.763-1.424 1.316-2h-12.816v24h10.189c3.163 0 9.811-7.223 9.811-9.614v-3.886c-.623.26-1.297.421-2 .475zm4-6.475c0 2.485-2.015 4.5-4.5 4.5s-4.5-2.015-4.5-4.5 2.015-4.5 4.5-4.5 4.5 2.015 4.5 4.5zm-2.156-.882l-.696-.696-2.116 2.169-.992-.941-.696.697 1.688 1.637 2.812-2.866z"/>
      </svg>

        <!-- <FingerprintSpinner
            style="position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 10"
            :size="60"
            :color="'#0060be'"
        /> -->

        <input v-on:change="handleFileUpload()" type="file" ref="file" style="width: fit-content; margin-left: 70px;" >
        <p style="font-size: 0.8em; font-style: italic; color: grey"> {{ messageImport }}</p>
      </div>

<!--
      <div style="display: flex; flex-direction: column;align-items: flex-start;  width: 70%; ">
        <div style="width: 60%">
          <vs-input
              style="margin-top: 2%;"
              label="Nom de la version (optionnel)"
              v-model="version_name"
              placeholder="Nom de la version"
          />
        </div>
        <div style="width: 100%;  margin-top: 10%">
          <vs-input
              label="Description (optionnel)"
              v-model="version_desc"
              placeholder="Description"
          />
        </div>
      </div>-->

      <!-- <div v-if="this.progress === 100">
        <div class="firework"></div>
        <div class="firework"></div>
        <div class="firework"></div>
      </div> -->

      <vs-button :disabled="csvName.slice(-3) !== 'csv'" @click="submitFile"   style="margin-top: 1%">
        Importer fichier-
        <i class='bx bx-import' style="width: 20px"></i>
      </vs-button>
    </div>
  </div>
</template>

<script>
// import {FingerprintSpinner} from 'epic-spinners'
import Api from "../../../store/config";
// import ProgressRing from "./ProgressRing"

let interval;

export default {
  name: "Import",
  components: {
    // ProgressRing,
  },
  data(){
    return {
      version_name: '',
      loading: false,
      progress: 0,
      error: false,
      version_desc: '',
      fill : { gradient: ["red", "green", "blue"] },
      file: '',
      csvName: '',
      messageImport: "Avant d'importer votre ficher, assurez vous que le header (intitulés des colonnes) soit bien absent du fichier"
    }
  },
  watch: {
    progress: function(val) {
      if(val == 100) {
        clearInterval(interval);
        const stars = document.querySelectorAll('.stars')
        stars.forEach((star) => {
          star.remove()
        })

        const rocketImage = document.getElementById('rocketImage')
        const rocket = document.querySelector('.rocket')
        const progress = document.getElementById('progress')
        rocketImage.classList.add('slideout-rocketImage')
        rocket.classList.add('slideout-rocket')
        progress.classList.add('slideout-fadeout')
        if(!this.error) {
          this.messageImport = `${this.csvName} a été importé avec succès`
          this.$vs.notification({
            position: 'top-left',
            title: `Import` ,
            text: `${this.csvName} a été importé avec succès` ,
            color: 'success'
          })
          setTimeout(() => {
            this.progress = 0
            this.loading = false;
            rocketImage.classList.remove('slideout')
            rocket.classList.remove('slideout-rocket')
            progress.classList.remove('slideout-fadeout')
          }, 2000)
        } else if (this.error) {
          this.error = true;
          setTimeout((err)=> {
            this.loading = false
            this.file = ''
            this.csvName= ''
            this.$vs.notification({
              position: 'top-left',
              title: `Erreur import` ,
              text: `Raison de l'erreur: ${err}` ,
              color: 'warn'
            })
          }, 5000)
        }
      }
    }
  },
  methods: {
    getTemplate() {
      Api().get('import/get-template-import')
          .then((res) => {
            let blob = new Blob([res.data], {type: "application/csv"})
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'template-import.csv'
            link.click()
          })
    },
    submitFile() {
      const changeCompletion = () => {
          setTimeout(() => {
            Api()
              .get(`import/${this.$store.state.Users.currentUser.id}`)
              .then((res) => {
                this.progress = res.data.completion === 100 && this.progress === 0 ? 0 : res.data.completion;
                if (this.progress !== 100) changeCompletion()
              })
          }, 1000)
      }

      this.loading = true;
      let formData = new FormData();

      formData.append('file', this.file);

      changeCompletion();

      setTimeout(() => { this.stars() }, 500)
      // Make the request to the POST /single-file URL
      Api().post(`import/${this.$store.state.Users.currentUser.id}`,
          formData, {
            headers: {'Content-Type': 'text/csv'},
          })
          .then(() => {
              this.done = true;
              this.progress = 100
          })
          .catch((err) => {
              this.error = true;
              this.messageImport = `${err}`
          })
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0]
      this.csvName = this.$refs.file.files[0].name
    },
    stars() {
      const count = 30;
      const scene = document.querySelector('.scene');
      let i = 0;

      while (i < count) {
        let star = document.createElement('i');
        scene.appendChild(star)

        let x = Math.floor(Math.random() * window.innerWidth);
        let duration = Math.random() * 1;
        let h = Math.random() * 100

        star.classList.add('stars')
        star.style.left = x + 'px';
        star.style.height = 50 + h + 'px';
        star.style.animationDuration = duration + 's'

        star.style.position = 'absolute';
        star.style.background = i % 3 ? 'rgba(192, 192, 192, 0.7)' : 'rgba(0, 0, 0, 0.5)';
        star.style.width = '1px';
        
        i++
      }
    }
  }
}
</script>

<style scoped>
.products-container {
  height: 73vmin;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

#title {
  margin: 0;
  font-weight: 600;
}

.scene {
  margin-top: 20px;
  position: relative;
  width: auto;
  height: 500px;
  overflow: hidden;
  display: flex;
  background-size: cover no-repeat;
  background: radial-gradient(#E7E7E7 40%, white 70%);
  justify-content: center;
  align-items: center;
}

.scene .rocket {
  position: relative;
  animation: fadein 1s, animate 0.2s ease infinite;
}

@keyframes animate {
  0%, 100% {
    transform: translateY(-2px) translateX(2px);
  }

  50% {
    transform: translateY(2px) translateX(-2px);
  }
}

.scene .rocket::before {
  content: '';
  position: absolute;
  bottom: -200px;
  left: 50%;
  transform: translateX(-50%);
  width: 10px;
  height: 200px;
  background: linear-gradient(transparent 5%, #0060be 15%, #00d0ff 50%, transparent);
  z-index: 5;
}

.scene .rocket::after {
  content: '';
  position: absolute;
  bottom: -200px;
  left: 50%;
  transform: translateX(-50%);
  width: 10px;
  height: 200px;
  background: linear-gradient(transparent 5%, #0060be 15%, #00d0ff 50%, transparent);
  z-index: 7;
  filter: blur(20px)
}

.sceneWrapper {
  position: relative;
}

.stars {
  animation: fadein 1s, animatestars infinite linear;
  transition: 0.3s;
  top: -50px;
}

@keyframes animatestars {
  0% {
    transform: translateY(0);
  }

  50%, 100% {
    transform: translateY(200vh);
  }
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.slideout-rocketImage {
  animation: slideoutImage 2s;
}

.slideout-rocket::before, .slideout-rocket::after {
  animation: slideoutRocket 2s;
}

@keyframes slideoutRocket {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  50%, 100% {
    transform: translateY(-100vh);
    opacity: 0;
  }
}

@keyframes slideoutImage {
  0% {
    transform: translateY(0) rotate(-45deg);
    opacity: 1;
  }

  50%, 100% {
    transform: translateY(-100vh) rotate(-45deg);
    opacity: 0;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

#progress {
  position: absolute;
  color: #0060be;
  font-size: 60px;
  left: 55%;
  top: 15vh;
  animation: none;
  animation-name: none;
}

#progress-fadeout {
  animation: fadeout 1s;
}

#rocketImage {
  width: 70px;
  transform: rotate(-45deg);
  fill: #0060be;
  z-index: 6;
}
</style>
