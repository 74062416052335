<template>
    <div class="files-view-container">
        <Storage />
    </div>
</template>

<script>
    import Storage from '../components/Files/Storage'

    export default {
        name: "FilesView",
        components: {
            Storage
        }
    }
</script>

<style scoped>
    .files-view-container {
        align-items: center;
        width: 100%;
        height: 94vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
    }
</style>