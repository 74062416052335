<template>
	<div>
		<GChart type="PieChart" :options="options" :data="data" />
	</div>
</template>

<script>
import { GChart } from "vue-google-charts";
import Api from "../../store/config";

export default {
	name: "DoneCurrentOrders",
	components: {
		GChart,
	},
	data() {
		return {
			api: {
				results: [],
			},
			data: [["Categories de Commandes", "Pourcentage des Categories"]],
			options: {
				width: 250,
				height: 250,
				padding: 0,
				legend: "bottom",
				colors: [
					"#4DAFE6",
					"#5E2B7E",
					"#9EBF43",
					"#DC6E39",
					"#D22D53",
					"#D32F7C",
					"#916843",
					"#F8CD46",
				],
				pieHole: 0.4,
			},
		};
	},
	methods: {
		DataInChart() {
			let save = {};
			let total = 0;
			const slice = 10;
			Object.entries(this.api).forEach(([key, value]) => {
				if (key !== "total") {
					save[key] = value;
					total += value;
				}
			});

			let others = ["Autres", 0];
			Object.entries(save).forEach(([key, value]) => {
				if ((value * 100) / total > slice) {
					this.data.push([key, value]);
				} else {
					others[1] = others[1] += value;
				}
			});

			this.data.push(others);
		},
	},
	created() {
		Api()
			.get(`/command/statistic`)
			.then((res) => {
				this.api = res.data.sub_family;
				this.DataInChart();
			})
			.catch(() => {});
	},
};
</script>
