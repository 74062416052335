<template>
  <div style=" width: 85%">

    <!-- Heading Title + filtering  container-->
    <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
      <div style="display: flex; flex-direction: row; align-items: baseline">
        <vs-button
            @click="goToProductsNavigation"
            circle
            size="small"
            color="#36465d"
            icon
            floating style="height: fit-content">
          <i class='bx bx-chevron-left'></i>
        </vs-button>
        <h2>
          <span style="font-size: 1.3em; padding-bottom: 2%">
            Comparer les produits
          </span>
          <span style="font-size: 0.7em; margin-left: 10px; color: rgba(0, 0, 0, 0.3); font-weight: lighter">  
            version actuelle
          </span> 
        </h2>
      </div>
    </div>

    <!-- Exception if no products found or API issue catched -->

    <div style="
    padding: 0% 2%; height: 73vmin;
    border-radius: 12px;">
      <p class="no-product-exception" v-if="version === undefined"> 
        Aucun produit trouvé 
      </p>

      <div class="products-container">

        <div class="products-block"  v-for="(item, index) in products.results" :key="index">
          <div class="product-logo">
            <img style="border-radius: 8px" :src="baseURL  + item.maker.logo" />
          </div>
          <div class="product-title">
            <!-- Product maker -->
            <div style="font-size: 0.7em; color: grey">
              Référence
            </div>

            <vs-tooltip>
              <strong>
                {{ item.reference === undefined ? 'Undefined' : ( item.reference.length > 15 ? item.reference.slice(0,15) + '...' : item.reference )}}
              </strong>
              <template #tooltip>
                <div style="font-family: 'Noto Sans JP', sans-serif; display: flex; flex-direction: column; justify-content: left; text-align: left" >
                  <div>
                    Ref: {{ item.reference === undefined ? 'Undefined' :  item.reference }}
                  </div>
                  <div>
                    Ref constructeur: {{ item.reference_const === undefined ? 'Undefined' : item.reference_const }}
                  </div>
                </div>
              </template>
            </vs-tooltip>
          </div>

          <!-- Product description -->
          <div class="product-detail">
            <div style="font-size: 0.9em; color: grey">
              {{ item.family_sub === undefined ?  'Undefined' : item.family_sub.name}}
            </div>
            <div>
              {{ item.description }}
            </div>
          </div>

          <!-- Product price -->
          <div class="product-price">
            <span style="color: slategray; font-size: 0.7em">
              Remise famille
            </span>
            <span>
              {{ item.family_discount.discount.toFixed(2)}} %
            </span>
          </div>

          <!-- Product price -->
          <div class="product-price">
            <span style="color: slategray; font-size: 0.7em">
              Prix public
            </span>
            <span>
              {{ item.public_price.toFixed(2)}} HT
            </span>
          </div>

          <!-- Product price -->
          <div class="product-price">
            <span style="color: slategray; font-size: 0.7em">
              Prix remisé
            </span>
            <span>
              {{ (item.public_price * ( 1 - (item.family_discount.discount / 100))).toFixed(2)}} HT
            </span>
          </div>

          <!-- Product buttons -->
          <div class="product-btns">
            <ProductDetailsButton :isCurrentVersion="isCurrentVersion"  :product="item"  />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ProductDetailsButton from "./Buttons/ProductDetailsButton";
  import Api from "../../store/config";

  export default {
    name: "CompareProducts",
    components: {
      ProductDetailsButton
    },
    props: {
      version: Number,
      reference: Object,

    },
    data:() => ({
      baseURL: process.env.VUE_APP_DEV ? process.env.VUE_APP_DEV : `https://${window.location.hostname}/api/`,
      loading: true,
      products: {
        results: []
      },
      isCurrentVersion: true,
      filteringMaker: '',
      filteringFamily: ''
    }),

    mounted() {
      this.getProducts(0, 10)
    },
    computed: {
    },
    methods: {
      getProducts(offset, limit){
        this.$emit('loading', true)

        let url = `/product/?offset=${offset}&limit=${limit}&reference=${this.reference.reference}&version=${this.version}&family_list=${this.filteringFamily}&maker_list=${this.filteringMaker}`

        Api().get(url)
            .then((res)=> {
              this.products = res.data
              this.$emit('loading', false)
            })
            .catch(() => {
              this.$emit('loading', false)
            })
      },
      // Router methods
      goToProductsNavigation(){
        this.$router.push('navigate')
      },
      addToCart(product, quantity, selectedRie){
        let url = `cart/?user_id=${this.$store.state.Users.currentUser.id}&product_id=${product.id}&quantity=${quantity}`

        if(selectedRie !== 0) {
          if(selectedRie[1] === 'rie4') {
            let RieId = selectedRie[0].id
            url   = `cart/?user_id=${this.$store.state.Users.currentUser.id}&product_id=${product.id}&quantity=${quantity}&rie4_id=${RieId}`
          }
          else if(selectedRie[1] === 'rie8') {
            let RieId = selectedRie[0].id
            url = `cart/?user_id=${this.$store.state.Users.currentUser.id}&product_id=${product.id}&quantity=${quantity}&rie8_id=${RieId}`
          }
        }

        Api().post(url)
            .then( () => {
                  this.$store.dispatch('Cart/setCartState', true)
                  this.$modal.hide(`ProductDetailsModal_${product.id}`)
                  this.$vs.notification({
                    position: 'top-left',
                    title: `Panier - Ajout` ,
                    text: `${product.reference} a été ajouté au panier` ,
                    color: 'success'
                  })
                }
            )
            .catch((err) => {
              this.$vs.notification({
                position: 'top-left',
                title: `Erreur` ,
                text: err,
                color: "warn"
              })
            })

      },
    }
  }
</script>

<style scoped>
  /* Media queries styles */
  @media screen and (min-width: 1250px) {

  }

  @media screen and (max-width: 1250px) {
    .product-logo {
      width: 15% !important;
    }
  }

  /* Normal styles */

  h2 {
    color: #2c3e50!important;
    z-index: 3;
    font-size: 1.2em;
  }

  .pagination {
    position: absolute; transform: translateX(-50%); left: 50%; bottom: 3%;
  }

  .no-product-exception {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .products-container {
    height: 73vmin;
    margin-top: 1%;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .products-block {
    margin-top: 0.5%;
    padding: 1%;
    height: 5%;
    margin-left: 1%;
    margin-right: 1%;
    border-radius: 8px;
    background-color: white;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .product-title {
    width: 16%;
  }

  .product-logo {
    width: 10%;
  }

  .product-price {
    width: 10%; font-size: 0.9em; text-align: right; display: flex; flex-direction: column;
  }

  .product-detail {
    width: 45%; font-size: 0.8em;  display: flex; flex-direction: column;
  }

  .product-btns {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    width: 10%;
  }

  .checkbox {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 5%;
  }

  .filter-and-search-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
</style>
