<template>
  <div class="hidden">
    <vs-sidebar
        class="cart"
        background="primary"
        textWhite
        right
        square
        :open.sync="$store.state.Cart.cartOpen"
    >
      <div style="  height: 100%; width: 100%; display: flex;
    flex-direction: column;
    justify-content: space-between;">
        <div>
          <div style="margin-bottom: 1%; color: black; display: flex; flex-direction: row; justify-content: space-between">
            <div style=" display: flex; flex-direction: row; align-items: center">
              <i class="bx bxs-cart" style="margin-right: 5%"></i>
              <h3 style="margin: 0; padding: 0">Panier</h3>
            </div>
            <vs-button :to="{ name: 'Cart'}" :disabled="cartLength == 0">Aller au panier <i class="bx bx-right-arrow-alt"></i> </vs-button>
          </div>
          <div style="width: 100%;  height: auto; color: black">
            <div class="cart-table">
              <div style="padding: 1% 2%; font-size: 0.8em">Qté</div>
              <div style="padding: 1% 2%; font-size: 0.8em">Référence</div>
              <div style="padding: 1% 2%; font-size: 0.8em; width: 50%">Prix total avec maintenance</div>
              <div style="padding: 1% 2%; font-size: 0.8em; ">Action</div>
            </div>

            <fingerprint-spinner
                :animation-duration="1500"
                :size="64"
                color="#005eb8"
                v-if="cartLoading" style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%)"
            />

            <div class="cart-item" v-for="(item, index) in cart.results" :key="index" style="padding: 1%">
              <div style="width: 10%">x{{item.quantity}}</div>
              <div style=" text-align: left">
                  <vs-tooltip left>
                    {{ item.product.reference.length > 20 ? item.product.reference.slice(0,20) + '...' :  item.product.reference  }}
                    <template  #tooltip>
                      <div style="font-family: 'Noto Sans JP', sans-serif; display: flex; flex-direction: column; justify-content: left; text-align: left" >
                        <div>Ref: {{ item.product.reference === undefined ? 'Undefined' :  item.product.reference }}</div>
                        <div>Ref constructeur: {{ item.product.reference_const === undefined ? 'Undefined' : item.product.reference_const }}</div>
                      </div>
                    </template>
                  </vs-tooltip>
              </div>
              <div style="text-align: center; width: 50%; margin-left: 1% ">{{ totalPriceItem(item)}} € HT</div>
              <div style="margin-left: 1%">
                <vs-button @click="deleteCartItem(item)" circle icon :size="'mini'" color="warning" > <i class="bx bxs-trash-alt"></i> </vs-button>
              </div>
            </div>
          </div>
        </div>

        <div>
          <p style="color: darkgrey" v-if="cartLength === 0 ">Aucun produit dans le panier</p>
        </div>

        <div style="width: 100%; border-top: solid 2px #eee; height: fit-content;">
          <div style="width: 100%; display: flex; flex-direction: row; justify-content: space-between; border-top: solid 2px #eee; height: auto; border-radius: 8px; color: black; background-color: #eee">
            <strong style="padding: 1% 2%">Total</strong>
            <div style="flex: 1 1 0; color: dimgrey; text-align: right; padding-right: 1%">
              <span style="color: #005eb8; font-weight: bold">{{totalCartFixed}}</span> <strong>€ HT</strong>
            </div>
          </div>
        </div>
      </div>
    </vs-sidebar>
  </div>
</template>

<script>
  import { FingerprintSpinner } from 'epic-spinners'

  import Api from '../../store/config'
  export default {
    name: "CartSidebar",
    components: {
      FingerprintSpinner
    },
    data(){
      return {
        cart: '',
        cartLoading: true,
        totalPrice: 0
      }
    },
    computed: {
      cartLength(){
        return this.cart.results ? this.cart.results.length : this.cart.length
      },
      totalCartFixed(){
        let total = 0
        if(this.cart){
          for(let item of this.cart.results){
            total +=  this.totalPriceCart(item)
          }
          return total.toFixed(2)
        }
        else return 0
        }

    },
    watch: {
      '$store.state.Cart.cartOpen': function () {
        if(this.$store.state.Cart.cartOpen) {
          this.getCart()
        }
        else if(!this.$store.state.Cart.cartOpen) {
          this.totalPrice = 0
        }
      },
      $route(to) {
        if (to.name === 'Cart') {
          this.$store.dispatch('Cart/setCartState', false)
        }
      }
    },
    created() {
      this.$store.dispatch('Cart/setCartState', false)
    },
    methods:{
      totalPriceItem(item){

        if(item.rie8_backref !== null){
          return ((item.product.discount_price + item.rie8_backref.annual_unit_included) * item.quantity).toFixed(2)
        }
        else if (item.rie4_backref !== null) {
          return ((item.product.discount_price + item.rie4_backref.annual_unit_included) * item.quantity).toFixed(2)
        }
        else if(item.rie4_backref === null && item.rie4_backref === null) {
          return (item.product.discount_price * item.quantity).toFixed(2)
        }

      },
      totalPriceCart(item){

        if(item.rie8_backref !== null){
          return ((item.product.discount_price) + item.rie8_backref.annual_unit_included) * item.quantity
        }
        else if (item.rie4_backref !== null) {
          return ((item.product.discount_price) + item.rie4_backref.annual_unit_included) * item.quantity
        }
        else if(item.rie4_backref === null && item.rie4_backref === null) {
          return (item.product.discount_price) * item.quantity
        }

      },
      getCart(){
        Api().get(`cart/?user_id=${this.$store.state.Users.currentUser.id}`)
        .then((res) => {
          this.cart = res.data
          this.$emit("cart", this.cart)
          this.cartLoading = false
            for(let item of res.data.results){
              this.totalPriceCart(item)
            }
        })
        .catch(() => {
              this.cartLoading = false
            }
        )
      },
      deleteCartItem(item){
        Api().delete(`cart/?id_cart_reference=${item.id_cart_reference}`)
            .then(()=> {
              this.$vs.notification({
                title: "Produit supprimé",
                text: `${item.product.reference} a été supprimé du panier`,
                position: 'top-left',
              })
              this.getCart()
            })
      },
    }
  }
</script>

<style scoped>
  .cart {
    padding: 1%;
    max-width: 55vmin!important;
    background-color: #FFFFFF;
  }

  .cart-item {
    padding: 0% 1%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .cart-table {
    width: 100%;
    display: flex; flex-direction: row; justify-content: space-between;
    border-top: solid 2px #eee;
    height: 10%;
    font-weight: bold;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #eee
  }
  .vs-button--icon i {
    font-size: 1.05em!important;
  }
</style>
