<template>
  <div class="validation-view-container">
    <Validation @loading="loadingEventMethod"/>
  </div>
</template>

<script>
import Validation from "@/components/Versions/Validation";
export default {
  name: "ValidationView",
  components: {Validation},
  data(){
    return{
      loading: true
    }
  },
  methods: {
    loadingEventMethod(){
      this.loading = false
    }
  }
}
</script>

<style scoped>
.validation-view-container {
  align-items: center;
  width: 100%;
  height: 94vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}
</style>
