<template>
  <div class="importExport-view-container">
    <ImportExport/>
  </div>
</template>

<script>
import ImportExport from "@/components/ImportExport/ImportExport";
export default {
  name: "ImportExportView",
  components: {ImportExport}
}
</script>

<style scoped>
.importExport-view-container {
  align-items: center;
  width: 100%;
  height: 94vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}
</style>
