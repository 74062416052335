<template>
  <div class="products-container">

  </div>
</template>

<script>
export default {
    name: "Export"
  }
</script>

<style scoped>
  .products-container {
    height: 73vmin;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
</style>
