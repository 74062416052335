<template >
  <div style="margin-left: 50px; height: 100vh; background-color: #fafafa">
    <Navbar :currentUser="currentUser" :cart="cart" />
    <Sidebar />
    <CartSidebar @cart="cart=$event"/>
    <transition  name="fade" mode="out-in">
      <router-view />
    </transition>
    <Footer style="position: absolute; bottom: 0.5%; left: 50%; transform: translateX(-50%)"/>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar/Sidebar";
import Navbar from "@/components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import CartSidebar from "../components/Cart/CartSidebar";
import Api from '../store/config'

export default {
  name: "DashboardView",
  components: {CartSidebar,
              Footer,
              Navbar,
              Sidebar,
              },

  data() {
    return {
      cart: "",
    }
  },

  watch: {
    isLoggedIn(){
      if(!this.isLoggedIn){
        this.$router.push({
          name: "Login"
        });
      }
    }
  },
  created() {
    this.getCart()
    this.getCartId()
  },
  computed: {
    isLoggedIn(){
      return this.$store.getters["Users/isLoggedIn"]
    },
    currentUser(){
      return this.$store.state.Users.currentUser
    },
  },
  methods: {
    getCartId(){
      Api().get(`cart/${this.$store.state.Users.currentUser.id}`)
      .then((res) => {
        this.$store.dispatch('Cart/setCartId', res.data.id)
        Api().get(`cart/?user_id=${this.$store.state.Users.currentUser.id}`).then((res) => {
          this.cart = res.data
        })
      })
      .catch()
    },
    getCart() {
      Api().get(`cart/?user_id=${this.$store.state.Users.currentUser.id}`)
        .then((res) => {
          this.cart = res.data
        })
    }
  }
}
</script>

<style scoped>

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 0.3s ease;
}


.fade-leave-active {
  transition: opacity 0.3s ease;
  opacity: 0;
}

</style>
