<template>
  <div class="recap-container">
    <div style="display: flex; justify-content: space-between; margin-top: -5%;">
      <h1 style="margin: 0">Accueil
        <p style="color: rgba(0, 0, 0, 0.4); position: absolute; margin: 0; font-size: 0.5em; font-weight: lighter">Bienvenue sur le dashboard <strong>Index</strong></p>
      </h1>
      <img v-if="$store.state.Users.currentUser.client.length > 0" style="border-radius: 8px" height="60" src="@/assets/ul.png" alt="axiansLogo"  />

    </div>
    <div style="margin-top: 1%; display: flex; gap: 10px; box-shadow: rgb(17 17 26 / 10%) 0 1px 0; padding-left: 15px; padding-right: 15px;  background-color: white; border-radius: 8px" v-if="isLoadWeather">
      <div style="display: flex; align-items: center">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M18 6c0-3.314-2.687-6-6-6s-6 2.686-6 6c0 2.972 2.164 5.433 5 5.91v12.09l2-2v-10.09c2.836-.477 5-2.938 5-5.91zm-8.66-1.159c-.53-.467-.516-1.372.034-2.023.548-.65 1.422-.799 1.952-.333s.515 1.372-.033 2.021c-.549.652-1.423.801-1.953.335z"/></svg>
        <p>{{ weather.location }} :</p>
      </div>
      <div style="display: flex; align-items: center">
        <p>Météo du jour: {{ parseInt(weather.today.main.temp) }}°C, {{ weather.today.weather[0].description}}</p>
        <img :src="'http://openweathermap.org/img/wn/' + weather.today.weather[0].icon + '.png'" alt="météo">
      </div>
      <div style="display: flex; align-items: center">
        <p>Météo de demain : {{ parseInt(weather.tomorrow.temp) }}°C, {{ weather.tomorrow.state}}</p>
        <img :src="'http://openweathermap.org/img/wn/' + weather.tomorrow.icon + '.png'" alt="météo">
      </div>

    </div>

    <div class="analytics-block">

      <div class="item" style="margin: 0!important;">
        <div style="display: flex; flex-direction: row; width: 100%; justify-content: space-between; align-items: center">
          <h4>Quantité de produits</h4>
          <svg width="24" height="24" fill="grey" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M23 6.066v12.065l-11.001 5.869-11-5.869v-12.131l11-6 11.001 6.066zm-21.001 11.465l9.5 5.069v-10.57l-9.5-4.946v10.447zm20.001-10.388l-9.501 4.889v10.568l9.501-5.069v-10.388zm-5.52 1.716l-9.534-4.964-4.349 2.373 9.404 4.896 4.479-2.305zm-8.476-5.541l9.565 4.98 3.832-1.972-9.405-5.185-3.992 2.177z"/></svg>
        </div>
        <div style="display: flex; flex:  1 1 0; align-items: center; justify-content: center">
          <p style="font-size: 2.2em; font-family: 'Ubuntu', sans-serif; color: #0060be"> {{ currentVersion ? currentVersion.products : 'loading'}}</p>
          <half-circle-spinner
              v-if="currentVersion.length === 0"
              :animation-duration="1000"
              :size="30"
              color="#0060be"
          />
        </div>
      </div>


      <div class="item">
        <div style="display: flex; flex-direction: row; width: 100%; justify-content: space-between">
          <h4>Nombre de versions</h4>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" fill="grey" viewBox="0 0 24 24"><path d="M6 22v-16h16v7.543c0 4.107-6 2.457-6 2.457s1.518 6-2.638 6h-7.362zm18-7.614v-10.386h-20v20h10.189c3.163 0 9.811-7.223 9.811-9.614zm-10 1.614h-5v-1h5v1zm5-4h-10v1h10v-1zm0-3h-10v1h10v-1zm3-6h-19v19h-1v-20h20v1zm-2-2h-19v19h-1v-20h20v1z"/></svg>

        </div>
        <div style="display: flex; flex:  1 1 0; align-items: center; justify-content: center">
          <p style="font-size: 2.2em; font-family: 'Ubuntu', sans-serif; color: #0060be"> {{ versions ? versions.results.length : 'loading'}}</p>
          <half-circle-spinner
              v-show="versions.length === 0"
              :animation-duration="1000"
              :size="30"
              color="#0060be"
          />
        </div>
      </div>

      <div class="item">
        <div style="display: flex; flex-direction: row; width: 100%; justify-content: space-between">
          <h4>Version actuelle</h4>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" fill="grey" viewBox="0 0 24 24"><path d="M11.362 2c4.156 0 2.638 6 2.638 6s6-1.65 6 2.457v11.543h-16v-20h7.362zm.827-2h-10.189v24h20v-14.386c0-2.391-6.648-9.614-9.811-9.614zm4.811 13h-10v-1h10v1zm0 2h-10v1h10v-1zm0 3h-10v1h10v-1z"/></svg>        </div>
        <div style="display: flex; flex:  1 1 0; align-items: center; justify-content: center">
          <p style="font-size: 1.8em; text-align: center; font-family: 'Ubuntu', sans-serif; color: #0060be"> {{ currentVersion ? currentVersion.name : 'loading'}}</p>
          <half-circle-spinner
              v-show="currentVersion.length === 0"
              :animation-duration="1000"
              :size="30"
              color="#0060be"
          />
        </div>
      </div>

      <div class="item">
        <div style="display: flex; flex-direction: row; width: 100%; justify-content: space-between">
          <h4>Top catégories</h4>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" fill="grey" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.326 18.266l-4.326-2.314-4.326 2.313.863-4.829-3.537-3.399 4.86-.671 2.14-4.415 2.14 4.415 4.86.671-3.537 3.4.863 4.829z"/></svg>        </div>
        <div style="display: flex; flex:  1 1 0; align-items: center; justify-content: center">
          <p style="font-size: 1.2em; font-family: 'Ubuntu', sans-serif; color: #0060be"> {{ versions ? this.topCategories.name : 'loading'}}</p>
          <half-circle-spinner
              v-show="versions.length === 0"
              :animation-duration="1000"
              :size="30"
              color="#0060be"
          />
        </div>
      </div>

    </div>

    <div class="test">
      <div class="item" style="margin: 0!important;">
        <h4>Produits les plus commandés</h4>
        <OrderedProducts class="component"/>
      </div>

      <div class="item" style="margin-left: 0">
        <h4>Status des commandes</h4>
        <DoneCurrentOrders class="component"/>
      </div>

      <div class="item" style="margin-left: 0">
        <h4>Catégories les plus commandées</h4>
        <OrderCategory class="component"/>
      </div>
    </div>
    
  </div>
</template>

<script>
import Api from "../../store/config";
import {HalfCircleSpinner} from 'epic-spinners'
import DoneCurrentOrders from "./DoneCurrentOrders";
import OrderCategory from "./OrderCategory";
import OrderedProducts from "./OrderedProducts"
import axios from "axios";

export default {
  name: "Recap",
  components: {
    DoneCurrentOrders,
    OrderCategory,
    OrderedProducts,
    HalfCircleSpinner
  },
  data(){
    return {
      baseURL: process.env.VUE_APP_DEV ? process.env.VUE_APP_DEV : `https://${window.location.hostname}/api/`,
      products: {
        results: []
      },
      versions: {
        results: []
      },
      topCategories: {
        name: undefined,
        value: undefined,
      },
      currentVersion: [],
      weather: {},
      isLoadWeather: false
    }
  },
  created() {
    this.getProducts()
    this.getVersions()
    this.getCurrentVersion()
    this.getTopCategories()

    axios.get('https://ipapi.co/json').then(async (response) => {
      await axios.get(`https://api.openweathermap.org/data/2.5/weather?lang=fr&lat=${response.data.latitude}&lon=${response.data.longitude}&units=metric&appid=23d4130c38ccdd6ec7e76f69a60cc872`).then((response) => {
        this.weather['today'] = response.data
      })
      await axios.get(`https://api.openweathermap.org/data/2.5/forecast?lang=fr&lat=${response.data.latitude}&lon=${response.data.longitude}&units=metric&appid=23d4130c38ccdd6ec7e76f69a60cc872`).then((response) => {
        this.weather['location'] = response.data.city.name


        const today = new Date();
        today.setHours(0,0,0,0);
        let result = response.data.list.filter((el) => {
          const data = new Date(el.dt_txt)
          data.setHours(0, 0, 0, 0)
          return data > today
        })

        // On récupère le lendemain
        result = result.slice(0,8)

        let temp = 0
        let state = ''
        let icon = ''
        // On parcourt tout les results pour en extraire les info importante.
        result.forEach((el) => {
          temp += el.main.temp
          if(new Date(el.dt_txt).getHours() === 12) state = el.weather[0].description; icon = el.weather[0].icon
        })

        this.weather['tomorrow'] = {
          temp: temp / 8,
          state: state,
          icon: icon
        }


      }).then(() => {
        this.isLoadWeather = true
      })

    })
  },
  methods: {
    getFormattedDate(date) {
      let toConv = new Date(date)
      let year = toConv.getFullYear();
      let month = (1 + toConv.getMonth()).toString().padStart(2, '0');
      let day = toConv.getDate().toString().padStart(2, '0');

      return day + '/' + month + '/' + year;
    },
    getProducts(){
      Api().get(`/product/?offset=0&limit=100000`)
          .then((res)=> {
            this.products = res.data
          })
          .catch(() => {
          })
    },
    getVersions(){
      Api().get(`/version/?offset=0&limit=10000`)
          .then((res)=> {
            this.versions = res.data
          })
    },
    getTopCategories() {
      Api().get('/command/statistic').then((res) => {
        console.log(res.data)
        Object.entries(res.data.family).forEach((el) => {
          if(this.topCategories.name === undefined) {
            this.topCategories.name = el[0]
            this.topCategories.value = el[1]
          } else {
            if(el[1] > this.topCategories.value && el[0] !== 'total') {
              this.topCategories.name = el[0]
              this.topCategories.value = el[1]
            }
          }
        })
      })
    },
    getCurrentVersion(){
      Api().get(`/version/current-version`)
          .then((res)=> {
            this.currentVersion = res.data
          })
          .catch(() => {
          })
    }
  }
}
</script>

<style scoped>
  @media screen and (max-width: 1300px) {
    .recap-container {
      height: 70%; display: flex; flex-direction: column;
    }
  }

  @media screen and (min-width: 1300px) {

    .recap-container {
      height: 70%; width: 80%; display: flex; flex-direction: column;
    }
  }

  h4 {
    margin: 0;
  }

  .analytics-block {
    margin: 1% 0 0 0;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .item {
    padding: 1%;
    border-radius: 8px;
    margin-left: 1%;
    background-color: white;
    box-shadow: rgba(17, 17, 26, 0.1) 0 1px 0;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 0;
  }

  .item > h4 {
    z-index: 10;
  }

  .orders-block {
    margin: 3% 0 0 0;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .products-block {
    margin-top: 1%;
    height: 300px;
  }

  .test {
    height: 60%;
    display: flex;                  /* establish flex container */
    flex-direction: row;            /* default value; can be omitted */
    flex-wrap: nowrap;              /* default value; can be omitted */
    justify-content: center;
    width: 100%;
    margin-top: 1%;
    gap: 1%;
  }

  .component {
    margin-top: -5%;
  }

</style>
