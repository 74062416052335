<template>
  <div style=" width: 75%" >

    <!-- Heading Title + filtering  container-->
    <div style="display: flex; flex-direction: row; justify-content: left; align-items: center" >
      <div>
        <h2>
          <span style="font-size: 1.3em; padding-bottom: 2%">Panier</span>
          <span style="font-size: 0.8em; margin-left: 10px; color: grey; font-weight: lighter">
            {{ cartLength }} produits
          </span>
        </h2>
      </div>
    </div>

    <!-- Exception if no products found or API issue catched -->

    <div style="background-color: white;
    padding: 1% 2%;
    border-radius: 12px;">
      <p class="no-product-exception" style="color:#000;" v-if="cart.results.length === 0"> 
        Aucun produit trouvé 
      </p>

      <div class="products-container">
        <div class="products-block"  v-for="(item, index) in cart.results" :key="index">
          <div style="width: 5%; margin-right: 2%" >
            <div style="font-size: 0.7em; color: grey">Qté</div>

            <select @change="updateCartItem(item)" v-model.number="item.quantity">
              <option  v-for="(item, index) in 100" :key="index">
                {{ item }}
              </option>
            </select>

          </div>
          <div class="product-logo" style="margin-right: 2%">
            <img v-if="item.product.maker.logo" style="border-radius: 8px" :src="baseURL  + item.product.maker.logo" />
            <img v-else style="border-radius: 8px; width: 80px" src="../../assets/defaultbox.png" />
          </div>

          <div class="product-title">
            <!-- Product maker -->
            <div style="font-size: 0.7em; color: grey">Référence</div>
            <vs-tooltip>
              <strong>{{ item.product.reference === undefined ? 'Undefined' : ( item.product.reference.length > 15 ? item.product.reference.slice(0,15) + '...' : item.product.reference )}}</strong>
              <template  #tooltip>
                <div style="font-family: 'Noto Sans JP', sans-serif; display: flex; flex-direction: column; justify-content: left; text-align: left" >
                  <div>Ref: {{ item.product.reference === undefined ? 'Undefined' :  item.product.reference }}</div>
                  <div>Ref constructeur: {{ item.product.reference_const === undefined ? 'Undefined' : item.product.reference_const }}</div>
                </div>
              </template>
            </vs-tooltip>
          </div>

          <!-- Product description -->
          <div class="product-detail">
            <div style="font-size: 0.9em; color: grey">{{ item.product.family_sub === undefined ?  'Undefined' : item.product.family_sub.name}}</div>

            <div>{{ item.product.description }}</div>
          </div>

          <!-- Product description -->
          <div class="product-price" style="width: 20%; margin-right: 1%">
            <span style="color: slategray; font-size: 0.7em">Maintenance</span>
            <div style="display: flex; flex-direction: column" v-if="item.rie8_backref && item.rie8_backref.duration.time_duration === 3">
              <div style="font-size: 0.8em">Maintenance 3 ans avec GTR H+4</div>
              <div>{{ item.rie8_backref ?   item.rie8_backref.annual_unit_included.toFixed(2)  : 'Aucun' }}€</div>
            </div>
            <div  style="display: flex; flex-direction: column" v-if="item.rie8_backref && item.rie8_backref.duration.time_duration === 1">
              <div  style="font-size: 0.8em">Maintenance 1 an avec GTR H+4</div>
              <div>{{ item.rie8_backref ?   item.rie8_backref.annual_unit_included.toFixed(2)  : 'Aucun' }}€</div>
            </div>
            <div  style="display: flex; flex-direction: column" v-if="item.rie4_backref && item.rie4_backref.duration.time_duration === 1">
              <div  style="font-size: 0.8em">Maintenance 1 an avec GTR J+1</div>
              <div>{{ item.rie4_backref ?   item.rie4_backref.annual_unit_included.toFixed(2)   : 'Aucun' }}€</div>
            </div>
            <div  style="display: flex; flex-direction: column" v-if="item.rie4_backref && item.rie4_backref.duration.time_duration === 3">
              <div  style="font-size: 0.8em">Maintenance 3 ans avec GTR J+1</div>
              <div>{{ item.rie4_backref ?   item.rie4_backref.annual_unit_included.toFixed(2)   : 'Aucun' }}€</div>
            </div>
            <div v-if="(!item.rie4_backref && !item.rie8_backref  ) ">Aucune</div>
          </div>

          <div style="width: 40%">
            <p style="color: slategray; font-size: 0.7em">
              Prix public :
              <span style="color: black">{{ item.product.public_price.toFixed(2) }}€ HT</span>
            </p>
            <p style="color: slategray; font-size: 0.7em">
              Prix unitaire remisé :
              <span style="color: black">{{ item.product.discount_price ? item.product.discount_price.toFixed(2) : item.product.public_price.toFixed(2)}}€ HT </span>
            </p>
            <p style="color: slategray; font-size: 0.7em">
              Prix total avec maintenance :
              <span style="color: black">{{totalPriceItem(item)}}€ HT</span>
            </p>
          </div>

          <!-- Product price -->
          <!-- <div class="product-price" style="margin-right: 2%">
            <span style="color: slategray; font-size: 0.7em">Prix unitaire remisé</span>
            <span >{{ item.product.discount_price ? item.product.discount_price.toFixed(2) : item.product.public_price.toFixed(2)}}€ HT </span>
          </div>

          <div class="product-price" style="background-color: #eee; border-radius: 8px; padding: 1%; margin-right: 2%">
            <span style="color: slategray; font-size: 0.7em">Prix total avec maintenance</span>
            <span>{{totalPriceItem(item)}}€ HT</span>
          </div> -->

          <div>
            <vs-button @click="deleteCartItem(item)" circle icon size="mini" color="warning" > <i class="bx bxs-trash-alt"></i> </vs-button>
          </div>
        </div>
      </div>
    </div>

    <div class="cart-total-rundown">
      <div style="display: flex; flex-direction: column; justify-content: flex-start; width: 50vmin ">
          <div style="display: flex; flex-direction: row; justify-content: space-between; color: grey ">
            <strong >Total au prix public: &nbsp;</strong>
            <span>{{totalCartPP}} <strong>€ HT</strong></span>
          </div>

        <div style="display: flex; flex-direction: row; justify-content: space-between; color: #003d57">
          <strong>Total avec remise appliqué: &nbsp;</strong>
          <span style="text-align: right">{{totalCartD}} <strong>€ HT</strong></span>
        </div>

        <div style="display: flex; flex-direction: row; justify-content: space-between">
          <strong>Total maintenances:</strong>
          <span>{{totalCartM}} <strong>€ HT</strong></span>
        </div>
      </div>

      <div style="display: flex; flex-direction: column; justify-content: flex-start;">
        <div style="display: flex; flex-direction: row; color: #005eb8">
          <strong>Total panier: &nbsp;</strong>
          <span>{{totalCart}} <strong>€ HT</strong></span>
        </div>
        <vs-button @click="showModal()" style="width: fit-content; height: fit-content">Envoyer au responsable client</vs-button>
        <ModalSendCart
          v-show="isModalVisible"
          @close="closeModal" 
        />
      </div>
    </div>
  </div>
</template>

<script>
  import Api from "../../store/config";
  import ModalSendCart from './ModalSendCart'

  export default {
    name: "Cart",
    components: {
      ModalSendCart,
    },
    data(){
      return {
        baseURL: process.env.VUE_APP_DEV ? process.env.VUE_APP_DEV : `https://${window.location.hostname}/api/`,
        cart: '',
        totalPrice: 0,
        isModalVisible: false
      }
    },
    created() {
      this.getCart()
      },
    computed: {
      cartLength(){
        return this.cart.results ? this.cart.results.length : this.cart.length
      },

      totalCart(){
        let total = 0
        for(let item of this.cart.results){
          total +=  parseFloat(this.totalPriceItem(item))
        }
        console.log(total)

      return total.toFixed(2)
      },
      totalCartD(){
        let total = 0
        for(let item of this.cart.results){
          total +=  item.product.discount_price ? item.product.discount_price * item.quantity : item.product.public_price * item.quantity
        }
        return total.toFixed(2)
      },
      totalCartM(){
        let total = 0
        for(let item of this.cart.results){
          total +=  this.totalCartMaintenance(item)
        }
        return total.toFixed(2)
      },
      totalCartPP(){
        let total = 0
        for(let item of this.cart.results){
          total +=  item.product.public_price  * item.quantity
        }

      return total.toFixed(2)
      }

    },
    methods: {
      // this bad boy gives you the total price of an item based on all its properties
      totalPriceItem(item){

        if(item.rie8_backref !== null){
          return (item.product.discount_price ? (item.product.discount_price + item.rie8_backref.annual_unit_included) * item.quantity : (item.product.public_price + item.rie8_backref.annual_unit_included) * item.quantity ).toFixed(2)
        }
        else if (item.rie4_backref !== null) {
          return (item.product.discount_price ? (item.product.discount_price + item.rie4_backref.annual_unit_included) * item.quantity : (item.product.public_price + item.rie4_backref.annual_unit_included) * item.quantity ).toFixed(2)
        }
        else if(item.rie4_backref === null && item.rie4_backref === null) {
          return (item.product.discount_price ? item.product.discount_price * item.quantity : item.product.public_price * item.quantity ).toFixed(2)
        }
      },
      totalCartMaintenance(item){

        if(item.rie8_backref !== null){
          return item.rie8_backref.annual_unit_included * item.quantity
        }
        else if (item.rie4_backref !== null) {
          return item.rie4_backref.annual_unit_included * item.quantity
        }
        else return 0

      },
      deleteCartItem(item){
        Api().delete(`cart/?id_cart_reference=${item.id_cart_reference}`)
        .then(()=> {
          this.$vs.notification({
            title: "Produit supprimé",
            text: `${item.product.reference} a été supprimé du panier`,
            position: 'top-left',
          })
          this.getCart()
        })
      },
      updateCartItem(item){
        Api().put(`cart/?id_cart_reference=${item.id_cart_reference}&quantity=${item.quantity}`)
        .then(()=> {
          this.$vs.notification({
            title: "Panier modifié - quantité",
            text: `${item.product.reference} a été modifié`,
            position: 'top-left',
          })
          this.getCart()
        })
      },
      getCart(){
        Api().get(`cart/?user_id=${this.$store.state.Users.currentUser.id}`)
            .then((res) => {
              this.cart = res.data
            })
      },
      showModal() {
          this.isModalVisible = true;
      },
      closeModal() {
        this.isModalVisible = false;
        this.getCart()
      }
    }
  }
</script>

<style scoped>
  h2 {
    color: #2c3e50!important;
    z-index: 3;
    font-size: 1.2em;
  }

  .pagination {
    position: absolute; transform: translateX(-50%); left: 50%; bottom: 3%;
  }

  .no-product-exception {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .products-container {
    height: 65vmin;
    margin-top: 1%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .products-block {
    margin-top: 0.5%;
    padding: 1%;
    margin-left: 1%;
    margin-right: 1%;
    border-radius: 8px;
    background-color: white;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

  .cart-total-rundown {
    border-bottom-left-radius: 8px;
    border-top: 2px solid #005eb833;
    border-bottom-right-radius: 8px;
    display: flex; flex-direction: row; justify-content: space-between;
    height: 90px; background-color: #eee;  padding: 1% 2%;
  }

  .product-title {
    width: 20%;
  }

  .product-logo {
    width: 10%;
  }
  .product-price {
    width: 10%; font-size: 0.9em; text-align: center; display: flex; flex-direction: column;
  }

  .product-detail {
    width: 30%; font-size: 0.8em;  display: flex; flex-direction: column;
  }

  .product-btns {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    width: 10%;
  }
  .checkbox {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 5%;
  }
  .filter-and-search-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .vs-button--icon i {
    font-size: 1.05em!important;
  }
</style>
