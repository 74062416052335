<template>
	<div v-if="userRole">
		<div class="user-block" v-for="(item, index) in users" :key="index">
			<div class="user-sub-item" style="width: 15%" >
				<span style="color: slategray; font-size: 0.7em">Nom utilisateur</span>
				<span style="font-weight: bold">{{item.username}}</span>
			</div>
			<div class="user-sub-item" style="width: 15%" >
				<span style="color: slategray; font-size: 0.7em">Role</span>
				<span style="overflow-wrap: break-word" >{{item.role_type}}</span>
			</div>
			<div class="user-sub-item" style="width: 20%" >
				<span style="color: slategray; font-size: 0.7em">Email</span>
				<span style="overflow-wrap: break-word">{{item.email}}</span>
			</div>
			<div class="user-sub-item" style="width: 10%;">
				<span style="color: slategray; font-size: 0.7em">Prénom</span>
				{{item.first_name}}
			</div>
			<div class="user-sub-item" style="width: 10%" >
				<span style="color: slategray; font-size: 0.7em">Nom</span>
				<span>{{item.last_name}}</span>
			</div>

			<div class="user-sub-item" style="width: auto; display: flex; gap: 5px; flex-direction: row; align-items: center"  >
				<vs-button v-if="userRole.role_type === 'Administrateur' || userRole.username === item.username" @click="openModal(item.id)">Editer</vs-button>
				<vs-button v-if="userRole.role_type === 'Administrateur'" @click="removeUser(item.id)" color="#9C2267">Supprimer</vs-button>
			</div>
			<UserModal @userUpdated="userUpdated" :user="item" @click="$modal.show(`UserDetailsModal_${item.id}`)" @close="close"/>
		</div>
		<AddUserModal @userAdded="userAdded"  />
	</div>
</template>

<script>

import UserModal from "@/components/Settings/Modals/UserModal";
import AddUserModal from "@/components/Settings/Modals/AddUserModal";
import { mapActions, mapState } from "vuex";

export default {
	name: "Users",
	components: {AddUserModal, UserModal},
	data(){
		return{
			selectedItem: null,
		}
	},
	computed: {
		...mapState('Users', ['users']),
		userRole() {
			return this.$store.state.Users.currentUser ? this.$store.state.Users.currentUser : null
		},
	},
	created() {
		this.getUsers()
	},
	methods: {
		...mapActions('Users', ['getUsers', 'deleteUser']),
		userUpdated(){
			this.getUsers()
		},
		userAdded(){
			this.getUsers()
		},
		removeUser(id) {
			this.deleteUser(id)
		},
		close() {
			this.$modal.hide(`UserDetailsModal_${this.selectedItem}`)
		},
		openModal(id) {
			this.selectedItem = id
			this.$modal.show(`UserDetailsModal_${id}`)
		}
	}
}
</script>

<style scoped>
	.user-block {
		margin-top: 0.5%;
		padding: 1%;
		margin-left: 1%;
		margin-right: 1%;
		flex: 1 1 0;
		border-radius: 8px;
		background-color: white;
		box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: space-between;
	}

	.user-sub-item {
		display: flex;
		flex-direction: column;
	}
</style>
