import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// third party components
import Vuesax from 'vuesax'
import './styles/vuesax.css' //Vuesax styles
import vClickOutside from 'v-click-outside'
import VuejsPaginate from 'vuejs-paginate'
import VModal from 'vue-js-modal'
import VueContentPlaceholders from 'vue-content-placeholders'
import VueApexCharts from 'vue-apexcharts'


// dependency injection - usage of third parties
Vue.use(vClickOutside)
Vue.use(VueContentPlaceholders)
Vue.use(VModal)
Vue.use(VueApexCharts)

Vue.use(Vuesax, {
  colors: {
    primary: '#005eb8',
    success:'rgb(23, 201, 100)',
    danger:'rgb(242, 19, 93)',
    warning:'rgb(255, 130, 0)',
    dark:'rgb(36, 33, 69)',
    bright: '#FFFFFF'
  }})

Vue.component('paginate', VuejsPaginate)


Vue.config.productionTip = false

export default new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
