<template>
  <div class="login-component-container">

    <div style="display: flex; flex-direction: row; align-items: baseline; width: 22vmin; justify-content: center">
      <h4 style="margin-bottom: 3em">Reinitialisation du mot de passe</h4>
    </div>

    <p style="font-size: 0.7em">
      Afin de finaliser la réinitialisation du mot de passe, veuillez renseigner ci-dessous votre nouveau mot de passe et confirmer celui-ci.<br><br>
      Votre mot de passe doit contenir:<br>
      8 caractères minimum, 1 majuscule, 1 minuscule, 1 chiffre.
    </p>


    <div style="margin-bottom: 5%; width: 60%; height: 5px">
      <p style="font-size: 12px">Niveau de sécuritée du mot de passe</p>
      <div style="height: 3px; border-radius: 250px" :style="passwordSecurity()"/>
    </div>

      <vs-input
          style="margin-top: 10%; width: 60%; margin-bottom: 5%;"
          label="Nouveau mot de passe"
          type="password"
          v-model="password.new"
          placeholder="Ex. ******"
      />



      <vs-input
          style="width: 60%; margin-bottom: 5%"
          label="Confirmer le mot de passe"
          type="password"
          v-model="password.confirm"
          placeholder="Ex. ******"
      />

      <vs-button
          color="#005eb8"
          @click="sendNewPassword"
      >
        Envoyer
      </vs-button>
    <Footer style="position: absolute; bottom: 0"/>
  </div>
</template>

<script>
import Footer from "@/components/Footer/Footer";
import { mapActions } from "vuex";

export default {
  name: "NewPassword",
  components: {Footer},
  data(){
    return {
      password: {
        new: undefined,
        confirm: undefined
      },
      securityLevel: 0
    }
  },
  watch: {
    'password.new'(newValue){
      let score = 0;
      const rules = []
      rules.push("[A-Z]")
      rules.push("[a-z]")
      rules.push("[0-9]");
      rules.push(".{8,}");

      rules.forEach(rule => {
        if(newValue.match(rule)){
          score++
        }
      })

      this.securityLevel = score

    }
  },
  methods: {
    ...mapActions('Users', ['updateUserPassword']),
    passwordSecurity(){
      switch (this.securityLevel) {
        case 0:
          return {
            width: "0%"
          }
        case 1:
          return {
            backgroundColor: "#ff0000",
            width: "25%"
          }
        case 2:
          return {
            backgroundColor: "#ff6200",
            width: "50%"
          }
        case 3:
          return {
            backgroundColor: "#ffe800",
            width: "75%"
          }
        case 4:
          return {
            backgroundColor: "#1bff00",
            width: "100%"
          }
      }
    },
    async sendNewPassword() {
      if(this.password.new && this.password.confirm){
        if(this.password.new !== this.password.confirm) {
          this.$vs.notification({
            position: 'top-left',
            title: 'Erreur',
            text: 'Les mots de passes de sont pas similaires merci de les corriger',
            color: 'danger'
          })
        } else {
          let regex = new RegExp("(?=.*[a-z])(?=.{8,})(?=.*[0-9])(?=.*[A-Z])")
          if(regex.test(this.password.new)){
            await this.updateUserPassword({uuid: this.$route.params.uuid, password: btoa(this.password.new)}).then(() => {
              this.$vs.notification({
                position: 'top-left',
                title: 'Succès',
                text: 'Votre mot de passe a été mis à jour',
                color: 'success'
              })
              setTimeout(() => {
                this.$router.push('/')
              }, 2000)
            })
          } else {
            this.$vs.notification({
              position: 'top-left',
              title: 'Warning',
              text: "Votre mot de passe n'est pas assez fort merci de relire les conditions du mot de passe",
              color: 'warning'
            })
          }
        }

      } else {
        if(!this.password.new) {
          this.$vs.notification({
            position: 'top-left',
            title: 'Erreur',
            text: 'Merci de renseigner votre nouveau mot de passse',
            color: 'danger'
          })
        } else if (!this.password.confirm) {
          this.$vs.notification({
            position: 'top-left',
            title: 'Erreur',
            text: 'Merci de confirmer votre nouveau mot de passe',
            color: 'danger'
          })
        }
      }
    }
  }
}
</script>

<style scoped>
.login-component-container {
  height: 90%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
</style>