<template>
    <transition name="modal-fade">
        <div class="modal-backdrop">
            <div class="modal">
                <header class="modal-header">
                    <slot name="header">
                        <div>
                            <h4>
                                Validation de la Commande: 
                                <span id="title">
                                    {{ title.slice(0, 18) + (title.length > 18 ? "..." : "") }}
                                </span>
                            </h4>
                        </div>
                    </slot>
                    <i @click="close" class='bx bx-x-circle' style='color: black;'></i>
                </header>

                <section class="modal-body">
                    <slot name="body">
                        <div class="validate">
                            <DeliveryTracker v-if="isPostOk == 'default' || isPostOk == 'error'" id="tracker" :modal="'validate'" :status="'shipped'"/>
                            <transition name="fade">
                                <DeliveryTracker v-if="isPostOk == 'ok'" id="tracker" :modal="'validate'" :status="'finished'"/>
                            </transition>
                            <div class="validate_buttons">
                                <p>Voulez-vous valider la réception de votre commande ?</p>
                                <vs-button v-if="isPostOk == 'default'" id='button' @click="validateCommand()" style="width: fit-content; height: fit-content">
                                    Valider la réception
                                </vs-button>
                                <vs-button v-else-if="isPostOk == 'ok'" id='button' success style="width: fit-content; height: fit-content">
                                    <i class='bx bx-check'></i>Succès
                                </vs-button>
                                <vs-button v-else-if="isPostOk == 'error'" id='button' danger style="width: fit-content; height: fit-content">
                                    <i class='bx bx-x'></i> Erreur
                                </vs-button>
                            </div>
                        </div>
                    </slot>
                </section>
            </div>
        </div>
    </transition>
</template>

<script>
    import DeliveryTracker from '../DeliveryTracker';
    import Api from "../../../store/config"

    export default {
        name: 'ModalInfoCommand',
        data() {
            return {
                isPostOk: 'default',
            }
        },
        components: {
            DeliveryTracker,
        },
        methods: {
            close() {
                this.$emit('close');
                this.isPostOk = 'default';
            },
            validateCommand() {
                Api().put(`/command/update-finish/${this.order_id}`, {
                    'state': true
                }).then(() => {
                    this.isPostOk = 'ok'
                    setTimeout(function() { this.close() }.bind(this), 3000)
                }).catch(() => {
                    this.isPostOk = 'error'
                })
            }
        },
        props: {
            title: String,
            order_id: Number 
        }
    };
</script>

<style scoped>
    .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }

    modal-header.slot {
        display: flex;
        flex-direction: row;
    }
    
    hr {
        border: 0.6px solid black;
        border-radius: 20px;
        width: 70%;
    }

    .modal {
        background: #FFFFFF;
        box-shadow: 2px 2px 20px 1px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        width: 500px;
        height: 300px;
    }

    .modal-header,
    .modal-footer {
        padding: 15px;
        display: flex;
    }

    .modal-header {
        position: relative;
        border-bottom: 1px solid #eeeeee;
        color: #2A69AF;
        justify-content: space-between;
        font-weight: bold;
    }

    .modal-footer {
        border-top: 1px solid #eeeeee;
        flex-direction: column;
        justify-content: flex-end;
    }

    .modal-body,
    .modal-product {
        position: relative;
        padding: 20px 10px;
    }

    .modal-fade-enter,
    .modal-fade-leave-to {
        opacity: 0;
    }

    .modal-body {
        padding: 0px;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity 1s ease;
    }

    .close {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .modal-body-container {
        height: 150px;
    }

    .modal-product {
        padding: 0px 20px 0px 20px;
    }

    .main-information-product {
        display: flex; 
        flex-direction: row; 
        justify-content: left; 
        width: 70%; 
        align-items: center
    }

    .main-information-product-text {
        display: flex; 
        flex-direction: column; 
        margin-left: 4%
    }

    .modal_body_grid {
        display: grid;
        width: 100%;
    }

    #title {
        color: black
    }

    #tracker {
        grid-area: tracker;
        margin: 10% 100px 0px 50px;
    }

    .validate {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: tracker buttons;
    }

    .validate_buttons {
        grid-area: buttons;
        margin-left: 250px;
        width: 40%;
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    #button {
        margin: auto;
    }

    p {
        text-align: center;
    }
    
    .bx-check, .bx-x {
        padding-right: 10px;
    }

    .fade-enter-active {
        transition: opacity .5s;
    }

    .fade-enter {
        opacity: 0;
    }

</style>