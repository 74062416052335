<template>
  <div style=" width: 80%" >
    <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center" >
      <div>
        <h2>
          <span style="font-size: 1.3em; padding-bottom: 2%">Paramètres</span>
          <span style="font-size: 0.7em; margin-left: 10px; color: rgba(0, 0, 0, 0.3); font-weight: lighter"> {{ active }}</span>
        </h2>
      </div>
    </div>

    <!-- Exception if no products found or API issue catched -->

    <div style="background-color: white;
    padding: 1% 2%;
    border-radius: 12px;">

      <div v-if="usersAuthorized()" class="filter-and-search-container">
        <div>
          <vs-button v-if="usersAuthorized()" border id="addUser" @click="$modal.show(`AddUserModal`)">Ajouter utilisateur</vs-button>
        </div>
      </div>

      <div class="products-container">
        <Users v-if="active === 'Utilisateurs'" />
      </div>
    </div>
  </div>
</template>

<script>
  import Users from "@/components/Settings/Pages/Users";

  export default {
    name: "Settings",
    components: {
      Users,
    },
    data:() => ({
      products: [],
      active: 'Utilisateurs',
      makers: [],
      option: [],
      family: [],
      familySub: [],
      filteringFamily: [],
      filteringFamilySub: [],
      filteringMaker: [],
      filteringType: '',
      loading: true
    }),
    methods: {
      usersAuthorized() {
        if (this.$store.state.Users.currentUser.role_type == 'Administrateur') return true;
        else return false;
      }
    }
  }
</script>

<style scoped>
  h2 {
    color: #2c3e50!important;
    z-index: 3;
    font-size: 1.2em;
  }

  .pagination {
    position: absolute; transform: translateX(-50%); left: 50%; bottom: 3%;
  }

  .no-product-exception {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .products-container {
    height: 73vmin;
    margin-top: 1%;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .products-block {
    margin-top: 0.5%;
    padding: 1%;
    margin-left: 1%;
    margin-right: 1%;
    border-radius: 8px;
    flex: 1 1 0;
    background-color: white;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }



  .product-title {
    width: 15%;
  }

  .product-logo {
    width: 10%;
  }
  .product-price {
    width: 10%; font-size: 0.9em; text-align: right; display: flex; flex-direction: column;
  }

  .product-detail {
    width: 45%; font-size: 0.8em;  display: flex; flex-direction: column;
  }

  .product-btns {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    width: 20%;
  }
  .checkbox {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 5%;
  }
  .filter-and-search-container {
    display: flex;
    flex-direction: row;
    border-bottom: solid 1px #eee;
    justify-content: space-between;
  }

  #addUser {
    margin-bottom: 15px;;
  }
</style>
