<template>
  <div class="login-component-container">
    <vs-button
        circle size="large" style="position: absolute;
        left: 2%; top: 50%; transform: translateY(-50%)"
        color="#005eb8" to="/">
      <i class="bx bx-arrow-back"></i>
    </vs-button>

    <div style="display: flex; flex-direction: row; align-items: baseline; width: 22vmin; justify-content: center">
      <h4 style="margin-bottom: 3em">Mot de passe oublié</h4>
    </div>

    <p style="font-size: 0.7em">
      Afin de modifier votre mot de passe, veuillez renseigner ci-dessous votre adresse email<br>
      Vous recevrez un mail contenant le lien de modification du mot de passe
    </p>

    <vs-input
        style="margin-top: 10%; width: 60%; margin-bottom: 5%"
        label="Adresse email"
        v-model="email_address"
        placeholder="Adresse email"
        @keyup.enter="forgotPassword"
    />

    <vs-button
        color="#005eb8"
        :disabled="!email_address.length > 0"
        @click="forgotPassword">
          Envoyer
    </vs-button>
    <Footer style="position: absolute; bottom: 0%"/>
  </div>
</template>

<script>
  import Api from '../../store/config'
  import Footer from "@/components/Footer/Footer";
  export default {
    name: "ForgotPassword",
    components: {Footer},
    data(){
      return {
        email_address: '',
        active: 0
      }
    },
    methods: {
      forgotPassword(){
        let body = {
          email: this.email_address
        }
        Api().post('auth/forgot-password', body)
        .then(()=> {
          this.$vs.notification({
            title: 'Mot de passe oublié',
            text: `L'email de confirmation a été envoyé avec succès`,
            color: 'primary'
          })
          this.$router.push('/')
        })
        .catch((err)=> {
          this.$vs.notification({
            title: 'Mot de passe oublié',
            text: `Erreur: ${err}`,
            color: 'warning'
          })
        })
      }
    }
  }
</script>

<style scoped>
  .login-component-container {
    height: 90%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
</style>