<!-- Every sidebar element takes an id, that matches its routing name
     The defined computed propery "active" gets the current route name
     and matches the id to it, in order to keep a reactive sidebar -->

<template >
  <div @mouseover="userPanelVisible = true" @mouseleave="userPanelVisible = false">
    <vs-sidebar
        id="sidebar"
        absolute
        reduce
        square
        hover-expand
        v-model="active"
        :open="true">

      <template #logo>
        <router-link to="recap"><img style="width: 30px" src="@/assets/rocket.png"/></router-link>
      </template>

      <vs-sidebar-item :to="{ name: 'Dashboard'}"  id="Recap" >
        <template #icon>
          <i class='bx bx-home'></i>
        </template>
        <a>Accueil</a>
      </vs-sidebar-item>

      <vs-sidebar-item :to="{name: 'Products'}"  id="Navigate" >
        <template #icon>
          <i class='bx bx-search'></i>
        </template>
        <a>Produits</a>
      </vs-sidebar-item>

      <vs-sidebar-item :to="{name: 'MyCommands'}"  id="MyCommands" >
        <template #icon>
          <i class='bx bxs-shopping-bags'></i>
        </template>
        <a>Commandes</a>
      </vs-sidebar-item>

      <vs-sidebar-item :to="{name: 'Versions'}" id="Versions" >
        <template #icon>
          <i class='bx bxs-file'></i>
        </template>
        <a>Versions</a>
      </vs-sidebar-item>

      <vs-sidebar-item v-if="userRole() !== 'Client'" :to="{name: 'ImportExport'}" id="ImportExport" >
        <template #icon>
          <i class='bx bxs-file-export'></i>
        </template>
        <a>Import</a>
      </vs-sidebar-item>

      <vs-sidebar-item :to="{name: 'Validation'}" id="Validation" >
        <template #icon>
          <i class='bx bxs-check-square'></i>
        </template>
        <a>Validation</a>
      </vs-sidebar-item>

      <vs-sidebar-item :to="{name: 'Storage'}" id="Storage" >
        <template #icon>
          <i class='bx bxs-folder-open'></i>
        </template>
        <a>Stockage</a>
      </vs-sidebar-item>


      <vs-sidebar-item v-if="userRole() !== 'Client'" :to="{name: 'Settings'}"  id="Settings" >
        <template #icon>
          <i class='bx bxs-wrench'></i>
        </template>
        <a>Paramètres</a>
      </vs-sidebar-item>

      <template #footer>
        <div style="display: flex; flex-direction: column">
          <p style="font-size: 10px; text-align: center; opacity: 0;" :class="{ appear: userPanelVisible }">v{{ version }}</p>
          <vs-sidebar-item href="mailto:support.ias@axians.com" id="help">
            <template #icon>
              <i class='bx bxs-help-circle'></i>
            </template>
            Aide
          </vs-sidebar-item>
        </div>
      </template>
    </vs-sidebar>
  </div>
</template>

<script>
import 'boxicons/css/boxicons.min.css'

export default {
  name: "Sidebar",
  data() {
    return {
      version: localStorage.getItem('version'),
      userPanelVisible: false,
    }
  },
  computed: {
    active(){
      return this.$route.name
    },
  },
  methods: {
    goTo(dest){
      this.$router.push(dest.toString())
    },
    userRole() {
      return this.$store.state.Users.currentUser.role_type
    }
  }
}
</script>

<style scoped>
img {
  border-radius: 8px;
}

#sidebar {
  z-index: 1000;
}

.appear {
    opacity: 1 !important;
}
</style>
